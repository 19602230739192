/* eslint-disable */
import { withStyles } from '@material-ui/core';
import breakpoints from '../data/breakpoints.json';
import Variables from 'styles/variables.module.scss';

const styles = theme => ({
  '@global': {
    // html: {
    //   fontFamily: 'Open Sans, sans-serif !important',
    // },
    // body: {
    //   fontFamily: 'Open Sans, sans-serif !important',
    //   backgroundColor: '#fafafa',
    //   letterSpacing: 'normal !important',
    //   overflow: 'auto!important',
    //   margin: '0',
    //   padding: '0',
    //   fontWeight: '400',
    //   lineHeight: '1.43',
    //   fontSize: '0.875rem',
    // },
    '.MuiButtonBase-root': {
      fontFamily: 'Open Sans, sans-serif!important',
    },
    '.MuiButton-root': {
      fontFamily: 'Open Sans, sans-serif!important',
    },
    p: {
      fontFamily: 'Open Sans, sans-serif',
      letterSpacing: 'normal!important',
    },
    typography: {
      fontFamily: 'Open Sans, sans-serif!important',
    },
    h1: {
      letterSpacing: 'normal !important',
    },
    h2: {
      letterSpacing: 'normal !important',
    },
    h3: {
      letterSpacing: 'normal !important',
    },
    h4: {
      letterSpacing: 'normal !important',
    },
    h5: {
      letterSpacing: 'normal !important',
    },
    h6: {
      letterSpacing: 'normal !important',
    },
    '*:focus': {
      outline: 0,
    },
    '.flex-shrink-0': {
      flexShrink: 0,
    },
    '.text-white': {
      color: theme.palette.common.white,
    },
    '.text-center': {
      textAlign: 'center',
    },
    '.text-right': {
      textAlign: 'right',
    },
    '.listItemLeftPadding': {
      paddingTop: `${theme.spacing(1.75)}px !important`,
      paddingBottom: `${theme.spacing(1.75)}px !important`,
      paddingLeft: `${theme.spacing(4)}px !important`,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: `${theme.spacing(4)}px !important`,
      },
      '@media (max-width:  420px)': {
        paddingLeft: `${theme.spacing(1)}px !important`,
      },
    },
    '.main-heading1': {
      fontSize: '16px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '16px!important',
      },
    },
    '.main-heading': {
      fontSize: '18px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '18px!important',
      },
    },
    '.testi-text': {
      fontSize: '24px!important',
      marginBottom: '25px!important',
      marginTop: '45px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '22px!important',
        marginBottom: '25px!important',
        marginTop: '45px!important',
      },
    },

    '.pac-container': {
      zIndex: '99999999999999 !important',
    },

    /*rent page start-------------------------------------------*/

    '.main-section-2': {
      marginTop: '20px',
      [theme.breakpoints.down('lg')]: {
        marginTop: '116px',
      },
      '& .MuiTypography-h1': {
        fontFamily: 'Open Sans !important',
        fontWeight: '700 !Important',
      },
      '& .MuiTypography-h6': {
        fontFamily: 'Open Sans !important',
        fontWeight: '500 !Important',
      },
    },

    // rental page compatible
    '.compatible-rental-mob': {
      marginTop: '64px',
      '& h1': {
        fontFamily: 'Open Sans !Important',
        fontWeight: '700 !Important',
        [theme.breakpoints.down('md')]: {
          fontSize: '18px !important',
        },
      },

      '& h6': {
        fontFamily: 'Open Sans !Important',
        [theme.breakpoints.down('sm')]: {
          fontSize: '13px !important',
        },
      },
      [theme.breakpoints.down(breakpoints.ssm)]: {
        paddingTop: '10px',
        '& .heading-section': {
          paddingRight: '10px',
        },
        margin: '10px 20px 20px 20px',
        width: '91%',
      },
      '& .card-section, & .builder-section, & .footer-section': {
        // padding: '0px 0 25px',
        // [theme.breakpoints.down(breakpoints.ssm)]: {
        //   padding: '10px 0',
        // },
      },
    },

    '.main-section-rental': {
      marginTop: '60px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        paddingTop: '11px',
        '& .heading-section': {
          paddingRight: '10px',
        },
        margin: '10px 20px 0px 20px',
      },
      '& .card-section, & .builder-section, & .footer-section': {
        // padding: '0px 0 25px',
        // [theme.breakpoints.down(breakpoints.ssm)]: {
        //   padding: '10px 0',
        // },
      },
    },

    '.main-section-rental-mob': {
      marginTop: '20px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        paddingTop: '10px',
        '& .heading-section': {
          paddingRight: '10px',
        },
        margin: '10px 0 0 19px',
        position: 'relative',
        zIndex: '9',
      },
      '& .card-section, & .builder-section, & .footer-section': {
        // padding: '0px 0 25px',
        // [theme.breakpoints.down(breakpoints.ssm)]: {
        //   padding: '10px 0',
        // },
      },
    },

    /*rent page end--------------------------------------------*/

    '.testi-text-sell': {
      fontSize: '24px!important ',
      marginBottom: '25px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '22px!important',
        marginBottom: '25px!important',
      },
    },

    '.headerTitlecenter': {
      textAlign: 'center!important',
      fontSize: '18px!important',
    },

    '.scheduleTourButton': {
      // transition: '0.3s ease-in-out all',
      fontFamily: 'Open Sans',
      '&:hover': {
        backgroundColor: '#EE6A29 !important',
      },
      [theme.breakpoints.down(breakpoints.md)]: {
        fontSize: '11px',
      },
    },

    //gallery start-------------
    '.bg-mob-section': {
      backgroundImage: 'url(/static/images/mobile/footer-bg.png)',
      backgroundPosition: '0 7px',
      backgroundRepeat: 'repeat-x',
    },
    '.viewmodal': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '88%',
      // height: "90%",
      backgroundColor: '#fff',
      border: '#000',
      borderRadius: '10px',
      boxShadow: '0px 0px 40px #00000080',
      p: 0,
      [theme.breakpoints.down(breakpoints.ssmll)]: {
        height: '30%!important',
      },
      [theme.breakpoints.down(breakpoints.lgm)]: {
        height: '612px',
      },

      [theme.breakpoints.up(breakpoints.lgm)]: {
        height: '64%!important',
      },
      [theme.breakpoints.up(breakpoints.xxl)]: {
        height: '96%!important',
      },
    },

    '.street-box': {
      '& iframe': {
        height: '441px !important',
        border: 'none!important',
        borderRadius: '10px',
      },
    },

    '.streetheight': {
      height: '610px',
      '& iframe': {
        height: '610px',
        border: 'none!important',
        borderBottomLeftRadius: '10px!important',
        borderBottomRightRadius: '10px!important',
        position: 'absolute',

        [theme.breakpoints.down(breakpoints.ssmll)]: {
          height: '86%!important',
        },

        [theme.breakpoints.down(breakpoints.lgm)]: {
          height: 'calc(100% - 49px) !important',
        },

        [theme.breakpoints.up(breakpoints.lgm)]: {
          position: 'absolute',
          height: '96%',
          border: 'none!important',
          borderBottomLeftRadius: '10px!important',
          borderBottomRightRadius: '10px!important',
        },
        [theme.breakpoints.up(breakpoints.xxl)]: {
          height: '95%!important',
        },
      },
    },

    '.videoheight': {
      height: '610px',
      '& video': {
        height: '640px',
        border: 'none!important',
        borderBottomLeftRadius: '10px!important',
        borderBottomRightRadius: '10px!important',
        position: 'absolute',

        [theme.breakpoints.down(breakpoints.ssmll)]: {
          height: '86%!important',
        },

        // [theme.breakpoints.down(breakpoints.lgm)]: {
        //   height: '92%',
        // },

        [theme.breakpoints.down(breakpoints.lgm)]: {
          height: '92%',
        },

        [theme.breakpoints.up(breakpoints.lgml)]: {
          position: 'absolute',
          height: '92.1%',
          border: 'none!important',
          borderBottomLeftRadius: '10px!important',
          borderBottomRightRadius: '10px!important',
        },
        [theme.breakpoints.up(breakpoints.xxl)]: {
          height: '95%!important',
        },
      },
    },

    '.projectImageGalleryFloor': {
      '& .image-gallery-image': {
        objectFit: 'contain!important',
        // maxHeight: "calc(100vh - 320px)!important",
      },

      '& img': {
        marginTop: '-5px',
        // height: '640px',
        height: '93Vh !Important',

        [theme.breakpoints.up(breakpoints.lgm)]: {
          height: '93Vh',
        },
        [theme.breakpoints.down(breakpoints.md)]: {
          height: 'auto !Important',
        },
        [theme.breakpoints.up(breakpoints.lgm)]: {
          marginTop: '-40px',
        },
      },
    },
    '.desk-view': {
      [theme.breakpoints.up(breakpoints.sm)]: {
        display: 'block',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'none',
      },
      [theme.breakpoints.down(breakpoints.ssm)]: {
        display: 'none',
      },
    },
    '.offeralign': {
      display: 'flex',
      verticalAlign: 'Top',
    },
    '.offerTitle': {
      width: '100%',
      maxWidth: '118px!important',
      marginLeft: '15px!important',
    },
    '.dealTitle': {
      width: '100%',
      maxWidth: '150px!important',
      marginLeft: '15px!important',
    },

    //gallery end-----------------

    '.sectionpadding': {
      paddingTop: '4rem',
      [theme.breakpoints.down(breakpoints.sm)]: {
        paddingTop: '10px',
      },
    },

    '.viewDetailsButton': {
      // transition: '0.3s ease-in-out all',
      border: '1px solid #D2DEEB !important',
      '&:hover': {
        backgroundColor: '#EE6A29 !important',
        color: '#fff !important',
      },
      [theme.breakpoints.down(breakpoints.md)]: {
        fontSize: '11px',
      },
    },

    '.viewDetailsButton1': {
      // transition: '0.3s ease-in-out all',
      '&:hover': {
        backgroundColor: '#0C56B2 !important',
        color: '#fff !important',
      },
      [theme.breakpoints.down(breakpoints.md)]: {
        fontSize: '13px',
      },
    },

    '.featureBoxData': {
      cursor: 'pointer',
      padding: '10px 15px',
      height: '70px',
      maxHeight: '74px',
      [theme.breakpoints.down(breakpoints.md)]: {
        padding: '5px',

        '& .MuiGrid-root.MuiGrid-container': {
          justifyContent: 'center',
        },

        '& h3': {
          fontSize: '11px',
          textAlign: 'center',
        },
      },
    },
    // "img" : {
    //   objectFit: "none !important",
    // },
    '.featureBoxContainer': {
      maxWidth: '100%',
      position: 'relative',

      [theme.breakpoints.down(breakpoints.md)]: {
        marginTop: '30px',
      },

      [theme.breakpoints.down(breakpoints.lg)]: {
        marginTop: '30px',
      },
    },

    '.featureBoxContainerNew': {
      maxWidth: '100%',
      position: 'relative',

      [theme.breakpoints.down(breakpoints.md)]: {
        marginTop: '20px',
      },

      [theme.breakpoints.down(breakpoints.lg)]: {
        marginTop: '20px',
      },
    },

    '.buyContainerText': {
      textAlign: 'left',
      display: 'block',
      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center',
        display: 'block!important',
      },
    },

    '.supporttitle': {
      textAlign: 'left!important',
      padding: '24px 0px!important',
      marginTop: '30px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center!important',
        padding: '10px 0px!important',
        marginTop: '0px!important',
      },
    },

    '.supportformtitle': {
      textAlign: 'left!important',
      // padding: "24px 0px",
      // marginTop: "30px",
      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center!important',
        padding: '10px 0px!important',
        marginTop: '0px!important',
      },
    },

    '.rentingtitle': {
      textAlign: 'left!important',
      padding: '24px 0px!important',
      marginTop: '30px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        padding: '10px 0px!important',
        marginTop: '0px!important',
      },
    },

    '.rentingcustomersImgMain': {
      display: 'flex!important',
      alignItems: 'center!important',
      justifyContent: 'center!important',
      background: '#0056B20F!important',
      width: '120px!important',
      height: '120px!important',
      borderRadius: '50%!important',
      margin: '0px auto!important',
      marginBottom: '20px!important',
    },

    '.rentingcustomersCircleImg': {
      background: '#0056B20D!important',
      height: '100px!important',
      width: '100px!important',
      borderRadius: '50%!important',
      display: 'flex!important',
      alignItems: 'center!important',
      justifyContent: 'center!important',

      '& img': {
        height: '75px!important',
      },
    },

    '.aboutcustomersCircleImg': {
      background: '#0056B20D!important',
      height: '80px!important',
      width: '80px!important',
      borderRadius: '50%!important',
      display: 'flex!important',
      alignItems: 'center!important',
      justifyContent: 'center!important',
      padding: '15px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        borderRadius: '12px!important',
        height: '70px!important',
        width: '80px!important',
      },
    },

    '.howit-works-text-heading': {
      fontSize: '15px !important',
      textAlign: 'left !important',
      marginTop: '3px !important',
    },

    '.proptryRentalService': {
      textAlign: 'right',
      paddingLeft: '10px',
      paddingRight: '10px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center',
        display: 'block',
      },
      [theme.breakpoints.down(breakpoints.ssml)]: {
        textAlign: 'center',
        display: 'block',
      },
    },

    '.proptryRentalService1': {
      textAlign: 'left',
      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center',
        display: 'block',
      },

      [theme.breakpoints.down(breakpoints.ssml)]: {
        textAlign: 'center',
        display: 'block',
      },
    },

    '.propertyRentalBoxImg': {
      backgroundColor: '#EE6A2A0A',
      border: '1px solid #EE6A2A33',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '3px',
      marginRight: '10px',
    },

    '.propertyRentalBox': {
      backgroundColor: '#FFCDB5',
      padding: '12px',
      borderRadius: '4px',
    },

    '.propertyInnerBox': {
      backgroundColor: '#EE6A2A',
      padding: '1px 5px',
      borderRadius: '4px',
    },

    '.propertyRentalBoxImg1': {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '3px',
      marginRight: '10px',
    },

    '.DebtImg1': {
      display: 'flex',
      alignItems: 'center',
      marginRight: '23px',
      background: '#FCFCFF',
      padding: '20px',
      borderRadius: '10px',
      border: '1px solid #0056B226',
      boxShadow: '0px 8px 8px #0056b215',
    },

    '.covidBoxImg1': {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '3px',
      marginRight: '10px',
    },

    '.propertyRentalBox1': {
      backgroundColor: '#0056B233',
      padding: '5px',
      borderRadius: '4px',
    },

    '.propertyDebtBox1': {
      backgroundColor: '#0056B233',
      padding: '5px',
      borderRadius: '4px',
      marginRight: '20px',
    },

    '.propertyInnerBox1': {
      backgroundColor: '#0056B2',
      padding: '1px 5px',
      borderRadius: '4px',
      width: '70px',
    },
    '.DebtInnerBox1': {
      backgroundColor: '#0056B2',
      padding: '1px 5px',
      borderRadius: '4px',
      width: '60px',
    },

    '.covideBoxImg1': {
      backgroundColor: '#FFE7DB',
      padding: '5px',
      borderRadius: '4px',
    },

    '.covideInnerBox1': {
      backgroundColor: '#FFCDB5',
      padding: '1px 5px',
      borderRadius: '4px',
      width: '60px',
    },

    '.covideBox1': {
      padding: '1px 5px',
      borderRadius: '4px',
      width: '60px',
    },

    '.homebuyingBox1': {
      backgroundColor: '#0056B233',
      padding: '5px',
      borderRadius: '4px',
      width: '70px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center',
        width: '70px',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },

    '.homebuyingBox2': {
      backgroundColor: '#EE6A2A40',
      padding: '5px',
      borderRadius: '4px',
      width: '70px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center',
        width: '70px',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },

    '.hbcardContainer': {
      background: '#FFFFFF',
      boxShadow: 'rgb(46 114 187 / 28%) 0px 2px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center',
      },

      '& li': {
        paddingLeft: '0px',
        paddingRight: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },

    '.homeinnerBox1': {
      backgroundColor: '#0056B2',
      padding: '2.5px 5px',
      borderRadius: '4px',
      width: '60px',
    },

    '.homeinnerBox2': {
      backgroundColor: '#EE6A2A',
      padding: '2.5px 5px',
      borderRadius: '4px',
      width: '60px',
    },
    '.howit-works-text': {
      fontSize: '13px !important',
      textAlign: 'left !important',
      marginTop: '2px !important',
    },
    '.rentingcustomersReviewCard': {
      border: '1px solid #C6DAE6',
      cursor: 'pointer',
      // height: '190px',
      margin: '0 13px 0px 0',
      padding: '20px 10px',
      position: 'relative',
      background: '#FFF',
      textAlign: 'center',
      borderRadius: '11px',
      // width: '272px',
      height: '196px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '272px',
      },
    },
    '.sellcustomersReviewCard': {
      border: '1px solid #0056B21A',
      cursor: 'pointer',
      width: '280px',
      margin: '0 10px 0px 0',
      padding: '12px',
      position: 'relative',
      background: '#FFF',
      textAlign: 'center',
      borderRadius: '4px',
    },
    '.brokeragecustomersReviewCard': {
      border: '2px solid #0056B245',
      margin: '0 10px 0px 10px',
      padding: '19px',
      position: 'relative',
      marginTop: '30px',
      textAlign: 'center',
      borderRadius: '10px',
      // width:'225px !Important',

      [theme.breakpoints.down(breakpoints.lg)]: {
        width: '211px !Important',
        height: '240px',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '93% !Important',
        height: 'auto',
      },
      [theme.breakpoints.down(breakpoints.mdsm)]: {
        padding: '24px',
      },
    },

    '.noBrokerText': {
      maxWidth: '100%',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 7,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    '.propertyBtnText': {
      textAlign: 'Left',
      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center',
      },
    },
    '.propertyRentalReviewCard': {
      border: '2px solid #0056B245',
      margin: '0 13px 0px 0',
      padding: '20px',
      position: 'relative',
      marginTop: '30px',
      textAlign: 'center',
      borderRadius: '10px',
      height: '200px',
      width: '273px',
    },
    '.whychoose-text': {
      fontSize: '18px !important',
      textAlign: 'center !important',
      marginTop: '15px !important',
    },

    '.nextico': {
      position: 'absolute',
      right: '7%',
      top: '58%',
      zIndex: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fff',
      border: '1px solid #ddd',
      borderRadius: '50%',
      height: '35px',
      width: '35px',
      cursor: 'pointer',
      '& img': {
        marginTop: '3px!important',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'none',
      },
    },

    '.previco-rent': {
      position: 'relative',
      left: '-1.5%',
      bottom: '55%',
      zIndex: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fff',
      border: '1px solid #ddd',
      borderRadius: '50%',
      height: '35px',
      width: '35px',
      cursor: 'pointer',
      '& img': {
        marginTop: '3px!important',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        left: '2.5%',
      },
    },

    '.nextico-rent': {
      position: 'relative',
      left: '97.5%',
      bottom: '42%',
      zIndex: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fff',
      border: '1px solid #ddd',
      borderRadius: '50%',
      height: '35px',
      width: '35px',
      cursor: 'pointer',
      '& img': {
        marginTop: '3px!important',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        left: '90.5%',
      },
    },

    '.number-head': {
      fontSize: '18px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '18px!important',
      },
    },
    '.number-text': {
      fontSize: '14px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '14px!important',
      },
    },
    '.noImpactTextBrokeragemob': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginTop: '5px',
      },
    },
    '.sticky-property-list-header': {
      position: 'sticky',
      zIndex: '98',
      top: '115px',
    },

    '.mob-home-benefits': {
      '& > div': {
        width: '150px !important',
        height: '150px !important',
      },
      [theme.breakpoints.up(breakpoints.xxs)]: {
        width: '160px',
        height: '160px',
        margin: '10px 0px',
        maxWidth: '160px',
        maxHeight: '160px',
      },
    },
    '.property-image-slider': {
      '& img': {
        borderRadius: '8px',
        [theme.breakpoints.down(breakpoints.xs)]: {
          borderRadius: '7px',
        },
      },
    },
    '.mob-designed-for-everyone': {
      width: '320px',
      maxWidth: '320px',
      margin: '0 10px 0 0',
      [theme.breakpoints.down(breakpoints.xs)]: {
        width: '270px',
        maxWidth: '270px',
      },
    },
    '.search-mob-info': {
      background: '#F8F8FF',
      padding: '18px 18px',
      position: 'relative',
      '& h1': {
        color: Variables.darkColor,
        fontSize: '13px',
        fontWeight: '700',
        paddingBottom: '2px',
        fontFamily: 'Open Sans, sans-serif!important',
        marginBottom: '5px',
      },
      '& p': {
        color: Variables.darkColor,
        fontSize: '11px',
        fontWeight: '500',
        fontFamily: 'Open Sans, sans-serif!important',
      },
      '& li': {
        fontSize: '11px',
        fontWeight: '500',
        fontFamily: 'Open Sans, sans-serif!important',
      },
    },
    // **** add search mob breadcrumbsBottom
    '.search-mob-breadcrumbsBottom': {
      padding: '8px 15px',
      [theme.breakpoints.down(breakpoints.xs)]: {
        marginTop: '24px',
      },
      '& .MuiBreadcrumbs-li': {
        fontSize: '11px!important',
        color: '#171717!important',
        fontFamily: 'Open Sans, sans-serif!important',
        fontWeight: '500!important',
        marginLeft: '6px',
        marginRight: '6px',
        marginBottom: '20px',
      },
      '& .MuiBreadcrumbs-separator': {
        marginLeft: '0px!important',
        marginRight: '0px!important',
        marginBottom: '18px',
      },
    },
    '.search-mob-breadcrumbs': {
      display: 'flex',
      alignItems: 'center',
      background: '#F3F3FF',
      padding: '8px 15px',
      borderBottom: '1px solid #D3E0ED',
      '& .MuiBreadcrumbs-li': {
        fontSize: '11px!important',
        color: '#171717!important',
        fontFamily: 'Open Sans, sans-serif!important',
        fontWeight: '500!important',
        marginLeft: '6px',
        marginRight: '6px',
      },
      '& .MuiBreadcrumbs-separator': {
        marginLeft: '0px!important',
        marginRight: '0px!important',
      },
      '& h5': {
        color: 'rgb(23, 23, 23)',
        fontSize: '10px',
        paddingTop: '3px',
        textAlign: 'right',
        fontWeight: '500',
      },
    },
    '.mob-search-bar': {
      width: '90%',
      maxWidth: '90%',
      margin: '0 5%',
      boxShadow: '0px 2px 10px #0056b21f',
      border: '1px solid #0c6cd21f',
      // [theme.breakpoints.down(breakpoints.xs)]: {
      //   },
      [theme.breakpoints.down(breakpoints.xxs)]: {
        '& input': {
          paddingLeft: '10px',
        },
      },
    },
    '.custom-modal': {
      borderRadius: '10px',
      backgroundColor: 'none',
      position: 'relative',
      overflow: 'visible',
      // overflowX: 'hidden',
      height: 'auto',
      background: 'none',
      padding: '0px',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '395px',
      maxWidth: '410px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        width: '100%',
        maxWidth: '100%',
        top: '60%',
      },
    },

    '.custom-modal-review': {
      borderRadius: '10px',
      backgroundColor: 'none',
      position: 'absolute',
      padding: '0px',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '410px',
      maxWidth: '410px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        width: '90%',
        maxWidth: '90%',
        top: '60%',
      },
    },

    '.custom-Login-modal': {
      borderRadius: '10px',
      backgroundColor: '#FFFFFF',
      position: 'absolute',
      padding: '0px',
      // padding: '20px',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '410px',
      maxWidth: '410px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        width: '90%',
        maxWidth: '90%',
      },
    },

    '.custom-modal-partner': {
      borderRadius: '10px',
      backgroundColor: '#FFFFFF',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '450px',
      maxWidth: '450px',
      [theme.breakpoints.down(breakpoints.mdll)]: {
        width: '100% !important',
        // maxWidth: '90%',
        marginTop: '41px',
        height: '95vh',
        maxHeight: '716px',
        overflow: 'scroll',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        paddingBottom: '25px',
      },

      [theme.breakpoints.down(breakpoints.ssm)]: {
        width: '100%',
        // maxWidth: '90%',
        marginTop: '41px',
        height: '95vh',
        maxHeight: '750px',
        overflow: 'scroll',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
      },

      '& img': {
        [theme.breakpoints.down(breakpoints.sm)]: {
          marginTop: '30px !important',
          right: '8px !important',
        },
      },
    },

    '.custom-modalShort': {
      borderRadius: '10px',
      backgroundColor: '#f9f9ff',
      position: 'absolute',
      padding: '20px',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '410px',
      maxWidth: '410px',
      zIndex: '9999',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        width: '90%',
        maxWidth: '90%',
      },

      '& div.MuiTabs-scroller': {
        backgroundColor: '#FFFFFF !Important',
        borderRadius: '10px 10px 0px 0px',

        '& .MuiButtonBase-root': {
          color: Variables.darkColor,
          border: '0',
          boxShadow: 'none',
          fontSize: '14px',
          fontWeight: 'bold',
          fontFamily: 'OpenSans',
          background: 'rgb(243, 243, 255)',
          opacity: '1',
        },

        '& .MuiButtonBase-root.Mui-selected': {
          color: Variables.darkColor,
          border: '0',
          boxShadow: 'none',
          backgroundColor: '#fff',
          fontSize: '16px',
          fontWeight: 'bold',
          fontFamily: 'OpenSans',
        },
      },

      ' & div.MuiBox-root': {
        backgroundColor: '#FFFFFF !Important',
        borderRadius: '0px 0px 10px 10px',

        '& h5': {
          paddingTop: '7px',
        },
      },
    },

    '.custom-modalNew': {
      borderRadius: '10px',
      backgroundColor: '#FFFFFF',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '612px',
      maxWidth: '612px',
      padding: '20px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        width: '90%',
        maxWidth: '90%',
      },
    },

    '.custom-modalReview': {
      borderRadius: '10px',
      backgroundColor: '#f9f9ff',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '395px',
      maxWidth: '600px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        width: '90%!important',
        maxWidth: '90%!important',
      },
    },

    '.custom-modal1': {
      borderRadius: '10px',
      backgroundColor: '#f9f9ff',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '550px',
      maxWidth: '550px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        width: '90%',
        maxWidth: '90%',
      },
    },

    '.customOne': {
      position: 'relative',
    },

    '.custom-modal2': {
      borderRadius: '10px',
      backgroundColor: '#f9f9ff',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '718px !important',
      maxWidth: '718px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        width: '90%',
        maxWidth: '90%',
        padding: '1px',
        height: 'auto',
        marginTop: '-21px',
        borderRadius: '17px',
        backgroundColor: 'transparent',
      },

      '& h5': {
        fontSize: '20px',
      },

      [theme.breakpoints.down(breakpoints.sm)]: {
        padding: '0px 0px 14px',

        '& button': {
          textAlign: 'right !important',
          background: 'transparent',
          border: 'none',
          '& svg': {
            display: 'none',
          },
        },
      },
    },

    '.empHelp': {
      marginBottom: '70px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        marginBottom: '144px',
      },
    },

    '.react-horizontal-scrolling-menu--scroll-container': {
      overflow: 'scroll!important',
    },

    '.rentingTopImg1': {
      position: 'absolute',
      backgroundColor: '#0056B2',
      padding: '8px 13px',
      borderRadius: '49%',
      border: '7px solid #cde3fa',
      top: '-39px',
      left: '37%',
      [theme.breakpoints.down(breakpoints.xs)]: {
        left: '37% !Important',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        left: '39%',
      },
      [theme.breakpoints.down(breakpoints.ms)]: {
        left: '39%',
      },
      [theme.breakpoints.down(breakpoints.ssm)]: {
        left: '37%',
      },

      [theme.breakpoints.down(breakpoints.ssmll)]: {
        left: '40%',
      },
    },

    '.home_selling_modal': {
      borderRadius: '20px',
      backgroundColor: '#F9F9FF',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxWidth: '800px',

      '& .homesellingPopup': {
        background: '#F9F9FF',
        borderRadius: '20px',

        '& > .headerBg': {
          background: '#FFFFFF',
          padding: '1px 22px',
          borderRadius: '20px',
          justifyContent: 'center',
          '& > h3': {
            fontSize: '20px',
            color: Variables.darkColor,
          },
        },

        '& > .bodyBg': {
          padding: '22px',
          '& > h6': {
            color: '#0056B2',
            fontSize: '16px',
          },
          '& > p': {
            color: '#363636',
            fontSize: '14px',
            lineHeight: '24px',
            marginBottom: '16px',
          },
        },
      },

      [theme.breakpoints.down(breakpoints.ssm)]: {
        width: '90%',
        maxWidth: '90%',
      },
    },
    '.search-container': {
      width: '100%',
      maxWidth: '100%',
      height: '35px',
      '& .module-type-grid': {
        '& > div': {
          height: '35px',
        },
        [theme.breakpoints.down(breakpoints.lg)]: {
          width: '20%',
        },
        [theme.breakpoints.up(breakpoints.lg)]: {
          width: '20%',
        },
        [theme.breakpoints.up(breakpoints.xl)]: {
          width: '20%',
        },
        [theme.breakpoints.down(breakpoints.mdl)]: {
          width: '17%!important',
        },
        [theme.breakpoints.down(breakpoints.mdd)]: {
          width: '17%',
        },
        // borderTop: '1px solid #0056B2',
        // borderLeft: '1px solid #0056B2',
        // borderBottom: '1px solid #0056B2',
        // borderBottomLeftRadius: 0,
        // borderTopLeftRadius: 0,
        // boxShadow: '0px 2px 10px rgba(46, 114, 187, 0.28)',
      },
      '& .city-grid': {
        '& > div': {
          height: '35px',
        },
        [theme.breakpoints.down(breakpoints.lg)]: {
          width: '27%',
        },
        [theme.breakpoints.up(breakpoints.lg)]: {
          width: '30%',
        },
        [theme.breakpoints.up(breakpoints.xl)]: {
          width: '30%',
        },
        [theme.breakpoints.down(breakpoints.mdl)]: {
          width: '27%!important',
        },
        [theme.breakpoints.down(breakpoints.mdd)]: {
          width: '27%!important',
        },
        // borderTop: '1px solid #0056B2',
        // borderBottom: '1px solid #0056B2',
        // boxShadow: '0px 2px 10px rgba(46, 114, 187, 0.28)',
        // borderLeft: '1px solid #d2d8dd',
        // borderRight: '1px solid #d2d8dd',
      },
      '& .input-grid': {
        '& input': {
          height: '25px',
        },
        [theme.breakpoints.down(breakpoints.lg)]: {
          width: '53%',
        },
        [theme.breakpoints.up(breakpoints.lg)]: {
          width: '50%',
        },
        [theme.breakpoints.up(breakpoints.xl)]: {
          width: '50%',
        },
        [theme.breakpoints.down(breakpoints.mdl)]: {
          width: '56%!important',
        },
        [theme.breakpoints.down(breakpoints.mdd)]: {
          width: '56%',
        },
        // boxShadow: '0px 2px 10px rgba(46, 114, 187, 0.28)',
      },
      '& .search-button-grid': {
        '& > div': {
          height: '35px',
        },
        display: 'flex',
        alignItems: 'center',
        width: '5%',
        // borderTop: '1px solid #0056B2',
        // borderRight: 'none !important',
        // borderLeft: 'none !important',
        // borderBottom: '1px solid #0056B2',
        // borderBottomRightRadius: 0,
        // borderTopRightRadius: 0,
        // backgroundColor: '#ffffff',
      },
    },

    '.verticalLine': {
      [theme.breakpoints.down(breakpoints.ssm)]: {
        display: 'none',
      },
    },

    '.overlayImgBox': {
      [theme.breakpoints.down(breakpoints.ssm)]: {
        padding: '0px',
      },
    },

    '.menu-wrapper--inner > div:first-child > div:first-child': {
      [theme.breakpoints.up(breakpoints.ssm)]: {
        marginLeft: '0px !important',
      },
    },

    // '.menu-wrapper--inner > div:last-child > div:last-child': {
    //   [theme.breakpoints.up(breakpoints.ssm)]: {
    //     marginRight: '0px !important',
    //   },
    // },

    '.menu-wrapper--inner > div:nth-child(3n) > div:first-child': {
      [theme.breakpoints.up(breakpoints.ssm)]: {
        marginRight: '0px !important',
      },
    },
    '.menu-wrapper--inner > div:nth-child(3n+1) > div:first-child': {
      [theme.breakpoints.up(breakpoints.ssm)]: {
        marginLeft: '0px !important',
      },
    },

    '.blog-inner-wrapper': {
      [theme.breakpoints.up(breakpoints.xxs)]: {
        marginBottom: '-10px',
        paddingBottom: '20px',
      },
      [theme.breakpoints.up(breakpoints.ssml)]: {
        paddingBottom: '30px',
      },
    },
    /* Rental property css */
    '.Rentalproperty': {
      margin: '0 13px 0px 0',
      position: 'relative',
      marginTop: '3px',
      textAlign: 'center',
      borderRadius: '10px',
      // height: '200px',
      // width: '273px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        margin: '0px',
      },

      '& figure span': {
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
      },
    },

    '.rentalHeadText': {
      textAlign: 'Left',
      padding: '2px',
      backgroundColor: '#FFFFFF',
      border: '1px solid #ccc',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
      borderTop: 'none',
      paddingLeft: '13px',
      marginTop: '-5px',
      paddingTop: '10px',
      paddingBottom: '8px',
    },

    '.rentalImages': {
      '& figure::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: '5px',
        right: 0,
        left: 0,
        background:
          'linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.4) 21%, rgba(0,0,0,0) 37%)',
        display: 'block',
        zIndex: 1,
        cursor: 'pointer',
      },
    },

    '.rentalBanner': {
      background: '#DCEFF5',
      width: '100%',
      borderRadius: '10px',
      border: '1px solid #CAD7DB',
      marginBottom: '20px',
      height: '105px',
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        height: 'auto',
      },
      '& img': {
        borderRadius: '10px',
      },
    },

    '.rentalBannerMob': {
      background: '#DCEFF5',
      width: '100%',
      borderRadius: '10px',
      border: '1px solid #CAD7DB',
      height: '294px',
      position: 'relative',
      backgroundImage: 'url(/static/images/rental-collection/img11.png)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      marginBottom: '35px',

      '& img': {
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
      },
    },

    '.rentalInner': {
      background: '#DCEFF5',
      paddingBottom: '153px',
      mixBlendMode: 'multiply',
      borderRadius: '7px',
      [theme.breakpoints.down(breakpoints.xss)]: {
        paddingBottom: '134px',
      },
    },

    '.rentalMobImg': {
      padding: '1px',

      '& img': {
        height: '300px !Important',
      },
    },

    '.RentalSection': {
      margin: '0 13px 0px 0',
      position: 'relative',
      marginTop: '45px',
      textAlign: 'center',
      borderRadius: '10px',
      height: '200px',
      width: '370px',
      [theme.breakpoints.down('md')]: {
        height: '200px',
        width: '346px',
      },
      [theme.breakpoints.up(breakpoints.mdd)]: {
        height: '200px',
        width: '316px',
      },
      [theme.breakpoints.down(breakpoints.ssm)]: {
        width: '100%',
        height: '74px',
        marginTop: '7px',
        '&:last-child': {
          marginBottom: '32px',
        },
      },
      [theme.breakpoints.up(breakpoints.lg)]: {
        height: '200px',
        width: '375px',
      },

      [theme.breakpoints.up(breakpoints.mds)]: {
        height: '200px',
        width: '355px',
      },
      [theme.breakpoints.up(breakpoints.lllg)]: {
        height: '240px',
        width: '375px',
      },

      // [theme.breakpoints.up('md')]: {
      //   width: 'auto !important',

      // },
    },

    '.rentalImages1': {
      '& figure::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: '5px',
        right: 0,
        left: 0,
        // background:
        // 'linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.4) 21%, rgba(0,0,0,0) 37%)',
        display: 'block',
        zIndex: 1,
        cursor: 'pointer',
        borderRadius: '10px',

        [theme.breakpoints.down(breakpoints.ssm)]: {
          background:
            'linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.4) 21%, rgba(0,0,0,0) 37%)',
          height: '74px',
        },
      },
      '& span': {
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
      },
    },

    '.mobrentalImages1': {
      '& figure span': {
        position: 'inherit !important',
      },

      '& figure::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: '5px',
        right: 0,
        left: 0,
        // background:
        // 'linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.4) 21%, rgba(0,0,0,0) 37%)',
        display: 'block',
        zIndex: 1,
        cursor: 'pointer',
        borderRadius: '10px',

        [theme.breakpoints.down(breakpoints.ssm)]: {
          background:
            'linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.4) 21%, rgba(0,0,0,0) 37%)',
          height: '74px',
        },

        [theme.breakpoints.down(breakpoints.mss)]: {
          background:
            'linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.4) 21%, rgba(0,0,0,0) 37%)',
          height: '70px !important',
        },
        [theme.breakpoints.down(breakpoints.xs)]: {
          background:
            'linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.4) 21%, rgba(0,0,0,0) 37%)',
          height: '69px !important',
        },

        [theme.breakpoints.down(breakpoints.xss)]: {
          background:
            'linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.4) 21%, rgba(0,0,0,0) 37%)',
          height: '65px !important',
        },
      },
      '& span': {
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
      },
    },

    '.btnExplore': {
      backgroundColor: Variables.primaryColor,
      paddingBottom: '8px',
      borderBottomLeftRadius: '7px',
      borderBottomRightRadius: '7px',
      cursor: 'pointer',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        position: 'absolute',
        top: '22%',
        right: '21px',
        borderRadius: '8px !important',
        padding: '9px',
        zIndex: '99',
      },
    },

    '.rentalSubText': {
      marginBottom: '15px',
      marginTop: '15px',
      textAlign: 'center',
      position: 'absolute',
      top: '5px',
      left: '29%',
      zIndex: '1',
      backgroundColor: '#FFFFFF',
      borderRadius: '7px',
      width: '150px',
      paddingTop: '5px',
    },

    '.rentalSubTextMob': {
      marginBottom: '15px',
      marginTop: '15px',
      textAlign: 'Left',
      position: 'absolute',
      top: '5px',
      zIndex: '1',
      borderRadius: '7px',
      paddingTop: '5px',
      fontSize: '16px',
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      color: '#FFFFFF',
      paddingLeft: '29px',
    },

    '.postPropertyBtn': {
      backgroundColor: Variables.primaryColor,
      borderRadius: '6px',
      color: '#FFFFFF',
      zIndex: '9',
      position: 'absolute',
      top: '54%',
      left: '59%',
      [theme.breakpoints.down(breakpoints.sm)]: {
        position: 'initial',
        width: '143px',
        paddingLeft: '7px',
        marginLeft: '28px',
        padding: '9px',
      },
    },
    /* End Rental property css */

    '.testimonial-card-item1': { marginRight: '5px' },

    '.testimonial-inner-wrapper > div:first-child > div:first-child': {
      [theme.breakpoints.up(breakpoints.ssm)]: {
        marginLeft: '0px !important',
      },
    },

    '.review-inner-wrapper': {
      marginRight: '-10px',
      [theme.breakpoints.up(breakpoints.xxs)]: {
        marginBottom: '7px !Important',
      },
    },

    '.review-inner-wrapper > div:first-child > div:first-child': {
      [theme.breakpoints.up(breakpoints.xxs)]: {
        marginLeft: '0px !important',
        marginRight: '0px !important',
      },
      [theme.breakpoints.up(breakpoints.ssm)]: {
        marginLeft: '0px !important',
        marginRight: '0px !important',
      },
    },
    '.shortlisted-inner-wrapper': {
      // marginRight: '-10px',
      [theme.breakpoints.up(breakpoints.sm)]: {
        marginBottom: '10px',
        marginRight: '14px',
      },

      [theme.breakpoints.up(breakpoints.ssmdl)]: {
        marginBottom: '10px',
        marginRight: '0px',
      },
    },

    '.shortlisted-inner-wrapper > div:first-child > div:first-child': {
      [theme.breakpoints.up(breakpoints.xxs)]: {
        marginLeft: '0px !important',
        marginRight: '0px !important',
      },
      [theme.breakpoints.up(breakpoints.ssm)]: {
        marginLeft: '0px !important',
        marginRight: '0px !important',
      },
    },
    '.shortlisted-inner-wrapper > div:nth-child(odd) > div:nth-child(odd)': {
      [theme.breakpoints.up(breakpoints.xxs)]: {
        marginLeft: '0px',
        marginRight: '0px',
      },
      [theme.breakpoints.up(breakpoints.ssm)]: {
        marginLeft: '0px',
        marginRight: '0px',
      },
    },

    '.review-inner-wrapper > div:nth-child(odd) > div:nth-child(odd)': {
      [theme.breakpoints.up(breakpoints.xxs)]: {
        marginLeft: '0px',
        marginRight: '0px',
      },
      [theme.breakpoints.up(breakpoints.ssm)]: {
        marginLeft: '0px',
        marginRight: '0px',
      },
    },

    // ".testimonial-inner-wrapper > div:nth-child(3n) > div:first-child": {
    //   [theme.breakpoints.up(breakpoints.ssm)]: {
    //     marginRight: "0px !important",
    //   },
    // },
    // ".testimonial-inner-wrapper > div:nth-child(3n+1) > div:first-child": {
    //   [theme.breakpoints.up(breakpoints.ssm)]: {
    //     marginLeft: "0px !important",
    //   },
    // },
    '.sitemapBtns': {
      justifyContent: 'left',
      [theme.breakpoints.down(breakpoints.sm)]: {
        justifyContent: 'center',
      },
    },

    '.sitemapImg': {
      alignItems: 'center',
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'block',
        alignItems: 'center',
      },
    },

    '.infoSection1': {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        display: 'block',
        alignItems: 'center',
      },
    },

    '.toolbarWrapper': {
      height: 'auto',
      boxShadow: 'none !important',
      transition: 'all 0.3s ease-in-out',
      '& .MuiToolbar-regular': {
        minHeight: '60px!important',
        [theme.breakpoints.down(breakpoints.ssm)]: {
          minHeight: '40px!important',
        },
      },
      '& .MuiToolbar-gutters': {
        padding: 0,
        marginTop: '0px',
      },
      [theme.breakpoints.down(breakpoints.ssm)]: {
        height: '51px',
      },
    },
    '.toolbarWrapper > div:first-child': {
      [theme.breakpoints.down(breakpoints.ssm)]: {
        padding: '0 10px',
      },
    },
    '.toolbarWrapper > div:last-child': {
      [theme.breakpoints.down(breakpoints.ssm)]: {
        height: '40px',
        marginTop: '3px',
        padding: '0px 8px',
      },
      height: '56px',
      padding: '0px 20px',
      marginTop: '-1px',
    },
    '.main-section1': {
      marginTop: '47px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        paddingTop: '0px',
        '& .heading-section': {
          paddingRight: '0px',
        },
        margin: '0px 0 0 10px',
      },
      '& .card-section, & .builder-section, & .footer-section': {
        padding: '10px 0 0px',
        marginTop: '10px',
        [theme.breakpoints.down(breakpoints.ssm)]: {
          padding: '10px 0px 0px 0px',
        },
      },
    }, // TOP Builders
    '.main-section': {
      marginTop: '3rem',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        zIndex: '9',
        position: 'relative',
        paddingTop: '10px',
        '& .heading-section': {
          paddingRight: '10px',
          marginBottom: '20px',
          marginTop: '20px',
        },
        margin: '0 0 0 20px',
      },
      '& .card-section, & .builder-section, & .footer-section': {
        // padding: '0px 0 25px',
        // [theme.breakpoints.down(breakpoints.ssm)]: {
        //   padding: '10px 0',
        // },
      },
    },

    '.main-section2': {
      marginTop: '20px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        paddingTop: '10px',
        '& .heading-section': {
          paddingRight: '10px',
        },
        margin: '-48px 0 0 0px',
      },
      '& .card-section, & .builder-section, & .footer-section': {
        // padding: '0px 0 25px',
        // [theme.breakpoints.down(breakpoints.ssm)]: {
        //   padding: '10px 0',
        // },
      },
    },

    '.moduleTypeButton': {
      width: '100%',
      height: '45px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        height: '35px',
      },
    },
    '.view-all-link': {
      fontSize: '16px',
      fontWeight: '700',
      padding: '10px 0px 10px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        fontSize: '13px !Important',
        fontWeight: '700',
        color: Variables.primaryColor,
        marginRight: '10px !Important',
        marginTop: '3px',
        lineHeight: '2',
      },
      [theme.breakpoints.down(breakpoints.xss)]: {
        marginRight: '0px !Important',
      },
    },

    '.view-all-link1': {
      fontSize: '12px',
      fontWeight: '700',
      cursor: 'pointer',
      color: '#0056b2',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        fontSize: '13px !Important',
        fontWeight: '700',
        color: Variables.primaryColor,
        marginRight: '10px !Important',
        marginTop: '3px',
        lineHeight: '2',
      },
      [theme.breakpoints.down(breakpoints.xss)]: {
        marginRight: '0px !Important',
      },
    },
    '.container': {
      [theme.breakpoints.between('sm', 'md')]: {
        width: '100%!important',
        maxWidth: '100%!important',
        paddingLeft: '30px!important',
        paddingRight: '30px!important',
      },
      margin: '0 auto !important',
      [theme.breakpoints.down('ssm')]: {
        margin: '0',
        width: '100%',
        maxWidth: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0',
      },
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        maxWidth: '100%',
        paddingLeft: '25px',
        paddingRight: '25px',
      },
      [theme.breakpoints.up('md')]: {
        width: 960,
        maxWidth: 960,
        paddingLeft: 0,
        paddingRight: 0,
      },
      [theme.breakpoints.up('lg')]: {
        width: 1140,
        maxWidth: 1140,
      },
      [theme.breakpoints.up(breakpoints.xl)]: {
        width: 1287,
        maxWidth: 1287,
      },
    },
    '.row': {
      display: 'flex',
      flexWrap: 'wrap',
      marginRight: -theme.spacing(2),
      marginLeft: -theme.spacing(2),
    },
    '.developer-card-grid-left': {
      height: '100%',
      maxHeight: 350,
      [theme.breakpoints.up(breakpoints.xl)]: {
        maxHeight: 550,
      },
    },

    '.mapContainer': {
      borderRadius: '10px',
    },

    '.item1': {
      cursor: 'pointer',
      display: 'block',
      marginRight: '10px',
      width: '23.5%',
      borderRadius: '14px',
      float: 'left',
      marginBottom: '10px',
      textAlign: 'center',
      background: '#fff',
      border: '1px solid #D3E0ED',
      animation: 'fadeInAnimation ease 3s',
      animationIterationCount: 1,
      animationFillMode: 'forwards',
      '& img': {
        width: '100%',
        borderRadius: '14px',
      },

      [theme.breakpoints.down(breakpoints.ssml)]: {
        width: '22.5% !important',
      },
    },

    '.masonry-images-container': {
      marginRight: '-14px!important',
    },

    '.developer-card-grid-right': {
      height: '100%',
      maxHeight: 350,
      '& .masonry-item': {
        height: '5.54rem',
        '& > img': {
          width: '100%',
          height: '100%',
        },
      },
      [theme.breakpoints.down(breakpoints.ssm)]: {
        '& .masonry-item': {
          marginLeft: '8px',
          marginRight: '3px',
          width: '22.5% !important',
        },
      },
      [theme.breakpoints.down(breakpoints.ssml)]: {
        '& .masonry-item': {
          marginLeft: '3px',
          marginRight: '3px',
          width: '23.5% !important',
          marginBottom: '20px',
        },
      },
      [theme.breakpoints.up(breakpoints.xl)]: {
        maxHeight: 550,
        '& .masonry-item': {
          height: '5.75rem',
          '& > img': {
            width: '100%',
            height: '100%',
          },
        },
      },
    },
    '.homepage-header': {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        // height: "250px !important",
        backgroundColor: '#F9F9FF',
      },
      // [theme.breakpoints.up(breakpoints.xxs)]: {
      //   maxHeight: "50vh",
      //   height: "50vh",
      // },

      [theme.breakpoints.up(breakpoints.ssm)]: {
        '& .MuiTypography-h1': {
          [theme.breakpoints.up(breakpoints.ssm)]: {
            fontSize: '30px !important',
          },
          [theme.breakpoints.up(breakpoints.mdl)]: {
            fontSize: '48px !important',
          },
        },
      },
    },

    '.homepage-header-mob': {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        // height: "250px !important",
        backgroundColor: '#F9F9F9',
      },
      // [theme.breakpoints.up(breakpoints.xxs)]: {
      //   maxHeight: "50vh",
      //   height: "50vh",
      // },

      [theme.breakpoints.up(breakpoints.ssm)]: {
        '& .MuiTypography-h1': {
          [theme.breakpoints.up(breakpoints.ssm)]: {
            fontSize: '30px !important',
          },
          [theme.breakpoints.up(breakpoints.mdl)]: {
            fontSize: '48px !important',
          },
        },
      },
    },

    '.container-fluid': {
      width: '100%',
      maxWidth: 2400,
      [theme.breakpoints.up(breakpoints.xl)]: {
        width: '100%',
        maxWidth: 6000,
      },
    },

    '.lg-mg-top': {
      marginTop: `${theme.spacing(20)}px !important`,
      [theme.breakpoints.down('md')]: {
        marginTop: `${theme.spacing(18)}px !important`,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: `${theme.spacing(16)}px !important`,
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: `${theme.spacing(14)}px !important`,
      },
    },
    '.lg-mg-bottom': {
      marginBottom: `${theme.spacing(20)}px !important`,
      [theme.breakpoints.down('md')]: {
        marginBottom: `${theme.spacing(18)}px !important`,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: `${theme.spacing(16)}px !important`,
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: `${theme.spacing(14)}px !important`,
      },
    },
    '.lg-p-top': {
      paddingTop: `${theme.spacing(20)}px !important`,
      [theme.breakpoints.down('md')]: {
        paddingTop: `${theme.spacing(18)}px !important`,
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: `${theme.spacing(16)}px !important`,
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: `${theme.spacing(14)}px !important`,
      },
    },
    'input[type="text"] i, input[type="email"] i': {
      padding: 0,
    },
    '.box-container': {
      boxShadow: '1px 1px 6px 0 rgb(32 33 36 / 11%) !important',
      backgroundColor: '#fff !important',
    },
    '.mob-home-assistance': {
      width: '135px',
      // height: "150px",
    },
    '.mob-home-owner, .mob-why-hb': {
      width: '300px',
      // height: "120px",
      margin: '0 1rem 0 0',
    },
    '.mob-property-card': {
      whiteSpace: 'nowrap',
      width: '300px',
      minWidth: '96%',

      [theme.breakpoints.down(breakpoints.xs)]: {
        width: '300px',
        minWidth: '96%',
        borderRadius: '9px !important',
        boxShadow: 'none',
        '& cardIndexMain > span': {
          fontSize: '12px',
        },
      },

      '& h4': {
        display: 'inline-block',
      },
      '& .MuiCardContent-root': {
        padding: '0px !important',
      },
    },

    //rent-homepage
    '.mob-rent-property-card': {
      whiteSpace: 'nowrap',
      width: '300px',
      minWidth: '96%',

      [theme.breakpoints.down(breakpoints.xs)]: {
        width: '259px',
        minWidth: '96%',
        '& cardIndexMain > span': {
          fontSize: '12px',
        },
      },

      '& h4': {
        display: 'inline-block',
        width: '200px',
        whiteSpace: 'nowrap',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis',
        // whiteSpace: "nowrap",
        // overflow: "hidden !important",
        //  textOverflow: "ellipsis",
      },
    },
    //mob-builder-card start
    '.mob-property-card1': {
      whiteSpace: 'nowrap',
      width: '300px',
      minWidth: '96%',
      borderRadius: '8px',
      // height:"520px",

      [theme.breakpoints.down(breakpoints.ms)]: {
        width: '259px',
        minWidth: '96%',
        marginBottom: '1px',
        marginTop: '1px',
        boxShadow: 'none',
        // height:"510px",
        '& cardIndexMain > span': {
          fontSize: '12px',
        },
      },

      [theme.breakpoints.down(breakpoints.xs)]: {
        width: '259px',
        minWidth: '96%',
        // height:"510px",
        '& cardIndexMain > span': {
          fontSize: '12px',
        },
      },

      '& h4': {
        display: 'inline-block',
      },

      '& .MuiCardContent-root': {
        padding: '0px !important',
      },
    },

    '.builderProjectCardImg1': {
      margin: '0 15px 0px 0px',
      height: '160px',
      position: 'relative',
      [theme.breakpoints.down(breakpoints.ms)]: {
        height: '143px',
      },
      [theme.breakpoints.down(breakpoints.xs)]: {
        height: '148px',
      },
    },

    '.property-card-item1': {
      paddingRight: '10px !important',
      marginRight: '5px!important',
      verticalAlign: 'top',
      [theme.breakpoints.down(breakpoints.xs)]: {
        marginBottom: '2px',
      },
    },

    //mob-builder-card end

    //desktop-builder-card start
    '.builderGroupImg': {
      background: '#fff',
      padding: '5px',
      marginRight: '20px',
      width: '200px',
      height: '200px',
      alignItems: 'center',
      display: 'flex',
      [theme.breakpoints.down(breakpoints.xs)]: {
        margin: '40px',
        marginRight: '40px !Important',
      },
      [theme.breakpoints.down('md')]: {
        marginRight: 'auto !important',
        width: '65%',
        marginLeft: 'auto ',
        marginBottom: '10px',
        '& picture': {
          width: '100%',
          height: '100%',
          display: 'block',
        },
        '& picture > span': {
          width: '100% !important',
          height: '100% !important',
        },
      },
      border: '1px solid #D3E0ED',
      borderRadius: '14px',

      '& img': {
        objectFit: 'contain !Important',
      },
    },

    //desktop-builder-card end

    //mob developers card start
    '.mob-developers-card': {
      // backgroundColor: 'rgba(249, 249, 255, 1)',
      borderRadius: '5px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        margin: '0 1rem 0rem 0!important',
        // width: '297px!important',
      },
      // [theme.breakpoints.down(breakpoints.mdl)]: {
      //   width:'297px !important',
      // }
    },
    '.developerslogomain': {
      display: 'flex!important',
      alignItems: 'center!important',

      '& h4': {
        fontSize: '20px !important',
        color: '#cd4b0d',
        fontWeight: '700',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '178px',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down(breakpoints.sm)]: {
          fontSize: '15px !important',
          width: '144px',
        },
      },
    },
    //mob developers card end

    //QA start --------------------
    '.buttoncontainer-qa': {
      borderBottom: '1px solid #D3E0ED',
      borderRadius: '0px 0px 12px 12px',
      backgroundColor: '#F3F3FF',
      padding: '12px 10px',
      textAlign: 'center',
      borderTop: '1px solid #D3E0ED',
      textTransform: 'initial!important',

      '& .MuiButton-contained': {
        background: '#0056B2',
        color: '#fff',
        borderRadius: '10px',
        fontSize: '14px!important',
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '700!important',
        textTransform: 'initial!important',
        [theme.breakpoints.up(breakpoints.xl)]: {
          fontSize: '12px!important',
          fontWeight: '700!important',
        },
      },
    },

    '.question-Ans': {
      fontSize: '14px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '500!important',
      paddingLeft: '15px',
      paddingRight: '15px',
      paddingBottom: '15px',
    },

    '.question': {
      fontSize: '14px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      '& h3': {
        fontSize: '14px!important',
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '700!important',
      },
    },
    '.question-ans': {
      fontSize: '10px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '500!important',
      borderTop: '1px solid #D3E0ED',
      padding: '15px',
      textAlign: 'justify',
    },

    '.qa-ans': {
      padding: '0px 0px 15px 0px',
    },
    '.view-all-details': {
      fontSize: '14px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      color: '#171717!important',
    },

    //QA end --------------------

    //Feature box Start

    '.featureBoxMain': {
      backgroundColor: '#fff!important',
      borderRadius: '17px!important',
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px!important',
      padding: '21px 25px!important',
      marginBottom: '48px!important',
      textAlign: 'center!important',
      cursor: 'pointer!important',
      marginLeft: '5px',
      marginRight: '5px',
      height: '200px',
      // [theme.breakpoints.down(breakpoints.mdl)]: {
      //   height:"180px important",
      //   padding:"2px!important"
      // },
      [theme.breakpoints.down(breakpoints.mdl)]: {
        height: 'auto important',
        padding: '2px!important',
      },

      [theme.breakpoints.down(breakpoints.ssml)]: {
        // width: "170px!important",
        height: 'auto!important',
        padding: '7px!important',
        borderRadius: '8px!important',
        marginBottom: '10px!important',
      },
      [theme.breakpoints.down(breakpoints.md)]: {
        height: 'auto!important',
        padding: '10px!important',
      },
      [theme.breakpoints.down(breakpoints.ssm)]: {
        // width: "80px!important",
        padding: '7px!important',
        height: 'auto!important',
        borderRadius: '8px!important',
        marginBottom: '10px!important',
        margin: '5px 0px 7px 2px!important',
      },

      [theme.breakpoints.down(breakpoints.ssml)]: {
        height: 'auto!important',
        padding: '2px!important ',
      },

      [theme.breakpoints.down(breakpoints.mmm)]: {
        height: '160px!important',
        padding: '23px!important',
        width: '130px',
      },

      [theme.breakpoints.down(breakpoints.sm)]: {
        height: '110px!important',
        padding: '16px!important',
        width: '106px',
      },

      [theme.breakpoints.down(breakpoints.ms)]: {
        height: '110px!important',
        padding: '12px!important',
        width: '90px',
      },

      [theme.breakpoints.down(breakpoints.mss)]: {
        height: '100px!important',
        padding: '8px!important',
        width: '80px!important',
      },

      [theme.breakpoints.down(breakpoints.xs)]: {
        // width: "85px!important",
        padding: '5px!important',
        //   padding:"7px!important",
        height: '94px!important',
        borderRadius: '8px!important',
        marginBottom: '10px!important',
        margin: '5px 0px 7px 0px!important',
        width: '78px!important',
      },
      [theme.breakpoints.down(breakpoints.xxxs)]: {
        // width: "68px!important",
        width: '68px!important',
        padding: '4px!important',
        height: '96px!important',
      },
      '&:hover': {
        backgroundColor: '#0056b2 !important',
        //transition: '400ms linear;!important',
      },
    },
    '.featureBoxImg': {
      marginBottom: '12px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginTop: '3px!important',
        marginBottom: '3px!important',
      },
      '& img': {
        [theme.breakpoints.down(breakpoints.sm)]: {
          height: '24px!important',
        },
      },
    },

    '.BoxTitle': {
      fontSize: '13px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      color: '#fff!important',
      marginBottom: '17px!important',
      [theme.breakpoints.down(breakpoints.xxxs)]: {
        fontSize: '8px!important',
        fontWeight: '700 !important',
      },

      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '9px!important',
      },

      [theme.breakpoints.down(breakpoints.xs)]: {
        fontSize: '8px!important',
      },
    },

    /* start SHORTLISTCSS */

    '.shortContainerMob': {
      paddingTop: '20px',

      [theme.breakpoints.down(breakpoints.sm)]: {
        paddingTop: '40px',
      },
      '& .property-card': {
        width: '334px',
        marginLeft: '8px',
        marginRight: '8px',

        [theme.breakpoints.down(breakpoints.sm)]: {
          width: '277px',
          borderRadius: '10px',
          padding: '10px !Important',
        },

        '&:first-child': {
          marginLeft: '0px !important',
          marginRight: '14px !important',
        },

        '&:last-child': {
          marginRight: '0px !important',
        },
      },
      '& .projectData': {
        '& a': {
          color: '#EE6A29',
          fontSize: '18px',
          fontWeight: 'bold',
        },
      },
    },
    '.shortContainerMob1': {
      paddingTop: '20px',

      [theme.breakpoints.down(breakpoints.sm)]: {
        paddingTop: '20px',
      },
      '& .property-card': {
        width: '334px',
        marginLeft: '8px',
        marginRight: '8px',

        [theme.breakpoints.down(breakpoints.sm)]: {
          width: '277px',
          borderRadius: '10px',
          padding: '10px !Important',
        },

        '&:first-child': {
          marginLeft: '0px !important',
          marginRight: '14px !important',
        },

        '&:last-child': {
          marginRight: '0px !important',
        },
      },
      '& .projectData': {
        '& a': {
          color: '#EE6A29',
          fontSize: '18px',
          fontWeight: 'bold',
        },
      },
    },
    '.shortContainerMob2': {
      paddingTop: '20px',

      [theme.breakpoints.down(breakpoints.sm)]: {
        paddingTop: '0px',
      },
      '& .property-card': {
        width: '334px',
        marginLeft: '8px',
        marginRight: '8px',

        [theme.breakpoints.down(breakpoints.sm)]: {
          width: '277px',
          borderRadius: '10px',
          padding: '10px !Important',
        },

        '&:first-child': {
          marginLeft: '0px !important',
          marginRight: '14px !important',
        },

        '&:last-child': {
          marginRight: '0px !important',
        },
      },
      '& .projectData': {
        '& a': {
          color: '#EE6A29',
          fontSize: '18px',
          fontWeight: 'bold',
        },
      },
    },

    '.deleteBtnMob': {
      textAlign: 'center',
      paddingTop: '10px',
      paddingBottom: '10px',
      cursor: 'pointer',
      width: '277px',
    },

    /* end SHORTLISTCSS */

    '.BoxTitleHover': {
      fontSize: '13px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      color: '#0053AD!important',
      marginBottom: '17px!important',

      [theme.breakpoints.down(breakpoints.md)]: {
        fontSize: '11px!important',
      },

      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '9px!important',
      },
      [theme.breakpoints.down(breakpoints.ms)]: {
        fontSize: '9px!important',
        fontWeight: '700 !important',
      },
      [theme.breakpoints.down(breakpoints.xxxs)]: {
        fontSize: '8px!important',
        fontWeight: '700 !important',
      },
    },

    '.BodyText': {
      fontSize: '13px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      color: '#fff!important',
      marginBottom: '23px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '9px!important',
      },
    },
    '.BodyTextHover': {
      fontSize: '13px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      color: '#707070!important',
      marginBottom: '23px!important',
      [theme.breakpoints.down(breakpoints.md)]: {
        fontSize: '11px!important',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '9px!important',
      },
    },

    '.LinkText': {
      fontSize: '13px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      color: '#fff!important',
      [theme.breakpoints.down(breakpoints.xxxs)]: {
        fontSize: '7px!important',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '8px!important',
      },
    },
    '.LinkTextHover': {
      fontSize: '13px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      color: '#707070!important',
      [theme.breakpoints.down(breakpoints.md)]: {
        fontSize: '11px!important',
      },
      [theme.breakpoints.down(breakpoints.xxxs)]: {
        fontSize: '7px!important',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '8px!important',
      },
    },

    '.featureBoxTitleInfo': {
      [theme.breakpoints.down(breakpoints.ssm)]: {
        display: 'none!important',
      },
    },
    '.featureBoxLink': {
      display: 'flex!important',
      alignItems: 'center!important',
      justifyContent: 'center!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginTop: '-7px!important',
        marginBottom: '7px!important',
      },
      '& .MuiSvgIcon-root': {
        top: '0px!important',
        position: 'relative!important',
        fontSize: '13px!important',
        left: '5px!important',
        [theme.breakpoints.down(breakpoints.sm)]: {
          fontSize: '8px!important',
          top: '0px!important',
          left: '2px!important',
        },
        [theme.breakpoints.down(breakpoints.ms)]: {
          fontSize: '8px!important',
          top: '1px!important',
          left: '2px!important',
        },
        [theme.breakpoints.down(breakpoints.xs)]: {
          fontSize: '8px!important',
          top: '1px!important',
          left: '2px!important',
        },
        [theme.breakpoints.down(breakpoints.xxxs)]: {
          fontSize: '8px!important',
          top: '0px!important',
          left: '2px!important',
        },
      },
    },
    //Feature box End

    //gallery image section start
    '.imagesWarp': {
      position: 'relative',
      width: '100%',
      '& span': {
        [theme.breakpoints.down(breakpoints.ms)]: {
          width: '430px!important',
        },
        [theme.breakpoints.down(breakpoints.mss)]: {
          width: '100%!important',
        },
      },
    },
    '.image-gallery-image': {
      width: '100%!important',
      objectFit: 'contain!important',
      height: '100%!important',
    },

    '.image-gallery-slide .image-gallery-image': {
      maxHeight: 'calc(108vh - 111px)!important',
      minHeight: '200px',
      borderBottomLeftRadius: '10px!important',
      borderBottomRightRadius: '10px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        borderBottomLeftRadius: '0px!important',
        borderBottomRightRadius: '0px!important',
      },
      [theme.breakpoints.down(breakpoints.lgm)]: {
        maxHeight: 'calc(113vh - 111px)!important',
        objectFit: 'cover !important',
      },
    },

    '.submenu': {
      display: 'inline-block',
      listStyleType: 'none',
      padding: '0px !important',
      margin: '10px 0px 10px 0px !important',
      width: '100%',
      minWidth: '820px',
      '& .horizontal-menu': {
        [theme.breakpoints.down(breakpoints.sm)]: {
          width: '320px',
          marginLeft: '15px',
        },
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '320px',
        minWidth: '100%',
      },
    },
    '.submenuItem': {
      float: 'left',
      cursor: 'pointer',
      display: 'inline-block',
      padding: '0px 20px 0px 20px',
      backgroundColor: '#fafafa',
      // border: '1px solid #0056B21A',
      borderRadius: '8px',
      fontSize: '14px!important',
      fontWeight: '700!important',
      fontFamily: 'Open Sans, sans-serif !important',
      color: Variables.darkColor,
      [theme.breakpoints.down(breakpoints.sm)]: {
        padding: '0px 0px 0px 20px',
      },
    },
    '.nextButtonmenu': {
      position: 'absolute',
      right: '-12%',
      top: '10px',
      height: '32px',
      width: '32px',
      background: '#fff',
      borderRadius: '50%',
      border: '1px solid #ddd',
      color: Variables.darkColor,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      [theme.breakpoints.down(breakpoints.sm)]: {
        right: '5%',
      },
    },
    '.navbar': {
      display: 'flex',
      '& a': {
        marginBottom: '11px',
        '&:active': {
          borderBottom: '4px solid #0056b2',
        },

        listStyle: 'none',
      },
    },

    '.arrow-next': {
      position: 'absolute',
      right: '0',
      top: '5px',
      height: '32px',
      width: '32px',
      background: '#fff',
      borderRadius: '50%',
      border: '1px solid #ddd',
      color: Variables.darkColor,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    //gallery image section end

    '.mob-best-property-card': {
      width: '310px',
      minWidth: '96%',
    },

    '.drawer-paper1': {
      width: '380px',
    },
    '.property-detail-image-container': {
      [theme.breakpoints.down(breakpoints.lg)]: {
        '& img': {
          width: '1600px !important',
          height: '620px !important',
        },
      },
    },
    '.property-card': {
      padding: '16px',
      margin: '0px 10px 0 0',
      // marginLeft:'7px !important',
      '& .menu-item-wrapper figure::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: '5px',
        right: 0,
        left: 0,
        background:
          'linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.4) 21%, rgba(0,0,0,0) 37%)',
        display: 'block',
        borderRadius: '12px',
        zIndex: 1,
        cursor: 'pointer',
        [theme.breakpoints.down(breakpoints.xs)]: {
          borderRadius: '7px',
        },
      },
    },

    '.testimonialInnerCard': {
      [theme.breakpoints.down(breakpoints.ssm)]: {
        height: '135px',
      },
    },

    '.testimonial-card': {
      padding: '15px 18px!important',
      // width: '374px',
      marginLeft: '4px',
      marginRight: '4px',
      // margin: "0 10px 0 0",
      borderRadius: '15px !important',
      // height: "215px",
      '&:nth-child(4)': {
        marginLeft: '0px',
      },
      '& .PktestimonialContainer': {
        margin: '12px 0 0 12px !important',
      },

      '& .menu-item-wrapper figure::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: '5px',
        right: 0,
        left: 0,
        background:
          'linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.4) 21%, rgba(0,0,0,0) 37%)',
        display: 'block',
        borderRadius: '12px',
        zIndex: 1,
        cursor: 'pointer',
      },
      [theme.breakpoints.down(breakpoints.xs)]: {
        paddingTop: '14px',
        lineHeight: '14px',
        borderRadius: '15px !important',
        '& img': {
          // marginTop:"26px",
        },
        '& .PktestimonialContainer': {
          margin: '13px 0 0 11px !important',
        },
      },

      [theme.breakpoints.down(breakpoints.ssm)]: {
        width: '97%!important',
        // marginBottom: '20px',
        paddingTop: '14px',
        lineHeight: '14px',
        borderRadius: '15px !important',
        marginLeft: '0px',
        '& img': {
          // marginTop:"26px",
        },
      },

      [theme.breakpoints.down(breakpoints.md)]: {
        paddingTop: '14px',
        lineHeight: '14px',
        borderRadius: '15px !important',
        '& img': {
          // marginTop:"26px",
        },
      },

      [theme.breakpoints.down(breakpoints.mdl)]: {
        paddingTop: '14px',
        lineHeight: '14px',
        borderRadius: '15px !important',
        '& img': {
          // marginTop:"26px",
        },
      },

      [theme.breakpoints.down(breakpoints.lg)]: {
        width: 'auto',
      },

      [theme.breakpoints.down(breakpoints.ssml)]: {
        width: '352px',
      },

      [theme.breakpoints.up(breakpoints.xl)]: {
        width: '417px',
      },
    },

    '.mainPolicyIconImg': {
      width: '14%',
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '100%',
        textAlign: 'center',
      },
    },

    '.mainPolicyIconImg1': {
      width: '7%',
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '100%',
        textAlign: 'center',
      },
    },

    '.desc': {
      fontSize: '13px',
      lineHeight: '24px',
      paddingRight: '2px',
      // width:"343px",
      paddingLeft: '28px',
      textAlign: 'justify',
      [theme.breakpoints.down(breakpoints.sm)]: {
        lineHeight: '20px!important',
        height: '123px',
        // paddingLeft: '18px!important',
        paddingRight: '0px!important',
      },
      [theme.breakpoints.down(breakpoints.xs)]: {
        lineHeight: '20px!important',
        height: '123px',
        paddingLeft: '27px!important',
        paddingRight: '0px!important',
      },

      [theme.breakpoints.down(breakpoints.ssm)]: {
        lineHeight: '17px',
        height: '123px',
        paddingLeft: '28px',
      },

      [theme.breakpoints.down(breakpoints.xss)]: {
        lineHeight: '17px',
        height: '123px',
      },

      [theme.breakpoints.down(breakpoints.md)]: {
        lineHeight: '17px',
        height: '123px',
      },

      [theme.breakpoints.down(breakpoints.mdl)]: {
        lineHeight: '17px',
        height: '123px',
      },
    },
    // ".testimonial-card": {
    //   margin: "0 10px 0 0",
    //   height: "215px",
    //   paddingTop: "23px !important",
    //   lineHeight: "22px",
    //   [theme.breakpoints.down(breakpoints.xs)]: {
    //     lineHeight: "14px",
    //   },
    // },

    '.search-bar-width': {
      '& .MuiInputBase-input': {
        fontSize: '16px !important',
        [theme.breakpoints.down(breakpoints.sm)]: {
          fontSize: '11px !important',
        },
      },
      '& .header-search-box': {
        marginTop: '0px',
        width: '100%!important',
        position: 'absolute',
        left: '0',
        top: '30px',
        [theme.breakpoints.down(breakpoints.lg)]: {
          width: '300px!important',
        },
        [theme.breakpoints.down(breakpoints.ssmdl)]: {
          width: '300px!important',
        },
      },
      [theme.breakpoints.up(breakpoints.lg)]: {
        width: '486px',
      },
      [theme.breakpoints.down(breakpoints.lg)]: {
        width: '350px',
      },
      [theme.breakpoints.down(breakpoints.ssmdl)]: {
        width: '300px',
      },
    },

    '.header-search-box': {
      width: '600px!important',
      left: '18%',
      height: '325px',
      marginTop: '12px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        position: 'fixed !important',
        top: 40,
        left: '0',
        width: '100%',
        height: '100%',
        padding: '0 0 105px 0',
      },
      [theme.breakpoints.up(breakpoints.lg)]: {
        width: '486px',
      },
      [theme.breakpoints.down(breakpoints.lg)]: {
        width: '350px',
      },
      [theme.breakpoints.down(breakpoints.ssmdl)]: {
        width: '300px',
      },

      [theme.breakpoints.up(breakpoints.lgm)]: {
        width: '486px',
      },
      [theme.breakpoints.up(breakpoints.xxxl)]: {
        width: '486px',
      },
    },
    '.header-search-box-with-no-containt': {
      width: '486px',
      height: 'auto',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        position: 'fixed !important',
        top: 40,
        left: '0',
        width: '100%',
        height: '100%',
        padding: '0 0 105px 0',
      },
      [theme.breakpoints.up(breakpoints.lg)]: {
        width: '486px',
      },
      [theme.breakpoints.down(breakpoints.lg)]: {
        width: '350px',
      },
      [theme.breakpoints.down(breakpoints.ssmdl)]: {
        width: '300px',
      },

      [theme.breakpoints.up(breakpoints.lgm)]: {
        width: '486px',
      },
      [theme.breakpoints.up(breakpoints.xxxl)]: {
        width: '486px',
      },
    },
    '.MuiTypography-h5': {
      fontFamily: 'Open Sans, sans-serif !important',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '.MuiTypography-h1': {
      fontFamily: 'Open Sans, sans-serif !important',
    },
    '.MuiTypography-h2': {
      fontFamily: 'Open Sans, sans-serif !important',
    },
    '.MuiTypography-h3': {
      fontFamily: 'Open Sans, sans-serif !important',
    },
    '.MuiTypography-h4': {
      fontFamily: 'Open Sans, sans-serif !important',
    },
    '.MuiTypography-h6': {
      fontFamily: 'Open Sans, sans-serif !important',
    },
    '#homepage-search-popup-popper': {
      zIndex: '9999',
    },

    '#common-popper1': {
      zIndex: '9999',
      display: 'grid',
      border: '1px solid #C9DBEF',
      backgroundColor: '#FFFFFF',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
      maxHeight: '300px',
      overflowY: 'scroll',
      background: '#FAFAFA',
      borderRight: 'none',
      // borderTop: 'none',
      '&::-webkit-scrollbar': {
        width: '12px',
        background: '#FFF',
        border: '1px solid #C6D9EE',
      },
      '&::-webkit-scrollbar-thumb': {
        // width: "12px",
        background: '#F2F5FC',
        border: '1px solid #C6D9EE',
      },
      // borderTopRightRadius: '5px',
      // borderRadius: '10px',
      // boxShadow: '0px 4px 10px #0056B20F',

      '& .eLbpTm': {
        padding: '7px 10px',
        fontSize: '14px',
      },

      '& .MuiSlider-valueLabel': {
        backgroundColor: '#0C56B2',
      },
    },

    '#common-popper': {
      zIndex: '9999',
      display: 'grid',
      border: '1px solid #C9DBEF',
      backgroundColor: '#FFFFFF',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
      borderTopRightRadius: '5px',
      '& .MuiSlider-valueLabel': {
        backgroundColor: '#0C56B2',
      },
      '& .eLbpTm': {
        padding: '7px 10px',
        fontSize: '14px',
      },
      '& .MuiTabScrollButton-root.Mui-disabled': {
        display: 'none',
      },
    },

    '#common-popper-rent': {
      zIndex: '9999',
      display: 'grid',
      border: '1px solid #C9DBEF',
      backgroundColor: '#FFFFFF',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
      borderTopRightRadius: '5px',
      // borderRadius: '10px',
      // boxShadow: '0px 4px 10px #0056B20F',
      '& .eLbpTm': {
        padding: '7px 10px',
        fontSize: '14px',
      },
      '& .MuiTabScrollButton-root.Mui-disabled': {
        display: 'none',
      },

      '& .MuiSlider-valueLabel': {
        backgroundColor: '#0C56B2',
      },

      '& .makeStyles-main': {
        left: '-59% !Important',
      },
    },

    '.MuiAppBar-root': {
      zIndex: 99,
    },
    '.MuiSlider-marked': {
      marginTop: '0px !important',
    },

    '.tourChatContent': {
      height: 'auto',
      overflow: 'hidden',
      [theme.breakpoints.down(breakpoints.md)]: {
        height: '400px',
        overflow: 'scroll',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        // marginTop: "30px ",
      },
    },

    '.builderProjectCardText1': {
      display: 'flex',
      // justifyContent: "left",
      // alignItems: "center ",
      marginTop: '10px',

      [theme.breakpoints.down(breakpoints.sm)]: {
        marginTop: '10px',
      },
      [theme.breakpoints.down(breakpoints.ms)]: {
        marginTop: '30px',
      },
    },

    '.footerSection': {
      position: 'absolute',
      bottom: 0,
      right: 3,
      marginBottom: 10,
      [theme.breakpoints.down(breakpoints.xss)]: {
        position: 'relative',
      },
    },

    '.homeContent': {
      height: 'auto',
      overflow: 'hidden',
      [theme.breakpoints.down(breakpoints.md)]: {
        height: '350px',
        overflow: 'scroll',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        // marginTop: "30px",
      },
    },

    '.MuiPaper-elevation4': {
      boxShadow: 'none !important',
    },
    '.phone-image': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'none',
      },
    },

    '.blog-card-item': {
      // paddingRight: '1px',
      // marginRight: '0.04rem',
      marginTop: '11px',

      '& .mob-developers-card': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5px',
        marginBottom: '0px',
        marginLeft: '4px',
        marginRight: '8px',
        [theme.breakpoints.down(breakpoints.sm)]: {
          marginBottom: '42px !important',
        },

        '&:first-child': {
          marginLeft: '0px',
          [theme.breakpoints.down(breakpoints.ssmdl)]: {
            marginLeft: '0px',
          },
        },
        [theme.breakpoints.up(breakpoints.lg)]: {
          width: '99%!important',
        },

        [theme.breakpoints.down(breakpoints.mdss)]: {
          width: '300px !important',
        },

        [theme.breakpoints.down(breakpoints.mdl)]: {
          width: 'auto !important',
        },
        [theme.breakpoints.down(breakpoints.md)]: {
          width: '275px !important',
        },

        // [theme.breakpoints.down(breakpoints.md)]: {
        //   width:"268px !important",
        // },

        [theme.breakpoints.down(breakpoints.ssmdl)]: {
          width: '297px !important',
        },

        [theme.breakpoints.down(breakpoints.ssm)]: {
          width: '277px !important',
        },

        '& a.view-all-link': {
          color: '#FFFFFF !important',
          fontWeight: '700',
          fontSize: '14px',
          padding: '10px 0px 10px',
        },
      },
    },

    '.introductionBox': {
      height: 'auto',
      display: 'block',
      padding: '15px',
      maxHeight: '100%',
      backgroundColor: '#fff',
      borderRadius: '14px',
      border: '1px solid #0056B21A',
      [theme.breakpoints.down(breakpoints.sm)]: {
        // marginLeft: "10px",
        // marginRight: "10px",
        border: 'none',
        borderRadius: '0px',
      },
    },

    '.introductionBox1': {
      height: 'auto',
      display: 'block',
      padding: '15px',
      maxHeight: '100%',
      backgroundColor: '#fff',
      borderRadius: '14px',
      border: '1px solid #0056B21A',
      [theme.breakpoints.down(breakpoints.sm)]: {
        // marginLeft: "10px",
        // marginRight: "10px",
        border: 'none',
        borderRadius: '0px',
        marginTop: '-20px',
      },
    },

    '.introBox': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        // backgroundColor: '#FCFCFF',
      },
      '.overviewBox1': {
        marginTop: '19px',
        [theme.breakpoints.down(breakpoints.sm)]: {
          backgroundColor: '#FCFCFF',
          marginTop: '28px',
        },
      },
    },

    '.introTitle': {
      fontSize: '20px',
      fontFamily: 'Open Sans, sans-serif',
      color: Variables.darkColor,
      fontWeight: 'bold',
      marginBottom: '15px',
      paddingTop: '10px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginLeft: '15px',
        marginRight: '15px',
        fontSize: '16px',
        with: '100%',
      },
      '& h2': {
        fontSize: '20px',
        fontFamily: 'Open Sans, sans-serif!important',
        color: Variables.darkColor,
        fontWeight: '700',
        marginBottom: '20px!important',
        [theme.breakpoints.down(breakpoints.sm)]: {
          fontSize: '14px',
          fontFamily: 'Open Sans, sans-serif!important',
          color: Variables.darkColor,
          fontWeight: '700',
        },
      },
    },

    '.location-details': {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      maxWidth: '220px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '& .MuiSvgIcon-root': {
        fontSize: '15px',
        color: Variables.darkColor,
        opacity: '0.8',
      },
    },

    '.more-projects-title': {
      marginTop: '10px',
      marginBottom: '10px',
      '& h5': {
        fontSize: '14px',
        fontFamily: 'Open Sans, sans-serif!important',
        color: Variables.darkColor,
        fontWeight: '700',
        textOverflow: 'ellipsis',
        maxWidth: '200px',

        [theme.breakpoints.down(breakpoints.sm)]: {
          marginLeft: '0px',
          marginRight: '0px',
          fontSize: '14px',
          fontFamily: 'Open Sans, sans-serif!important',
          color: Variables.darkColor,
          fontWeight: '700',
        },
      },
    },

    '.introTitleMap': {
      fontSize: '20px',
      fontFamily: 'Open Sans, sans-serif',
      color: Variables.darkColor,
      fontWeight: 'bold',
      paddingTop: '10px',
      marginBottom: '20px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginRight: '15px',
        fontSize: '16px',
        with: '100%',
      },
    },
    '.introTitleoverview-calc': {
      '& h2': {
        fontSize: '20px',
        fontFamily: 'Open Sans, sans-serif!important',
        color: Variables.darkColor,
        fontWeight: '700',
        marginBottom: '2px!important',
        paddingLeft: '5px',
        [theme.breakpoints.down(breakpoints.sm)]: {
          marginLeft: '0px',
          marginRight: '0px',
          fontSize: '16px',
          fontFamily: 'Open Sans, sans-serif!important',
          color: Variables.darkColor,
          fontWeight: 'bold',
        },
      },
    },
    '.introTitleoverview': {
      '& h2': {
        fontSize: '20px',
        fontFamily: 'Open Sans, sans-serif!important',
        color: Variables.darkColor,
        fontWeight: '700',
        marginBottom: '18px!important',
        [theme.breakpoints.down(breakpoints.sm)]: {
          marginLeft: '0px',
          marginRight: '0px',
          fontSize: '16px',
          fontFamily: 'Open Sans, sans-serif!important',
          color: Variables.darkColor,
          fontWeight: '700',
        },
        '@media (max-width:  420px)': {
          padding: '0px 0px 0px',
          fontFamily: 'Open Sans, sans-serif!important',
        },
      },

      '& h3': {
        fontSize: '20px',
        fontFamily: 'Open Sans, sans-serif!important',
        color: Variables.darkColor,
        fontWeight: '700',
        marginBottom: '18px!important',
        [theme.breakpoints.down(breakpoints.sm)]: {
          marginLeft: '0px',
          marginRight: '0px',
          fontSize: '16px',
          fontFamily: 'Open Sans, sans-serif!important',
          color: Variables.darkColor,
          fontWeight: '700',
        },
        '@media (max-width:  420px)': {
          padding: '0px 0px 0px',
          fontFamily: 'Open Sans, sans-serif!important',
        },
      },

      '& h6': {
        fontSize: '14px',
        fontFamily: 'Open Sans, sans-serif!important',
        color: Variables.darkColor,
        fontWeight: '700',
        marginBottom: '18px!important',
        [theme.breakpoints.down(breakpoints.sm)]: {
          marginLeft: '0px',
          marginRight: '0px',
          fontSize: '16px',
          fontFamily: 'Open Sans, sans-serif!important',
          color: Variables.darkColor,
          fontWeight: 'bold',
        },
      },
    },

    '.introTitleQasection': {
      '& h2': {
        fontSize: '20px',
        fontFamily: 'Open Sans, sans-serif!important',
        color: Variables.darkColor,
        fontWeight: '700',
        marginBottom: '20px!important',
        marginTop: '-29px',
        [theme.breakpoints.down(breakpoints.sm)]: {
          marginLeft: '0px',
          marginRight: '0px',
          fontSize: '16px',
          fontFamily: 'Open Sans, sans-serif!important',
          color: Variables.darkColor,
          fontWeight: 'bold',
          marginTop: '-17px',
        },
      },

      '& h3': {
        fontSize: '20px',
        fontFamily: 'Open Sans, sans-serif!important',
        color: Variables.darkColor,
        fontWeight: '700',
        marginBottom: '20px!important',
        marginTop: '-29px',
        [theme.breakpoints.down(breakpoints.sm)]: {
          marginLeft: '0px',
          marginRight: '0px',
          fontSize: '16px',
          fontFamily: 'Open Sans, sans-serif!important',
          color: Variables.darkColor,
          fontWeight: 'bold',
          marginTop: '-17px',
        },
      },
    },

    '.introTitleoverview1': {
      '& h2': {
        fontSize: '20px',
        fontFamily: 'Open Sans, sans-serif!important',
        color: Variables.darkColor,
        fontWeight: '700',
        marginBottom: '20px!important',
        [theme.breakpoints.down(breakpoints.sm)]: {
          marginLeft: '0px',
          marginRight: '0px',
          fontSize: '16px',
          fontFamily: 'Open Sans, sans-serif!important',
          color: Variables.darkColor,
          fontWeight: 'bold',
        },
      },
    },

    '.introTitleRating': {
      fontSize: '20px',
      fontFamily: 'Open Sans, sans-serif',
      color: Variables.darkColor,
      fontWeight: 'bold',
      marginBottom: '15px',
      paddingTop: '10px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginLeft: '0px',
        marginRight: '0px',
        fontSize: '16px',
        with: '100%',
        fontWeight: 'bold',
      },
    },

    '.ratingFeatureBox': {
      borderRight: '1px solid #D3E0ED',
      paddingRight: '40px',
      paddingLeft: '20px',
      textAlign: 'center',
    },

    '.tabDetailsWapper': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        // width: "100%",
        // marginLeft: "15px",
        // marginRight: "15px",
      },
      '& .react-tabs': {
        position: 'relative',
      },
    },

    '.introTitle1': {
      fontSize: '20px',
      fontFamily: 'Open Sans, sans-serif',
      color: Variables.darkColor,
      fontWeight: 'bold',
      marginBottom: '15px',
      paddingTop: '10px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'none',
      },
    },

    '.distanceInput': {
      width: '52%',
      border: 'none',
      height: '21px',
      display: 'block',
      padding: '18px 10px',
      borderRadius: '0px',
      backgroundColor: '#FFFFFF',
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '42%',
      },
    },

    '.builderGroupTitle': {
      // marginTop: "10px",
      [theme.breakpoints.down(breakpoints.xxs)]: {
        marginTop: '10px',
        fontSize: '17px !important',
      },

      [theme.breakpoints.down(breakpoints.ssm)]: {
        marginTop: '10px',
        fontSize: '17px !important',
      },
    },

    '.builderGroupText': {
      fontSize: '14px',
      lineHeight: '20px',
      textAlign: 'justify',
      // marginTop: "10px",
      color: 'rgba(0, 0, 0, 0.8)',
      fontWeight: '500',
      marginTop: '5px',
      marginLeft: '-8px',

      '& h3': {
        fontSize: '24px !important',
        color: Variables.darkColor,
        fontWeight: 'bold',
        fontFamily: 'Open sans !important',
      },

      [theme.breakpoints.down(breakpoints.xxs)]: {
        marginTop: '10px',
        '& h3': {
          fontSize: '24px',
          color: Variables.darkColor,
          fontWeight: '500',
          textAlign: 'center',
        },
      },

      [theme.breakpoints.down(breakpoints.ssm)]: {
        marginTop: '10px',
        marginLeft: '0px',
        '& h3': {
          fontSize: '24px',
          color: Variables.darkColor,
          fontWeight: 'bold',
          textAlign: 'center',
        },
      },
    },

    '.builderPropertyPojectsYears': {
      // display: "flex",
      // alignItems: "center",
      // float: "right",
      textAlign: 'right',
      marginTop: '10px',
      fontSize: '16px',

      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center',
        fontWeight: '500',
      },

      '& h3': {
        lineHeight: 'unset',
        fontSize: '18px !important',
        fontWeight: '500 !important',
        textAlign: 'right',
        [theme.breakpoints.down(breakpoints.sm)]: {
          fontSize: '18px !important',
          textAlign: 'center',
        },
      },
    },

    '.horizontalArrowB': {
      paddingLeft: '15px',
      [theme.breakpoints.down(breakpoints.sxxs)]: {
        paddingLeft: '0px',
      },
    },

    '.mobbuilderShowDetails': {
      padding: '4px 15px',
      marginTop: '10px',
      marginBottom: '10px',
      [theme.breakpoints.down(breakpoints.sxxs)]: {
        marginTop: '3px',
      },
    },

    '.projectData': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '95%',
      '& h4': {
        display: 'inline-block',
        color: '#171717',
        fontSize: '18px',
        fontWeight: '700',
        fontFamily: 'Open Sans',
        //  width:"326px",
        //  whiteSpace: "nowrap",
        //  overflow:"hidden !important",
        //  textOverflow: "ellipsis",
        [theme.breakpoints.down(breakpoints.mdl)]: {
          // width:"253px",
        },
      },
      '& h5': {
        marginTop: '5px',
        color: '#6F6F6F',
        fontSize: '14px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
      },
    },

    '.introTitleProject': {
      fontSize: '18px',
      fontFamily: 'Open Sans, sans-serif',
      color: Variables.darkColor,
      fontWeight: '700',
      marginBottom: '15px',
      paddingTop: '10px',
      display: 'none',
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginLeft: '0px',
        marginRight: '0px',
        fontSize: '15px',
        with: '100%',
        display: 'block',
      },
    },

    '.cardDetails': {
      padding: '10px',

      '& p': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '322px',
        height: '100%',
        display: '-webkit - box',
        whiteSpace: 'nowrap',
        WebkitLineClamp: '4',
        WebkitBoxOrient: 'vertical',
        [theme.breakpoints.down(breakpoints.sm)]: {
          width: '180px',
        },
      },

      '& h1': {
        fontSize: '12px',
        [theme.breakpoints.down(breakpoints.sm)]: {
          fontSize: '17px',
        },
      },

      // cardImg: {
      //   "& img": {
      //     borderRadius: "20",
      //   },
      // },
    },

    '.PropertyBlogItem1': {
      // paddingRight:"15px",
      marginLeft: '0px',
      marginRight: '0px',
      marginBottom: '2px',
      '& :first-child': {
        marginLeft: '0px',
      },
      '& :last-child': {
        // marginRight:"-1px",
      },
    },

    '.PropertyBlogItem': {
      // paddingRight:"15px",
      marginLeft: '0px',
      marginRight: '0px',
      marginBottom: '0px',
      '& :first-child': {
        marginLeft: '0px',
      },
      '& :last-child': {
        // marginRight:"-1px",
      },
    },
    // '.cardContinerDetails .MuiGrid-root:last-child': {
    //   top: '10px !Important',
    // },
    '.cardDetails1': {
      // padding: '18px',
      position: 'relative',
      width: 'auto',
      height: '240px',
      marginRight: '0px',

      [theme.breakpoints.down(breakpoints.lllg)]: {
        height: '280px',
      },
      [theme.breakpoints.down(breakpoints.lg)]: {
        width: 'auto',
        marginRight: '0px',
        height: '280px',
      },
      [theme.breakpoints.down(breakpoints.md)]: {
        // height: '290px',
        paddingTop: '6px',
      },
      [theme.breakpoints.down(breakpoints.mdd)]: {
        height: '290px',
      },
      '& figure::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: '5px',
        right: 0,
        left: 0,
        background:
          'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%, rgba(0,0,0,0.2) 52%)',
        display: 'block',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        height: '48px',
        zIndex: 1,
        cursor: 'pointer',
      },

      '& p': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '322px',
        height: '2.2em',
        display: '-webkit - box',
        whiteSpace: 'nowrap',
        WebkitLineClamp: '4',
        WebkitBoxOrient: 'vertical',
        [theme.breakpoints.down(breakpoints.sm)]: {
          width: '180px',
        },
      },

      '& h1': {
        fontSize: '12px',
        fontFamily: 'Open Sans !important',
        [theme.breakpoints.down(breakpoints.sm)]: {
          fontSize: '17px',
        },
      },

      [theme.breakpoints.down(breakpoints.sm)]: {
        padding: '6px 0px 0px 0px',
        // padding: '6px',
        paddingTop: '2px',
        marginTop: '-2px',
        height: '319px',
      },

      // cardImg: {
      //   "& img": {
      //     borderRadius: "20",
      //   },
      // },
    },

    '.cardBuilder': {
      paddingLeft: '12px !important',
      paddingRight: '12px !important',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        paddingLeft: '4px !important',
        paddingRight: '0px !important',
      },
    },

    '.headerSpace': {
      [theme.breakpoints.down(breakpoints.lg)]: {
        paddingLeft: '15px !important',
        paddingRight: '15px !important',
      },
      [theme.breakpoints.down(breakpoints.ssm)]: {
        paddingLeft: '8px !important',
        paddingRight: '15px !important',
      },
    },
    '.titleBottom': {
      [theme.breakpoints.down(breakpoints.ssm)]: {
        marginBottom: '5px!important',
      },
    },

    '.cardDetailsBox': {
      top: '-98px',
      // [theme.breakpoints.down(breakpoints.mdll)]: {
      //   top: '-35px',
      // },

      // [theme.breakpoints.down(breakpoints.md)]: {
      //   top: '0px',
      // },

      // [theme.breakpoints.down(breakpoints.ssmdl)]: {
      //   top: '-35px',
      // },
      [theme.breakpoints.down('sm')]: {
        top: '0',
      },
      [theme.breakpoints.up('md')]: {
        top: '0px',
      },
      [theme.breakpoints.up(breakpoints.lg)]: {
        top: '-98px',
      },
      [theme.breakpoints.up(breakpoints.lllg)]: {
        top: '-77px',
        // top: '-64px',
      },
    },

    '.cardRentDetails': {
      top: '-66px',
      // [theme.breakpoints.down(breakpoints.mdll)]: {
      //   top: '-35px',
      // },

      // [theme.breakpoints.down(breakpoints.md)]: {
      //   top: '-35px',
      // },

      // [theme.breakpoints.down(breakpoints.ssmdl)]: {
      //   top: '-35px',
      // },

      [theme.breakpoints.down('sm')]: {
        top: '0',
      },
      [theme.breakpoints.up('md')]: {
        top: '0px',
      },
      [theme.breakpoints.up(breakpoints.mmd)]: {
        top: '-25px',
      },
      [theme.breakpoints.up(breakpoints.mds)]: {
        top: '0px',
      },

      [theme.breakpoints.up(breakpoints.lg)]: {
        top: '-25px',
      },
      [theme.breakpoints.up(breakpoints.lllg)]: {
        top: '-76px',
      },
    },

    // '.cardDetailsBox1': {
    //   top: '-98px',
    //   [theme.breakpoints.down(breakpoints.mdll)]: {
    //     top: '-35px',
    //   },

    //   [theme.breakpoints.down(breakpoints.md)]: {
    //     top: '-35px',
    //   },

    //   [theme.breakpoints.down(breakpoints.ssmdl)]: {
    //     top: '-35px',
    //   },

    //   [theme.breakpoints.down(breakpoints.ssml)]: {
    //     top: '-35px',
    //   },
    // },

    '.cardDetailsBox1': {},

    '.horizScrollPadding': {
      paddingLeft: '15px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        paddingRight: '15px',
      },
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    '.cardContinerDetails': {
      border: '1px solid #e6e6e6',
      // padding:"15px",
      borderRadius: '15px',
      // boxShadow: "rgb(0 0 0 / 8%) 0px 2px 10px",
      position: 'relative',
      height: '280px',
      width: 'auto',
      marginLeft: '5px',
      marginRight: '5px',
      background: '#FFFFFF',

      '& :first-child': {
        marginLeft: '0px',
      },

      // [theme.breakpoints.down(breakpoints.sm)]: {
      //   height:"340px",
      // },

      [theme.breakpoints.down(breakpoints.lg)]: {
        height: '260px',
        width: 'auto',
      },

      [theme.breakpoints.down(breakpoints.mdll)]: {
        height: '250px',
        width: 'auto',
      },

      [theme.breakpoints.down(breakpoints.md)]: {
        height: '286px',
        width: 'auto',
      },
      [theme.breakpoints.down(breakpoints.ssml)]: {
        height: '258px',
        width: '351px',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        height: '290px',
        width: '311px',
      },

      [theme.breakpoints.down(breakpoints.smm)]: {
        height: '321px',
        width: '402px',
      },

      [theme.breakpoints.down(breakpoints.ms)]: {
        height: '277px',
        width: '323px',
      },

      [theme.breakpoints.down(breakpoints.mss)]: {
        height: '277px',
        width: '306px',
        marginLeft: '0px',
      },

      [theme.breakpoints.down(breakpoints.xs)]: {
        height: '265px',
        width: '293px',
      },

      [theme.breakpoints.down(breakpoints.xss)]: {
        height: '276px',
        width: '276px',
      },

      [theme.breakpoints.down(breakpoints.xxsm)]: {
        height: '240px !important',
        width: '252px !important',
      },

      [theme.breakpoints.down(breakpoints.xxxs)]: {
        height: '276px',
        width: '296px',
      },

      [theme.breakpoints.down(breakpoints.xxxs)]: {
        height: '276px',
        width: '220px',
      },

      [theme.breakpoints.down(breakpoints.xxs)]: {
        height: '276px !important',
        width: '222px !important',
      },

      [theme.breakpoints.down(breakpoints.sxxs)]: {
        height: '179px !important',
        width: '222px !important',
      },

      '& h1': {
        [theme.breakpoints.down(breakpoints.xxsm)]: {
          lineHeight: '19px !important',
          padding: '13px',
        },
      },
    },

    '.mobbBlogButton': {
      marginTop: '20px',

      [theme.breakpoints.down(breakpoints.xxsm)]: {
        marginTop: '-3px',
      },
      [theme.breakpoints.down(breakpoints.xs)]: {
        marginTop: '0px',
      },
      [theme.breakpoints.down(breakpoints.xxs)]: {
        marginTop: '-12px',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginTop: '8px',
        marginLeft: '12px !Important',
      },
      '& a': {
        width: '96% !important',
        backgroundColor: '#0056B2',
        padding: '10px 25px',
        justifyContent: 'center',
        display: 'flex',
        textAlign: 'center',
        color: '#FFFFFF',
        fontWeight: 'bold',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },

    '.blogButton': {
      marginTop: '0px',

      [theme.breakpoints.down(breakpoints.mdll)]: {
        marginTop: '1px',
      },
      '& a': {
        width: '90% !important',
        backgroundColor: '#0056B2',
        padding: '10px 25px',
        justifyContent: 'center',
        display: 'flex',
        textAlign: 'center',
        color: '#FFFFFF',
        fontWeight: 'bold',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '40px',
      },
    },

    '.MuiAccordion-root:before': {
      backgroundColor: '#D3E0ED!important',
    },

    '.builder-faq': {
      height: 'auto',
      display: 'block',
      padding: '0px',
      maxHeight: '100%',
      backgroundColor: '#fff',
      borderRadius: '10px',
      border: '1px solid #D3E0ED',
      borderBottom: 'none',
      position: 'relative',
      overflow: 'hidden',
      '& .MuiAccordionSummary-content': {
        margin: '24px 0',
        [theme.breakpoints.down(breakpoints.ssm)]: {
          margin: '20px 0',
        },
        '& h3': {
          fontWeight: '700',
          paddingLeft: '15px',
          [theme.breakpoints.down(breakpoints.ssm)]: {
            paddingLeft: '0px',
            lineHeight: '1.5 !important',
          },
        },
      },

      '& .MuiAccordionSummary-root': {
        [theme.breakpoints.down(breakpoints.ssm)]: {
          padding: '0px 20px',
        },
      },

      '& .MuiAccordionDetails-root': {
        [theme.breakpoints.down(breakpoints.ssm)]: {
          padding: '8px 20px 2px !important',
        },
      },

      '& .MuiCollapse-root': {
        paddingLeft: '12px',
        marginBottom: '1px',
        [theme.breakpoints.down(breakpoints.ssm)]: {
          paddingLeft: '0px',
          marginBottom: '1px',
        },
      },
      '.MuiAccordionSummary-content.Mui-expanded': {
        margin: '10px 0',
      },
      '& .MuiAccordion-root.Mui-expanded': {
        margin: '0px 0',
      },
      '& .MuiPaper-elevation1': {
        boxShadow: 'none',
        borderBottom: '1px solid #D3E0ED',
      },
      [theme.breakpoints.down(breakpoints.ssm)]: {
        borderRadius: '5px',
        padding: '0px',
      },

      '& .MuiAccordion-rounded:first-child': {
        borderTopLeftRadius: '10px!important',
        borderTopRightRadius: '10px!important',

        [theme.breakpoints.down(breakpoints.ssm)]: {
          borderTopLeftRadius: '5px!important',
          borderTopRightRadius: '5px!important',
        },
      },

      '& .MuiAccordion-rounded:last-child': {
        borderBottomLeftRadius: '10px!important',
        borderBottomRightRadius: '10px!important',
        [theme.breakpoints.down(breakpoints.ssm)]: {
          borderBottomLeftRadius: '5px!important',
          borderBottomRightRadius: '5px!important',
        },
      },
    },

    '.streetviewBox': {
      minHeight: '100%',
      display: 'block',
      padding: '5px 17px',
      backgroundColor: '#F3F3FE',
      borderRadius: '10px',
      border: '1px solid #D3E0ED',
      position: 'relative',
      height: '127px',
      // backgroundImage: 'url(../static/images/loader.gif)',
      // backgroundRepeat: 'no-repeat',
      // backgroundPosition: 'center center',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        border: '1px solid #D3E0ED',
        borderRadius: '10px',
        padding: '8px 17px',
        height: '100%',
        minHeight: '168px',
      },
    },
    '.overviewBox-faq .MuiAccordionSummary-content': {
      margin: '20px 0 !Important',
    },

    '.overviewBox-faq': {
      height: 'auto',
      display: 'block',
      padding: '0px',
      maxHeight: '100%',
      backgroundColor: '#fff',
      borderRadius: '10px',
      border: '1px solid #D3E0ED',
      borderBottom: 'none',
      position: 'relative',

      '& .MuiAccordionSummary-content': {
        margin: '20px 0 !Important',

        '& h4': {
          [theme.breakpoints.down(breakpoints.ssm)]: {
            paddaingBottom: '0px !important',
          },
        },

        '& h3': {
          fontSize: '14px !Important',
          fontWeight: 'bold',
          fontFamily: 'Open Sans',
          marginBottom: '0px !Important',

          [theme.breakpoints.down(breakpoints.ssm)]: {
            fontSize: '14px !Important',
            marginBottom: '0px',
            lineHeight: '1.5 !important',
          },
        },
      },

      '& .MuiCollapse-root': {
        // padding: '0px 12px 2px',

        [theme.breakpoints.down(breakpoints.sm)]: {
          padding: '0px 0px',
        },
      },

      '& .MuiAccordionDetails-root': {
        '& p': {
          marginTop: '3px',
        },
      },

      '& .MuiAccordionSummary-root': {
        // padding: '0px 26px',
        [theme.breakpoints.down(breakpoints.sm)]: {
          padding: '0px 15px',
        },
      },

      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '20px 0',
      },
      '& .MuiAccordion-root.Mui-expanded': {
        margin: '0px 0',
      },
      '& .MuiPaper-elevation1': {
        boxShadow: 'none',
        borderBottom: '1px solid #D3E0ED',
      },
      [theme.breakpoints.down(breakpoints.ssm)]: {
        borderRadius: '10px',
        padding: '0px',
        marginTop: '-10px',
      },

      '& .MuiAccordion-rounded:first-child': {
        borderTopLeftRadius: '10px!important',
        borderTopRightRadius: '10px!important',
      },

      '& .MuiAccordion-rounded:last-child': {
        borderBottomLeftRadius: '10px!important',
        borderBottomRightRadius: '10px!important',
      },
    },

    '.overviewBox': {
      height: 'auto',
      display: 'block',
      padding: '20px 20px',
      maxHeight: '100%',
      backgroundColor: '#fff',
      borderRadius: '10px',
      border: '1px solid #D3E0ED',
      position: 'relative',
      color: '#171717',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        border: '1px solid #D3E0ED',
        borderRadius: '10px',
        padding: '15px!important',
        overflow: 'hidden',
      },
    },

    '.introText': {
      '& p': {
        marginTop: '5px',
        marginBottom: '15px',
      },
    },

    '.overviewBoxUpper': {
      height: 'auto',
      display: 'block',
      padding: '20px',
      maxHeight: '100%',
      backgroundColor: '#fff',
      borderRadius: '10px 10px 0px 0px',
      border: '1px solid #D3E0ED',
      position: 'relative',
      color: '#171717',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        border: '1px solid #D3E0ED',
        borderRadius: '10px 10px 0px 0px',
        padding: '15px!important',
        overflow: 'hidden',
      },
    },

    '.overviewText1': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        borderBottom: '1px solid #f1f1f1',
        width: '100%',
      },
    },

    '.amenitiesBox': {
      height: 'auto',
      display: 'block',
      padding: '15px',
      maxHeight: '100%',
      backgroundColor: '#fff',
      borderRadius: '14px',
      border: '1px solid #0056B21A',
      [theme.breakpoints.down(breakpoints.sm)]: {
        // marginLeft:"15px",
        // marginRight:"15px",
        marginTop: '-22px',
        border: 'none',
        backgroundColor: 'transparent',
      },
    },

    '.bankBox': {
      height: 'auto',
      display: 'block',
      padding: '15px',
      maxHeight: '100%',
      backgroundColor: '#fff',
      borderRadius: '14px',
      border: '1px solid #0056B21A',
      [theme.breakpoints.down(breakpoints.sm)]: {
        border: 'none',
        backgroundColor: 'transparent',
        textAlign: 'center',
        padding: '15px 7px 15px 7px',
      },
    },

    '.search-page-mobile-button-bottom': {},

    '.facilitiesBox': {
      height: 'auto',
      display: 'block',
      padding: '15px',
      maxHeight: '100%',
      backgroundColor: '#fff',
      borderRadius: '14px',
      border: '1px solid #0056B21A',
      [theme.breakpoints.down(breakpoints.sm)]: {
        // marginLeft:"15px",
        // marginRight:"15px",
        backgroundColor: 'transparent',
        borderRadius: '0px',
        border: 'none',
      },
    },
    '.writeReviewHeading': {
      display: 'flex',
      flexFlow: 'column nowrap',
      '& > span': {
        marginTop: '-10px',
      },
      [theme.breakpoints.down('xs')]: {
        //flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        paddingRight: '1rem',
        '& > span': {
          marginTop: 0,
        },
      },
    },
    '.writeReviewContainer': {
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        // marginLeft: 'auto',
        // marginRight: 'auto',
        // marginTop: '1rem',
      },
      '& button': {
        background: '#0056B2',
        color: '#FFFFFF',
        alignItems: 'center',
        padding: '10px 21px',
        borderRadius: '10px',
        fontSize: '14px',
        fontWeight: 'bold',
        width: '100%',

        [theme.breakpoints.down(breakpoints.sm)]: {
          fontWeight: 'bold',
          fontSize: '12px',
          borderRadius: '5px',
        },
      },
    },
    '.wirteReviewBtn': {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px;',
      marginLeft: '-4px',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '12px',
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '700',
      },
    },
    '.amenitiesTextIco': {
      display: 'block',
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: '12px',
      marginTop: '10px',
      justifyContent: 'center',

      '& img': {
        [theme.breakpoints.down(breakpoints.sm)]: {
          width: '40px!important',
          height: '40px!important',
        },
      },
      '& h6': {
        color: Variables.darkColor,
        fontSize: '13px!important',
        fontWeight: '700!important',
        fontFamily: 'Open Sans, sans-serif!important',
        [theme.breakpoints.down(breakpoints.sm)]: {
          fontSize: '13px!important',
          fontWeight: '400!important',
        },
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'block',
        marginBottom: '10px',
        marginTop: '10px',
      },
    },

    '.bankTextIco': {
      display: 'block',
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: '5px',
      marginTop: '5px',
      justifyContent: 'center',
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'block',
        // marginBottom: '10px',
        // marginTop: '10px',
      },
    },

    '.amenitiesWapper': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'block',
      },
    },

    '.mainTabList': {
      background: '#ffffff',
      padding: '8px 20px',
      margin: '0px 10px 0px 0px',
      border: '1px solid #D3E0ED',
      borderRadius: '10px',
      textAlign: 'center',
      color: Variables.darkColor,
      font: 'normal normal bold 14px/19px Open Sans',
      cursor: 'pointer',
      fontWeight: 'bold',
      '& img': {
        paddingRight: '5px!important',
      },

      [theme.breakpoints.down(breakpoints.sm)]: {
        '& img': {
          paddingRight: '0px!important',
          width: '16px',
        },
        padding: '8px 14px 8px 14px',
        borderRadius: '5px',
        border: '1px solid #D3E0ED',
        font: 'normal normal normal 12px/17px Open Sans',
      },
    },

    '.pricingBox': {
      height: 'auto',
      display: 'block',
      // padding: '15px',
      maxHeight: '100%',
      backgroundColor: '#F3F3FF',
      borderRadius: '10px !important',
      border: '1px solid #D3E0ED',
      [theme.breakpoints.down(breakpoints.sm)]: {
        // width: "100%",
        borderRadius: '0px',
        marginTop: '-26px',
        borderTop: '3px solid #0056B21A',
      },
    },

    '.pricingBoxWap': {
      height: 'auto',
      display: 'block',
      padding: '20px',
      maxHeight: '100%',
      backgroundColor: '#fff',
      borderRadius: '14px',
      border: '1px solid #D3E0ED',
      [theme.breakpoints.down(breakpoints.sm)]: {
        // width: "100%",
        borderRadius: '0px',
        border: 'none',
        // borderBottom: '4px solid rgba(0, 86, 178, 0.1)',
        marginTop: '3px',
        backgroundColor: 'transparent',
        padding: '0px 20px',
      },
    },

    '.allReviewSection': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        padding: '0px 20px',
      },
    },

    '.banner-heading': {
      fontSize: '58px !important',
      fontWeight: '700 !Important',
      color: '#000',
      fontFamily: 'Open Sans, sans-serif !important',
      [theme.breakpoints.down(breakpoints.mmd)]: {
        fontSize: '42px !important',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '25px !important',
      },
      [theme.breakpoints.down(breakpoints.xs)]: {
        fontSize: '28px !important',
      },
    },

    '.interestText': {
      marginLeft: '-14px',
      [theme.breakpoints.down(breakpoints.xs)]: {
        marginLeft: '-10px',
      },
    },
    '.sectionBank': {
      margin: '10px 0px 0px 0px',
      padding: '5px 15px',
      backgroundColor: '#fff',
      borderTopLeftRadius: '14px',
      borderTopRightRadius: '14px',
      borderTop: '1px solid #D3E0ED',
      borderLeft: '1px solid #D3E0ED',
      borderRight: '1px solid #D3E0ED',
      [theme.breakpoints.down(breakpoints.sm)]: {
        backgroundColor: 'transparent',
        border: 'none',
      },

      [theme.breakpoints.down(breakpoints.ssmll)]: {
        overflow: 'hidden',
      },
    },

    '.PrivateTabIndicator-colorSecondary-137': {
      backgroundColor: '#0056B2 !important',
      height: '5px !important',
    },
    '.pro-percent': {
      position: 'absolute',
      top: '65%',
      left: '55%',
      transform: 'translate(-50%, -50%)',
      zIndex: '9',
      [theme.breakpoints.down(breakpoints.sm)]: {
        top: '60%',
        left: '20%',
      },
    },

    '.proContainer': {
      // [theme.breakpoints.down(breakpoints.xs)]: {
      // marginTop: '31px !important',
      // marginBottom: '48px !important',
      // textAlign: 'center',
      // marginLeft: '26px !important',
      // },
      //unusual commit

      [theme.breakpoints.down(breakpoints.xss)]: {
        marginTop: '49px !important',
        marginBottom: '41px !important',
        textAlign: 'center',
        marginLeft: '26px !important',
        height: '200px',
      },

      [theme.breakpoints.down(breakpoints.mss)]: {
        marginTop: '57px !important',
        marginBottom: '10px !important',
        textAlign: 'center',
        marginLeft: '26px !important',
        height: '200px',
      },
      [theme.breakpoints.down(breakpoints.ms)]: {
        marginTop: '57px !important',
        marginBottom: '10px !important',
        textAlign: 'center',
        marginLeft: '26px !important',
        height: '200px',
      },

      // xss
      // [theme.breakpoints.down(breakpoints.ssmll)]: {
      //   marginTop: '57px !important',
      //   marginBottom: '10px !important',
      //   textAlign: 'center',
      //   marginLeft: '26px !important',
      //   height:'200px',
      //   }
    },

    '.sectionbankwap': {
      // marginTop: '36px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        backgroundColor: 'transparent',
        marginLeft: '15px',
        marginRight: '15px',
        marginTop: '10px',
        // borderBottom: '4px solid rgba(0, 86, 178, 0.1)',
        // borderTop: '4px solid rgba(0, 86, 178, 0.1)',
      },
    },

    '.sectionMapwap': {
      // marginTop: '27px',
      marginBottom: '20px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        backgroundColor: 'transparent',
        marginLeft: '20px',
        marginRight: '20px',
        // borderBottom: '4px solid rgba(0, 86, 178, 0.1)',
        // borderTop: '4px solid rgba(0, 86, 178, 0.1)',
      },
    },

    '.mapSection': {
      position: 'relative',
      height: '400px',
      width: '100%',
      borderRadius: '10px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        height: '300px',
        zIndex: '0px',
        marginTop: '13px',
      },
    },
    '.sectionwap': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        backgroundColor: 'transparent',
        borderBottom: '4px solid rgba(0, 86, 178, 0.1)',
        borderTop: '4px solid rgba(0, 86, 178, 0.1)',
      },
    },

    '.builderGroupContainer': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        border: '1px solid #D3E0ED',
        padding: '15px',
        borderRadius: '10px',
        backgroundColor: '#FFFFFF',
      },
    },

    '.floorPlanImgBox': {
      borderRadius: '10px',
      opacity: '0.6!important',
      border: '1px solid #ddd',
      padding: '10px',
      marginBottom: '10px',

      [theme.breakpoints.down(breakpoints.sm)]: {
        borderRadius: '2px',
      },
    },

    '.accordion-calc': {
      border: 'none!important',
      backgroundColor: '#fafafa!important',
      boxShadow: 'none!important',
      // borderRadius: '10px !important',
      margin: '0px 0!important',
      '& .MuiAccordionDetails-root': {
        padding: '0px!important',
        [theme.breakpoints.down(breakpoints.sm)]: {
          display: 'block!important',
        },
      },
      '& .MuiAccordionSummary-root': {
        margin: '0px 0!important',
        padding: '0px 0px!important',
        height: '100%!important',
        minHeight: '20px!important',
      },
      '& last-child': {
        borderBottom: 'none!important',
      },
      '&:before': {
        backgroundColor: 'transparent!important',
      },

      [theme.breakpoints.down(breakpoints.sm)]: {
        borderRadius: '0px !important',
      },
    },

    '.accordion': {
      border: '1px solid #0056B21A',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 4px 10px #0056B20F',
      borderRadius: '10px !important',
      margin: '12px 0',
      '& last-child': {
        borderBottom: 'none',
      },
      '&:before': {
        backgroundColor: 'transparent',
      },

      [theme.breakpoints.down(breakpoints.sm)]: {
        borderRadius: '0px !important',
        borderBottom: '1px solid #0056B21A',
        border: 'none',
      },
    },

    '.renting-banner-heading': {
      fontSize: '48px !important',
      fontWeight: '700 !Important',
      color: Variables.darkColor,
      fontFamily: 'Open Sans, sans-serif !important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '30px !important',
      },
      [theme.breakpoints.down(breakpoints.xs)]: {
        fontSize: '24px !important',
      },
    },
    '.previco-rent-policy': {
      position: 'relative',
      right: '1.5%',
      bottom: '47%',
      zIndex: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fff',
      border: '1px solid #ddd',
      borderRadius: '50%',
      height: '35px',
      width: '35px',
      cursor: 'pointer',
      '& img': {
        marginTop: '3px!important',
      },

      [theme.breakpoints.down(breakpoints.sm)]: {
        right: '2.5%',
      },
    },
    '.nextico-rent-policy': {
      position: 'relative',
      left: '97.5%',
      bottom: '35%',
      zIndex: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fff',
      border: '1px solid #ddd',
      borderRadius: '50%',
      height: '35px',
      width: '35px',
      cursor: 'pointer',
      '& img': {
        marginTop: '3px!important',
      },

      [theme.breakpoints.down(breakpoints.sm)]: {
        left: '90.5%',
      },
    },

    '.nextico-rent-policy1': {
      position: 'relative',
      left: '97.5%',
      bottom: '44%',
      zIndex: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fff',
      border: '1px solid #ddd',
      borderRadius: '50%',
      height: '35px',
      width: '35px',
      cursor: 'pointer',
      '& img': {
        marginTop: '3px!important',
      },

      [theme.breakpoints.down(breakpoints.sm)]: {
        left: '90.5%',
      },
    },

    '.renting-banner-text1': {
      fontSize: '16px !important',
      fontWeight: '400 !important',
      color: '#171717!important',
      fontFamily: 'Open Sans',
      opacity: '0.8!important',
      marginLeft: '10px!important',
      marginTop: '-5px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '13px !important',
        marginLeft: '10px!important',
      },
    },

    '.renting-banner-text': {
      fontSize: '18px !important',
      fontWeight: '500 !important',
      color: '#171717!important',
      fontFamily: 'Open Sans, sans-serif !important',
      opacity: '0.8!important',
      marginLeft: '10px!important',
      marginTop: '-5px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '13px !important',
        marginLeft: '10px!important',
      },
    },

    '.bannerPoints': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '15px',
    },

    '.bannerPointsNew': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '15px',

      [theme.breakpoints.down(breakpoints.sm)]: {
        justifyContent: 'center',
        marginBottom: '15px',
      },
    },

    '.renting-banner-btn': {
      position: 'relative',
      zIndex: '1',
      marginBottom: '30px',
      marginTop: '30px',
      backgroundColor: '#0056b2',
      width: '200px',
      textAlign: 'center',
      padding: '10px',
      borderRadius: '10px',

      '& a': {
        color: '#fff',
        fontWeight: '700',
      },
    },

    '.renting-banner-btn1': {
      position: 'relative',
      zIndex: '1',
      marginBottom: '30px',
      marginTop: '30px',
      width: '300px',
      textAlign: 'left',
      padding: '5px 0px',
      borderRadius: '10px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
      },

      '& a': {
        color: '#fff',
        fontWeight: '700',
      },
    },

    '.list-your-btn': {
      background: '#0054a6',
      borderRadius: '12px',
      color: '#fff',
      padding: '12px 36px',
      fontSize: '13px',
      width: '100%',
      '&:hover': {
        background: '#094d8f',
      },
    },

    '.getDistanceButton': {
      backgroundColor: '#0056B2',
      color: '#FFFFFF',
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
      height: 'auto',
      padding: '9px 15px',
      boxShadow: 'none',
      fontSize: '12px',
      // height: "100%",
      borderBottomLeftRadius: 0,
      fontWeight: 'bold',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: Variables.darkColor,
        color: '#FFFFFF',
      },

      [theme.breakpoints.down(breakpoints.ms)]: {
        padding: '9px 25px',
      },
      [theme.breakpoints.down(breakpoints.xs)]: {
        padding: '9px 15px',
      },

      [theme.breakpoints.down(breakpoints.xxxs)]: {
        padding: '9px 10px',
      },
    },

    '.maptextInput': {
      width: '60%',
      display: 'block',
      borderRadius: '0px',
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
      height: '44px',
      backgroundColor: '#FFFFFF',
      //border: 'none',
      padding: 10,
      border: '1px solid #D3E0ED',

      [theme.breakpoints.down(breakpoints.xs)]: {
        width: '60%',
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
      },
    },

    '.formSubmit1': {
      marginRight: '5%',
      width: '26%',
      display: 'block',

      [theme.breakpoints.down(breakpoints.ssmdl)]: {
        marginRight: '3% !Important',
      },

      [theme.breakpoints.down(breakpoints.xxs)]: {
        fontSize: '12px !Important',
      },
      [theme.breakpoints.down(breakpoints.ms)]: {
        marginRight: '5% !Important',
      },
      [theme.breakpoints.down(breakpoints.mss)]: {
        marginRight: '7% !Important',
      },

      [theme.breakpoints.down(breakpoints.xs)]: {
        marginRight: '1% !Important',
      },
      [theme.breakpoints.down(breakpoints.xxxs)]: {
        marginRight: '4% !Important',
      },
      [theme.breakpoints.down(breakpoints.xxsm)]: {
        marginRight: '9% !Important',
      },
      '& .MuiButtonBase-root': {
        height: '42px',
        fontSize: '14px',
        textTransform: 'capitalize',
        boxShadow: 'none',
        borderRadius: '0',
        backgroundColor: '#0056B2',
        color: '#FFFFFF',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
        padding: '22px',
        lineHeight: '17px',
        [theme.breakpoints.down(breakpoints.xs)]: {
          padding: '14px',
        },
        [theme.breakpoints.down(breakpoints.sm)]: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
    },

    '.mobileBannerLeft': {
      position: 'absolute',
      // top: "40px",
      left: '10px',
      width: '100%',
      zIndex: 2,
      [theme.breakpoints.down(breakpoints.sm)]: {
        left: '0px',
        padding: '0 10px 10px 10px',
        textAlign: 'center',
      },
    },

    '.pricechartBox': {
      height: 'auto',
      display: 'block',
      padding: '25px',
      maxHeight: '100%',
      backgroundColor: '#fff',
      borderRadius: '10px',
      border: '1px solid #D3E0ED',
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '90% !Important',
        marginLeft: '20px !Important',
        marginRight: '20px !Important',
        textAlign: 'center',
        // marginBottom: '30px',
        '& svg': {
          width: '300px',
        },
      },

      [theme.breakpoints.down(breakpoints.ssm)]: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        '& svg': {
          width: '100%',
        },
      },
    },

    '.introTitle2': {
      fontSize: '18px',
      fontFamily: 'Open Sans, sans-serif',
      color: Variables.darkColor,
      fontWeight: '700',
      marginBottom: '0px !Important',
      paddingTop: '0px !important',
      display: 'none',
      [theme.breakpoints.down(breakpoints.sm)]: {
        // marginLeft: "22px",
        // marginRight: "22px",
        fontSize: '16px',
        with: '100%',
        display: 'block',
        marginBottom: '20px !important',
        // paddingTop: '10px !important',
        fontWeight: 'bold',
      },
    },

    '.propertyProgresscalcBox': {
      height: 'auto',
      display: 'block',
      maxHeight: '100%',
      backgroundColor: '#fff',
      borderRadius: '14px',
      border: '1px solid #0056B21A',
      padding: '20px',
      fontSize: '13px',
      fontWeight: '700',
      [theme.breakpoints.down(breakpoints.sm)]: {
        border: 'none',
        borderRadius: '0px',
      },
    },

    '.tourVideo': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        paddingLeft: '20px',
        paddingRight: '20px',
      },
    },

    '.titleAD': {
      color: '#0056B2',
      fontSize: '30px',
      fontWeight: '700',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '18px',
      },
    },

    '.ratingFeatureBox1': {
      textAlign: 'center',
      // marginRight: "10px",
      borderRight: '1px solid #D3E0ED',
      paddingRight: '9px',
      paddingLeft: '9px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        paddingRight: '4px',
        paddingLeft: '4px',
      },
    },

    '.ratingFeatureBox:last-child': {
      borderRight: 'none !important',
    },

    '.rightBorder': {
      paddingRight: '30px',
      textAlign: 'center',
      lineHeight: '24px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        borderRight: 'none',
        textAlign: 'center',
        width: '100%',
        paddingRight: '0px !important',
      },
    },

    '.titleRatingMob': {
      display: 'none',
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'block',
      },
    },

    '.sectionBgReview': {
      margin: '20px 0px',
      backgroundColor: '#fff',
      borderRadius: '14px',
      border: '1px solid #D3E0ED',
      [theme.breakpoints.down(breakpoints.sm)]: {
        borderRadius: '10px',
        border: '1px solid #D3E0ED',
        marginLeft: '20px !important',
        marginRight: '20px  !important',
        marginTop: '20px',
        // borderBottom: "4px solid rgba(0, 86, 178, 0.1)",
      },
    },

    '.titleRatingDesktop': {
      display: 'block',
      width: '100%',
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'block',
        width: '100%',
        paddingLeft: '20px',
        // paddingTop: '30px',

        '& p': {
          marginTop: '7px',
          // marginLeft: "-20px",
        },
      },
    },

    '.AlltitleRatingDesktop': {
      display: 'block',
      width: '100%',
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'block',
        width: '100%',
        paddingLeft: '3px',
        // marginTop: '-37px',
        paddingBottom: '0px',

        '& p': {
          marginTop: '7px',
          // marginLeft: "-20px",
        },
      },
    },

    '.ratingReview': {
      marginTop: '-2px',
    },

    '.faqTab': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        background: '#fafafa',
        // borderBottom: '4px solid rgba(0, 86, 178, 0.1)',
        paddingTop: '0px',
        marginTop: '0px',
      },
    },

    '.whyContainerText': {
      textAlign: 'left',
      display: 'block',
      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center !important',
        display: 'block!important',
      },
    },

    '.whyContainerText1': {
      textAlign: 'left',
      display: 'block',
      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center !important',
        display: 'block',
        paddingLeft: '15px',
        paddingRight: '15px',
      },
    },
    '.privacyheaderText': {
      textAlign: 'left',
      paddingLeft: '10px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'left',
        // display: 'block',
        justifyContent: 'left',
        paddingLeft: '25px',
        paddingRight: '25px',
      },
    },
    '.blueBgcontainer': {
      background: '#F2F5FC',
      padding: '10px',
      textAlign: 'left',

      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center',
      },
    },

    '.rentingBtn': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center',
      },
    },
    //support form start -----------------------------------------------
    '.solution-banner-img': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'none',
      },
    },

    '.contact-banner1': {
      marginLeft: '20px !important',
      height: '100%',
      maxHeight: '530px',
      overflowX: 'hidden',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '12px',
        background: '#FFF',
      },
      '&::-webkit-scrollbar-thumb': {
        // width: "12px",
        background: '#F2F5FC',
      },
      [theme.breakpoints.down(breakpoints.md)]: {
        marginLeft: '0px !important',
        float: 'left !important',
        width: '100% !important',
        maxHeight: '100%!important',
        overflowY: 'hidden',
      },
    },

    '.sign-up-box': {
      backgroundColor: '#fff',
      borderRadius: '20px',
      boxShadow: 'rgb(0 86 178 / 8%) 0px 8px 24px',
      padding: '35px 20px',
      border: '1px solid #2E37BB26',
      height: '100%',
    },

    '.support-form-main': {
      backgroundColor: '#fff',
      borderRadius: '20px',
      boxShadow: 'rgb(0 86 178 / 8%) 0px 8px 24px',
      padding: '30px',
      border: '1px solid #2E37BB26',
      height: '100%',
    },

    '.support-form-main1': {
      height: '100%',
      marginRight: '40px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginRight: '0px',
      },
    },
    '.support-data': {
      paddingLeft: '40px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        paddingLeft: '0px',
      },
    },

    '.support-captcha': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    '.support-captcha-img': {
      width: '22%',
      marginTop: '5px',
      marginBottom: '-5px',
    },
    '.support-captcha-refresh-btn': {
      width: '24%',
      margin: '2px 10px',
      '& img': {
        marginTop: '3px!important',
      },
    },
    '.support-captcha-textinput': {
      width: '54%',
    },
    '.support-form-text-main': {
      display: 'flex',
      width: '100%',
      marginBottom: '10px',
      marginLeft: '5px',
    },
    '.support-form-text-img': {
      width: '16%',
    },
    '.support-form-text': {
      display: 'flex',
      width: '84%',
    },
    '.formSupportSubmit': {
      width: '150px!important',
      backgroundColor: '#0056B2!important',
      color: '#fff!important',
      textTransform: 'capitalize!important',
      // transition: 'all 0.3s ease-in-out!important',
      borderRadius: '12px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      '&:hover': {
        boxShadow: 'none!important',
        backgroundColor: '#074587!important',
        color: '#FFFFFF!important',
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '700!important',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '100%!important',
        marginTop: '20px',
      },
    },

    '.formSupportSubmit1': {
      width: '100%!important',
      backgroundColor: '#0056B2!important',
      color: '#fff!important',
      textTransform: 'capitalize!important',
      // transition: 'all 0.3s ease-in-out!important',
      borderRadius: '12px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      '& span.MuiButton-label': {
        paddingTop: '6px',
        paddingBottom: '6px',
      },
      '&:hover': {
        boxShadow: 'none!important',
        backgroundColor: '#074587!important',
        color: '#FFFFFF!important',
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '700!important',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '100%!important',
        marginTop: '20px',
      },
    },

    '.formSignupSubmit': {
      width: '250px!important',
      backgroundColor: '#0056B2!important',
      color: '#fff!important',
      textTransform: 'capitalize!important',
      // transition: 'all 0.3s ease-in-out!important',
      borderRadius: '12px!important',
      padding: '6px!important',
      '&:hover': {
        boxShadow: 'none!important',
        backgroundColor: '#074587!important',
        color: '#FFFFFF!important',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '100%!important',
        marginTop: '10px',
      },
    },
    //support form end -----------------------------------------------------------

    //contact us start -----------------------------------------------------------
    '.contactus-main': {
      backgroundColor: '#0056B20A',
      borderRadius: '10px',
      padding: '15px 10px',
      // border: "1px solid #2E37BB26",
      textAlign: 'center',
      marginBottom: '20px',
      display: 'flex',
      alignItems: 'center',
    },
    '.contactus-main-box': {
      backgroundColor: '#fff',
      borderRadius: '10px',
      padding: '15px 10px',
      textAlign: 'center',
    },
    '.contactus-main-new-box': {
      backgroundColor: '#fff',
      borderRadius: '10px',
      padding: '5px 10px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
    },
    '.contactus-info-main': {
      backgroundColor: '#0056B20A',
      borderRadius: '10px',
      padding: '10px',
      textAlign: 'center',
      width: '450px',
      margin: '0px auto',
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '100%!important',
      },
    },
    '.contactcustomersImgMain': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#0056B20F',
      width: '95px',
      height: '95px',
      borderRadius: '50%',
    },

    '.btm-hr': {
      border: '1px  solid #0056b2',
      width: '165px',
      margin: '0px auto',
      marginTop: '20px',
    },
    //contact  us end -----------------------------------------------------------

    //about us -----------------------------------------------------------
    '.aboutus-main': {
      backgroundColor: '#0056B20A',
      borderRadius: '10px',
      padding: '15px 10px',
      // border: "1px solid #2E37BB26",
      textAlign: 'center',
      marginBottom: '20px',
      display: 'flex',
      alignItems: 'center',
    },
    '.aboutus-main-img': {
      backgroundColor: '#DBE4F0',
      height: '80px',
      width: '95px',
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '50%',
      margin: '0px',
    },
    '.aboutus-main-text': {
      marginLeft: '15px',
    },
    //about us end -----------------------------------------------------------

    //overview section statrt
    '.overview-section': {
      marginBottom: '30px',
      marginTop: '40px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        paddingLeft: '20px',
        paddingRight: '20px',
        width: '100%',
        marginTop: '30px',
      },
    },

    '.overview-section-ami': {
      marginBottom: '30px',
      marginTop: '40px !Important',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        paddingLeft: '20px',
        paddingRight: '20px',
        width: '100%',
        marginTop: '30px !Important',
      },
    },

    '.overview-section1': {
      // marginBottom: '30px',
      // marginTop: '40px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        paddingLeft: '20px',
        paddingRight: '20px',
        width: '100%',
        marginBottom: '0px',
        marginTop: '30px',
      },
    },

    '.pdp-section1': {
      marginTop: '40px',
      paddingTop: '20px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        width: '100%',
        marginTop: '15px',
        paddingLeft: '20px',
        paddingRight: '20px',
      },
    },

    '.pdp-section': {
      marginTop: '40px',
      paddingTop: '20px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        width: '100%',
        marginTop: '30px',
        paddingLeft: '20px',
        paddingRight: '20px',
      },
    },

    '.pdpMr': {
      [theme.breakpoints.down(breakpoints.ssm)]: {
        paddaingBottom: '0px',
      },
    },

    '.pdp-data': {
      // marginBottom: '30px',
      // marginTop: '40px !Important',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        paddingLeft: '20px',
        paddingRight: '20px',
        width: '100%',
        marginTop: '30px !Important',
      },
    },

    '.mobpdp-section': {
      // marginBottom: '30px',
      // marginTop: '40px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        width: '100%',
        marginBottom: '0px',
        marginTop: '40px !important',
      },
    },

    // '.overview-section1': {
    //   marginBottom: '30px',
    //   marginTop: '30px',
    //   [theme.breakpoints.down(breakpoints.ssm)]: {
    //     paddingLeft: '20px',
    //     paddingRight: '20px',
    //     width: '100%',
    //     marginBottom: '0px',
    //   },
    // },

    '.overview-section-calc': {
      marginBottom: '0px!important',
      marginTop: '0px!important',
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '0px 0!important',
      },
      [theme.breakpoints.down(breakpoints.ssm)]: {
        paddingLeft: '20px!important',
        paddingRight: '20px!important',
        width: '100%!important',
        marginBottom: '16px!important',
      },

      '& .MuiAccordion-rounded': {
        border: '1px solid #D3E0ED !important',
        borderRadius: '10px!important',
      },
      '& .MuiAccordion-root.Mui-expanded': {
        border: '0px!important',
        background: '#fafafa!important',
        boxShadow: 'none!important',
      },
      '& .MuiAccordionSummary-root.Mui-expanded': {
        padding: '0px!important',
        [theme.breakpoints.down(breakpoints.ssm)]: {
          minHeight: '52px',
        },
      },
      '& .MuiAccordionDetails-root': {
        padding: '0px!important',
        display: 'contents',

        [theme.breakpoints.down(breakpoints.ssm)]: {
          display: 'block!important',
        },
      },
      '& .MuiPaper-elevation1': {
        boxShadow: 'none!important',
      },
    },
    '.pd-calc': {
      padding: '15px 20px!important',
    },
    '.overview-section-calc-emi': {
      marginBottom: '0px!important',
      marginTop: '0px!important',
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '0px 0!important',
      },

      [theme.breakpoints.down(breakpoints.ssm)]: {
        paddingLeft: '20px!important',
        paddingRight: '20px!important',
        width: '100%!important',
        marginBottom: '-10px!important',
      },
      '& .MuiAccordion-rounded': {
        border: '1px solid #D3E0ED !important',
        borderRadius: '10px!important',
      },
      '& .MuiAccordion-root.Mui-expanded': {
        border: '0px!important',
        background: '#fafafa!important',
        boxShadow: 'none!important',

        [theme.breakpoints.down(breakpoints.ssm)]: {
          marginTop: '15px',
        },
      },
      '& .MuiAccordionSummary-root.Mui-expanded': {
        padding: '0px!important',
        [theme.breakpoints.down(breakpoints.ssm)]: {
          minHeight: '52px',
        },
      },
      '& .MuiAccordionDetails-root': {
        padding: '0px!important',
        marginBottom: '20px',
      },
      '& .MuiPaper-elevation1': {
        boxShadow: 'none!important',
        marginBottom: '0px',
      },
    },
    '.overview-border': {
      borderRight: '1px solid #D3E0ED',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        border: 'none',
      },
      [theme.breakpoints.down(breakpoints.mdl)]: {
        border: 'none',
      },
    },
    '.overview-padding': {
      paddingLeft: '20px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        padding: '0px',
      },
      [theme.breakpoints.down(breakpoints.mdl)]: {
        padding: '0px',
      },
    },
    //overview section end

    //homeloanemi start -----------------------------------------------
    '.monthpaymentmaintitle': {
      padding: '10px',
      borderBottom: '1px solid #0056B21A',
      background: '#FCFCFF',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
    },

    '.monthpaymentmaincontent': {
      padding: '15px',
    },

    '.Averagetext14': {
      fontSize: '20px',
      fontWeight: 'bold',
      // paddingTop: "3px",
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '14px',
        fontWeight: 'bold',
      },
    },

    '.reviewTotal': {
      fontSize: '14px',
      fontWeight: 'bold',
      border: '1px solid #D3E0ED',
      background: '#FFFFFF',
      borderRadius: '10px',
      padding: '9px 0px',
      height: '40px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        borderRadius: '5px',
        fontSize: '12px',
      },
    },

    '.rateTitle16': {
      fontSize: '20px',
      fontWeight: '700',
      color: Variables.darkColor,
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '14px',
      },
    },

    '.monthpaymentmainbox': {
      border: '1px solid #0056B21A',
      cursor: 'pointer',
      height: 'auto',
      margin: '0 10px 0px 0',
      position: 'relative',
      background: '#FFF',
      textAlign: 'center',
      borderRadius: '10px',
    },
    '.height-month': {
      height: '280px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        height: '380px',
      },
    },
    '.firsttimerenting': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    '.emipayments': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      background: '#f5f8fc',
      borderRadius: '10px',
      padding: '8px',
      marginBottom: '20px',

      '& .MuiInputBase-root-MuiOutlinedInput-root': {
        padding: '12.5px 11px!important',
        height: '48px!important',
      },

      '& .MuiIconButton-edgeEnd': {
        border: 'none!important',
        marginRight: '-13px!important',
        background: '#ddd!important',
        borderRadius: '0%!important',
        borderBottomRightRadius: '4px!important',
        borderTopRightRadius: '4px!important',
      },
    },
    '.firsttimerentingtext': {
      width: '80%',
    },
    '.emipaymentstext': {
      width: '40%',
    },
    '.firsttimerentingamount': {
      width: '20%',
      border: '2px solid #e9ecef',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '60%!important',
      },
    },

    // '.renthow':{
    //   '&.MuiGrid-root':{
    //     '&.brokeragecustomersReviewCard':{
    //       [theme.breakpoints.down(breakpoints.mds)]: {
    //       width:'225px',
    //       },
    //     },

    //   },

    // },

    '.scheduleamount': {
      width: '100%',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '100%!important',
      },
      '& .MuiInputBase-input-MuiOutlinedInput-input': {
        padding: '12.5px 10px!important',
      },
    },

    '.firsttimerentingamounttext': {
      width: '40%',
      '& .MuiInputBase-root-MuiInput-root:before': {
        border: 'none!important',
      },
      '& .MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before': {
        border: 'none!important',
      },
    },

    '.firsttimerentingamounticon': {
      width: '10%',
      backgroundColor: '#e9ecef',
      padding: '6px',
      textAlign: 'center',
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '30%!important',
      },
    },
    '.mortgagecalculator': {
      textAlign: 'center',
      borderBottom: '2px dotted #ddd',
    },
    '.piechart': {
      padding: '5px',
      borderRadius: '10px',
      marginTop: '12%',
      [theme.breakpoints.down(breakpoints.sm)]: {
        padding: '5px!important',
        margin: '5px!important',
      },
    },
    '.piechartlable': {
      padding: '5px',
      display: 'flex',
      marginTop: '10px!important',
      marginBottom: '10px!important',
      alignItems: 'center',
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '260px!important',
      },
    },

    '.pievalue': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '-50px',
    },

    '.piechart-dot-amount': {
      backgroundColor: '#EE6A2A',
      textAlign: 'center',
      color: '#fff!important',
      width: '15px',
      height: '15px',
      marginRight: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: '0px',
      borderRadius: '4px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginRight: '3px',
      },
    },
    '.piechart-dot-total-interest': {
      backgroundColor: '#187EEB',
      textAlign: 'center',
      color: '#fff!important',
      width: '15px',
      height: '15px',
      marginRight: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
    },
    '.tableheading': {
      textAlign: 'center!important',
    },

    '.bannerTitleEMI': {
      color: '#171717!important',
      fontSize: '32px!important',
      fontFamily: 'Open Sans, sans-serif!important',
      fontWeight: '700!important',
      marginBottom: '5px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '24px!important',
        textAlign: 'center!important',
        lineHeight: '32px!important',
      },
    },
    '.subTitleEmi': {
      color: '#171717!important',
      fontSize: '30px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      opacity: '0.9!important',
      marginBottom: '10px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '24px!important',
        textAlign: 'center!important',
        lineHeight: '30px!important',
        fontWeight: '700!important',
      },
    },

    '.pieTitle': {
      fontSize: '12px!important',
      color: '#171717!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '10px!important',
        fontWeight: '700!important',
      },
    },

    '.subTitleEmiText': {
      color: '#171717!important',
      fontSize: '14px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '500!important',
      marginBottom: '40px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '14px!important',
        textAlign: 'center!important',
        lineHeight: '24px!important',
      },
    },

    '.scheduleText': {
      color: Variables.darkColor,
      fontSize: '16px',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700',
      opacity: '0.8',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '11px!important',
      },
    },

    '.mainLoanEMIBox': {
      height: '100%',
      width: '93%',
      backgroundColor: '#fff',
      borderRadius: '6px',
      border: '1px solid #0056B224',
      display: 'flex',
      marginLeft: '7%',
      [theme.breakpoints.down(breakpoints.md)]: {
        width: '100%',
        marginLeft: '0%',
      },
      [theme.breakpoints.down(breakpoints.lg)]: {
        width: '100%',
        marginLeft: '0%',
      },
    },
    '.mainLoanEMIInnerBox': {
      backgroundColor: '#0056B224',
      height: '100%',
      // minidth: '100%',
      borderTopLeftRadius: '6px',
      borderBottomLeftRadius: '6px',
      width: '48%',
      display: 'flex',
      justifyContent: 'center',
    },
    '.mainLoanEMIMainBox': {
      padding: '10px 15px 15px 15px',
    },
    '.mainLoanEMIMain': {
      width: '52%',
      '& .MuiDivider-root': {
        backgroundColor: 'rgb(208 220 233)!important',
        height: '0.4px!important',
      },
    },

    '.homefaq': {
      '& .makeStyles-heading': {
        fontSize: '11px!important',
      },
    },

    '.homeloanfaq': {
      // [theme.breakpoints.down(breakpoints.md)]: {
      //   marginLeft: '-20px',
      //   marginRight: '-20px',
      // },
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginTop: '11px',
        // marginLeft: '-20px',
        // marginRight: '-20px',
      },
      '& .makeStyles-heading': {
        fontSize: '11px!important',
      },
      '& section': {
        [theme.breakpoints.down(breakpoints.sm)]: {
          marginLeft: '0px!important',
          marginRight: '0px!important',
          width: 'auto',
        },
      },
    },

    '.deskviewEMI': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'none!important',
      },
    },
    '.mobviewEMI': {
      [theme.breakpoints.up(breakpoints.sm)]: {
        display: 'none!important',
      },
    },

    '.mobEMIBox': {
      border: '1px solid #DEDEFFA1',
      padding: '15px',
      marginBottom: '20px',
      borderRadius: '14px',
      boxShadow: '0px 4px 10px #0056b215',
    },

    '.formWarpperScroll': {},
    '.mobEMIBoxValues': {
      border: '1px solid #DEDEFFA1',
      padding: '8px',
      marginBottom: '20px',
      borderRadius: '14px',
      boxShadow: '0px 4px 10px #0056b215',
      width: '100%',
    },
    '.mobEMIBoxValuesMain': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    '.mobEMIBoxAmount': {
      width: '50%',
      border: '1px solid #DEDEFFA1',
      padding: '8px',
      marginBottom: '20px',
      borderRadius: '14px',
      boxShadow: '0px 4px 10px #0056b215',
      margin: '5px',
    },
    '.mobEMIBoxinterest': {
      width: '50%',
      border: '1px solid #DEDEFFA1',
      padding: '8px',
      marginBottom: '20px',
      borderRadius: '14px',
      boxShadow: '0px 4px 10px #0056b215',
      margin: '5px',
    },
    '.pichartsize': {
      width: '150px',
      height: '150px',
      margin: '15px auto',
      position: 'relative',
      top: '24%',
      right: '12%',
      [theme.breakpoints.down(breakpoints.md)]: {
        width: '110px',
        height: '110px',
      },
    },

    '.rupeeemi': {
      position: 'relative',
      left: '19%',
      fontSize: '19px!important',
      marginTop: '2px',
    },

    '.rupeeemimob': {
      position: 'relative',
      left: '-11%',
      fontSize: '19px!important',
      marginTop: '2px',
    },

    '.percentage': {
      position: 'relative',
      left: '1%',
      fontSize: '18px!important',
      marginTop: '3px',
    },

    '.percentagemob': {
      position: 'relative',
      left: '1%',
      fontSize: '18px!important',
      marginTop: '3px',
    },

    //homeloanemi end -----------------------------------------------

    //homeworth start -----------------------------------------------
    '.homeworth-main': {
      backgroundColor: '#0056B20A',
      borderRadius: '10px',
      padding: '15px',
      // border: "1px solid #2E37BB26",
      textAlign: 'center',
    },
    '.homeworth-banner-img': {
      textAlign: 'left',
      marginLeft: '-47px',
    },
    '.homeworth-banner-img-main': {
      marginLeft: '30px!important',
    },
    '.corevalue': {
      marginBottom: '50px',
    },
    '.corevalue-section': {
      background: '#F5F8FC',
      padding: '10px',
    },
    //homeworth end -----------------------------------------------
    //emi property section start
    '.topMain': {
      marginTop: '45px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginTop: '0px',
      },
    },
    //emi property section end

    //progress bar start
    '.progressvertical': {
      width: '170px',
      height: '134px',
      borderRadius: '3px',
      position: 'absolute',
      top: '54%',
      left: '75%',
      transform: 'translate(-50%, -50%)',
      [theme.breakpoints.up(breakpoints.xl)]: {
        top: '58%',
      },
      [theme.breakpoints.down(breakpoints.lg)]: {
        top: '48%',
        // left: '50%',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        left: '50%',
        top: '56%',
        height: '117px',
      },
      [theme.breakpoints.down(breakpoints.ms)]: {
        left: '50%',
        top: '54%',
        height: '135px',
      },
      [theme.breakpoints.down(breakpoints.mss)]: {
        left: '50%',
        top: '54%',
        height: '127px',
      },
      // [theme.breakpoints.down(breakpoints.ssmdl)]: {
      //   left: '74%',
      //   top: '54%',
      //   height: '134px',
      // },

      [theme.breakpoints.down(breakpoints.xss)]: {
        left: '50%',
        top: '50%',
        height: '125px',
      },
      [theme.breakpoints.down(breakpoints.xs)]: {
        left: '50%',
        top: '50%',
        height: '125px',
      },
      [theme.breakpoints.down(breakpoints.xxxs)]: {
        left: '50%',
        top: '55%',
        height: '100px',
      },
      [theme.breakpoints.down(breakpoints.xxsm)]: {
        left: '50%',
        top: '50%',
        height: '100px',
      },
    },

    '.ovr-mar': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        padding: '0px!important',
        height: '270px',
      },
    },

    '.title-box-top': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'block',
        zIndex: '111',
        position: 'absolute',
        top: '0%',
        background: '#F3F3FF',
        padding: '6px',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        width: '100%',
        borderBottom: '1px solid #D3E0ED',
        fontSize: '13px!important',
      },
    },

    '.title-box-bottom': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'block',
        zIndex: '111',
        position: 'absolute',
        bottom: '0%',
        background: '#F3F3FF',
        padding: '6px',
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
        width: '100%',
        borderTop: '1px solid #D3E0ED',
      },
    },

    '.progress-img': {
      width: '100%',
      position: 'relative',
      zIndex: '1',
      [theme.breakpoints.down(breakpoints.sm)]: {
        top: '0%',
        marginBottom: '40px',
        marginTop: '20px',
      },
    },

    '.mob-view': {
      [theme.breakpoints.up(breakpoints.sm)]: {
        display: 'none',
      },
    },
    //progress bar start

    //homeloaneligible start ---------------------------------------------
    '.note': {
      background: '#fff',
      boxShadow: 'rgb(149 157 165 / 20%) 0px 8px 24px',
      padding: '15px',
      borderRadius: '10px',
    },
    '.mainLoaneligibleBox': {
      backgroundColor: Variables.primaryColor,
      borderRadius: '6px',
      marginLeft: '7%',
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginLeft: '0%',
      },
    },

    '.mainLoaneligibleMonthBox': {
      backgroundColor: '#fff',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      '& h5': {
        color: Variables.primaryColor,
      },
    },

    '.eligi-Text-title': {
      color: '#7B7878!important',
      fontSize: '14px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      [theme.breakpoints.down(breakpoints.xxxs)]: {
        fontSize: '12px!important',
      },
    },

    '.homeLoanAmountmob': {
      display: 'flex',
      alignItems: 'center',

      '& .MuiInput-input': {
        textAlign: 'right!important',
        fontSize: '16px',
        [theme.breakpoints.down(breakpoints.xxxs)]: {
          fontSize: '11px!important',
        },
      },
      '& .MuiInput-root:before': {
        // width:"128px!important",
        left: '-26%!important',
      },

      '& .MuiInput-root:after': {
        // width:"128px!important",
        left: '-26%!important',
      },
    },

    '.homeLoanTenure': {
      '& .MuiInput-input': {
        textAlign: 'right!important',
        fontSize: '16px',
        [theme.breakpoints.down(breakpoints.xxxs)]: {
          fontSize: '11px!important',
        },
      },
      '& .MuiInput-root:before': {
        width: 'auto!important',
        left: '80%!important',
      },

      '& .MuiInput-root:after': {
        width: 'auto!important',
        left: '80%!important',
      },
    },

    '.homeLoaninterestmob': {
      display: 'flex',
      alignItems: 'center',
      '& .MuiInput-input': {
        textAlign: 'right!important',
        fontSize: '16px',
        [theme.breakpoints.down(breakpoints.xxxs)]: {
          fontSize: '11px!important',
        },
      },
      '& .MuiInput-root:before': {
        width: '42px!important',
        left: '63%!important',
      },

      '& .MuiInput-root:after': {
        width: '42px!important',
        left: '63%!important',
      },
    },

    '.subeligiTitle': {
      color: '#fff!important',
      fontSize: '26px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      textAlign: 'center!important',
      paddingTop: '15px!important',
      paddingBottom: '10px!important',

      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '20px!important',
      },
    },

    '.subeligwilliTitle': {
      color: '#0e7bf1',
      fontSize: '22px',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700',
      textAlign: 'center',
      paddingTop: '15px',
      paddingBottom: '10px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '18px!important',
      },
    },

    '.project-marketing-note': {
      padding: '20px!important',
    },

    //homeloaneligible end -----------------------------------------------

    //sign up form start -----------------------------------------------
    '.sign-up-form-main': {
      width: '100%!important',
      display: 'flex!important',
      alignItems: 'center!important',
      position: 'relative!important',
    },
    '.sign-up-form-info': {
      backgroundColor: '#fff!important',
      borderRadius: '10px!important',
      boxShadow: 'rgb(0 86 178 / 8%) 0px 8px 24px!important',
      padding: '15px 15px 15px 15px!important',
      border: '1px solid #2E37BB26!important',
      height: '100%!important',
      width: '65%!important',
      margin: '0px auto!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '100%!important',
      },
    },

    '.secsignupform': {
      padding: '20px!important',
      marginBottom: '40px!important',
      background: '#0056b2!important',
    },

    '.signformtitle': {
      textAlign: 'center!important',
    },
    '.textInput': {
      margin: '0 0 6px 0 !important',
      display: 'block!important',
      backgroundColor: '#fff!important',
      fontFamily: 'Open Sans, sans-serif !important',
      '& .MuiOutlinedInput-root': {
        borderRadius: '10px!important',
        height: '36px!important',
      },
      '& .MuiOutlinedInput-input': {
        padding: '20px!important',
      },
      '& .MuiInputLabel-formControl': {
        top: '-8px!important',
        fontSize: '13px!important',
        fontFamily: 'Open Sans, sans-serif !important',
      },
      '& .MuiTypography-body1': {
        fontSize: '15px!important',
        marginLeft: '10px!important',
        width: '52px!important',
        fontFamily: 'Open Sans, sans-serif!important',
        color: '#171717!important',
        fontWeight: '500!important',
      },
      '& .MuiButtonBase-root': {
        color: '#89a5c3!important',
      },
      '& .Mui-checked': {
        color: '#1976d2!important',
        marginRight: '10px!important',
      },
      '& .MuiTypography': {
        marginRight: '10px!important',
      },
    },

    '.textInput1': {
      margin: '0 0 11px 0 !important',
      display: 'block!important',
      backgroundColor: '#fff!important',
      '& .MuiOutlinedInput-root': {
        borderRadius: '10px!important',
        height: '36px!important',
      },
      '& .MuiOutlinedInput-input': {
        padding: '20px!important',
        backgroundColor: 'white',
        fontSize: '13px',
        color: '#6A6A6AE6',
      },
      '& .MuiInputLabel-formControl': {
        top: '-8px!important',
        fontSize: '13px!important',
        fontFamily: 'Open Sans, sans-serif !important',
      },
      '& .MuiTypography-body1': {
        fontSize: '15px!important',
        marginLeft: '10px!important',
        width: '52px!important',
        fontFamily: 'Open Sans, sans-serif!important',
        color: '#171717!important',
        fontWeight: '500!important',
      },
      '& .MuiButtonBase-root': {
        color: '#89a5c3!important',
      },
      '& .Mui-checked': {
        color: '#1976d2!important',
      },
    },

    '.formSubmitNew': {
      width: '65%',
      backgroundColor: '#DBDBDB',
      color: '#fff',
      textTransform: 'inherit',
      // transition: 'all 0.3s ease-in-out',
      borderRadius: '10px',
      fontSize: '20px',
      fontWeight: 'bold',

      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '18px',
        width: '100%',
      },

      '&:hover': {
        boxShadow: 'none',
        backgroundColor: '#0056B2',
        color: '#FFFFFF',
      },
    },

    '.CommonwriteReviewbtn': {
      border: '1px solid #0056B2',
      borderRadius: '12px',
      width: '45%',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '15px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        borderRadius: '5px',
        width: '60%',
        border: '1px solid #D2DEEB',
      },

      '& button': {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '-4px',
        paddingBottom: '10px',
        color: '#0056B2',
      },
      '& .MuiTypography-body1': {
        fontSize: '14px!important',
        marginLeft: '4px!important',
        // width:"42px!important",
        fontFamily: 'Open Sans, sans-serif !important',
        color: '#000000 !important',
        fontWeight: '500!important',
      },
    },

    '.revireTxt1': {
      paddingTop: '10px',
      paddingBottom: '10px',
      textAlign: 'left',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        paddingLeft: '5px',
        paddingRight: '14px',
        textAlign: 'center',
        marginTop: '-10px',
        height: '33px',
      },
    },

    '.revireTxt': {
      paddingTop: '10px',
      paddingBottom: '10px',
      textAlign: 'left',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        paddingLeft: '5px',
        paddingRight: '14px',
        textAlign: 'left',
        marginTop: '-28px',
        height: '95px',
      },
    },

    '.writeIcon1': {
      height: '55px',
      padding: '15px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '-15px',
      },
    },

    '.reviewRightBg': {
      backgroundImage: 'url(/static/images/Componentbg.svg)',
      backgroundPosition: 'right bottom',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      [theme.breakpoints.down(breakpoints.sm)]: {
        height: '150px',
      },
    },

    '.reviewPRight': {
      borderRadius: '12px',
      marginTop: '25px',
      textAlign: 'center',
      background: '#FEF5E6',
      padding: '18px 10px 0px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        padding: '35px 10px 0px',
        border: '1px solid #D7D8D2',
        marginTop: '0px',
        position: 'relative',
      },
    },

    '.reviewPRightcontainer': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        justifyContent: 'center',
        alignItems: 'left',
        display: 'block',
        marginBottom: '15px',
      },
    },

    '.CommonwriteRequestBtn': {
      border: '1px solid #1F78DC',
      borderRadius: '12px',
      width: '50%',
      textAlign: 'center',
      marginLeft: '45px',
      marginRight: '45px',
      marginBottom: '111px',
      background: '#1F78DC',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        // width: '46%',
        // position: 'absolute',
        // right: '-43px',
        // top: '-86px !important',
        background: '#0056B2',
        borderRadius: '5px',
        marginLeft: 'auto',
        marginRight: 'auto',
      },

      '& button': {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '-4px',
        paddingBottom: '10px',
        color: '#FFFFFF',
        fontSize: '14px',
        fontWeight: '700',
      },
    },

    '.TitleRating': {
      fontSize: '13px',
      fontFamily: 'Open Sans, sans-serif',
      color: Variables.darkColor,
      fontWeight: 'normal',
      marginBottom: '15px',
      paddingTop: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginLeft: '0px',
        marginRight: '0px',
        fontSize: '12px',
        width: '100%',
        paddingTop: '13px',
      },
    },

    '.baxContainerMap': {
      width: '20%',
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '20%',
      },
    },

    '.reportNowText': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      [theme.breakpoints.down(breakpoints.sm)]: {
        borderLeft: '1px solid #D3E0ED',
      },
      '& h5': {
        marginTop: '0px !important',
      },
    },

    '.reviewData': {
      width: '100%',
      marginBottom: '4px',
      '& p': {
        border: '1px solid #D2DEEB',
        padding: '7px',
        marginTop: '10px',
        borderRadius: '5px',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        [theme.breakpoints.down(breakpoints.sm)]: {
          fontSize: '12px',
        },
      },

      '& .nextico-our-culture': {
        border: '1px solid #E3EAF5 !Important',
        top: '54%',
        height: '32px!important',
        width: '32px!important',
        zIndex: '999 !important',

        '& div': {
          position: 'initial !Important',
        },

        '& span ': {
          position: 'initial !Important',
          display: 'inline-table !Important',
        },

        '& img': {
          marginTop: '0px!important',
          objectFit: 'cover !important',
        },
      },

      '& .previco-our-culture': {
        border: '1px solid #E3EAF5 !Important',
        top: '54%',
        height: '32px!important',
        width: '32px!important',
        zIndex: '999 !important',

        '& div': {
          position: 'initial !Important',
        },

        '& span ': {
          position: 'initial !Important',
          display: 'inline-table !Important',
        },

        '& img': {
          marginTop: '0px!important',
          objectFit: 'cover !important',
        },
      },
    },
    /*--EndAllreviews--*/

    '.textInputsellAskqus': {
      margin: '0 0 23px 0 !important',
      display: 'block!important',
      backgroundColor: '#fff!important',
      fontSize: '20px',
      fontWeight: '500',
      fontFamily: 'Open Sans, sans-serif !important',

      '& h4': {
        fontSize: '20px',

        [theme.breakpoints.down(breakpoints.sm)]: {
          fontSize: '16px',
        },
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '10px!important',
        height: '36px!important',
      },
      '& .MuiOutlinedInput-input': {
        padding: '20px!important',
        fontFamily: 'Open Sans, sans-serif !important',
      },
      '& .MuiInputLabel-formControl': {
        top: '-8px!important',
        fontSize: '16px!important',
        fontFamily: 'Open Sans, sans-serif !important',
      },
      '& .MuiTypography-body1': {
        fontSize: '16px!important',
        marginLeft: '4px!important',
        // width:"42px!important",
        fontFamily: 'Open Sans, sans-serif !important',
        color: '#171717!important',
        fontWeight: '500!important',
        [theme.breakpoints.down(breakpoints.sm)]: {
          fontSize: '12px !Important',
        },
      },
      '& .MuiButtonBase-root': {
        color: '#89a5c3!important',
        display: 'none',
      },
      '& .Mui-checked': {
        color: '#1976d2!important',
      },

      '& .MuiFormControlLabel-root': {
        '&:hover': {
          background: '#F2F5FC',
        },
        '&:Focus': {
          background: '#F2F5FC',
        },
        '&:checked': {
          background: '#F2F5FC',
        },
      },
    },

    '.textInputsell1': {
      margin: '0 0 23px 0 !important',
      display: 'block!important',
      backgroundColor: '#fff!important',
      fontSize: '16px',
      fontWeight: '500',
      fontFamily: 'Open Sans, sans-serif !important',
      '& span': {
        fontWeight: '700',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '10px!important',
        height: '36px!important',
      },
      '& .MuiOutlinedInput-input': {
        padding: '20px!important',
        fontFamily: 'Open Sans, sans-serif !important',
      },
      '& .MuiInputLabel-formControl': {
        top: '-8px!important',
        fontSize: '13px!important',
        fontFamily: 'Open Sans, sans-serif !important',
      },
      '& .MuiTypography-body1': {
        fontSize: '13px!important',
        marginLeft: '4px!important',
        // width:"42px!important",
        fontFamily: 'Open Sans, sans-serif!important',
        color: '#171717!important',
        fontWeight: '500!important',
      },
      '& .MuiButtonBase-root': {
        color: '#89a5c3!important',
        display: 'none',
      },
      '& .Mui-checked': {
        color: '#1976d2!important',
      },

      '& .MuiFormControlLabel-root': {
        '&:hover': {
          background: '#F2F5FC',
        },
        '&:Focus': {
          background: '#F2F5FC',
        },
        '&:checked': {
          background: '#F2F5FC',
        },
      },
    },

    '.textInputsell': {
      margin: '0 0 6px 0 !important',
      display: 'block!important',
      backgroundColor: '#fff!important',
      fontSize: '16px',
      fontWeight: '500',
      fontFamily: 'Open Sans, sans-serif !important',
      '& .MuiOutlinedInput-root': {
        borderRadius: '10px!important',
        height: '36px!important',
      },
      '& .MuiOutlinedInput-input': {
        padding: '20px!important',
        fontFamily: 'Open Sans, sans-serif !important',
      },
      '& .MuiInputLabel-formControl': {
        top: '-8px!important',
        fontSize: '13px!important',
        fontFamily: 'Open Sans, sans-serif !important',
      },
      '& .MuiTypography-body1': {
        fontSize: '14px!important',
        marginLeft: '4px!important',
        fontFamily: 'Open Sans, sans-serif !important',
        color: Variables.darkColor,
        fontWeight: '700!important',
        [theme.breakpoints.down(breakpoints.xss)]: {
          fontSize: '13px!important',
        },
      },
      '& .MuiButtonBase-root': {
        color: '#89a5c3!important',
        marginRight: '5px',
      },
      '& .Mui-checked': {
        color: '#1976d2!important',
      },
    },

    '.textInputchk': {
      margin: '0 0 6px 0 !important',
      display: 'block!important',
      backgroundColor: '#fff!important',
      fontFamily: 'Open Sans, sans-serif !important',
      '& .MuiOutlinedInput-root': {
        borderRadius: '10px!important',
        height: '30px!important',
        fontFamily: 'Open Sans, sans-serif !important',
      },
      '& .MuiOutlinedInput-input': {
        padding: '20px!important',
      },
      '& .MuiInputLabel-formControl': {
        top: '-8px!important',
        fontSize: '10px!important',
        fontFamily: 'Open Sans, sans-serif !important',
      },
      '& .MuiTypography-body1': {
        fontSize: '14px!important',
        fontFamily: 'Open Sans, sans-serif!important',
        color: '#6A6A6AE6!important',
        fontWeight: '500!important',
      },
    },

    '.textAreaInput1': {
      margin: '0 0 6px 0 !important',
      display: 'block!important',
      backgroundColor: '#fff!important',
      fontFamily: 'Open Sans, sans-serif !important',
      '& .MuiOutlinedInput-root': {
        borderRadius: '10px!important',
        height: '45px!important',
        fontFamily: 'Open Sans, sans-serif !important',
      },
      '& .MuiOutlinedInput-input': {
        padding: '20px!important',
        fontFamily: 'Open Sans, sans-serif !important',
      },
      '& .MuiInputLabel-formControl': {
        top: '-8px!important',
        fontSize: '13px!important',
        fontFamily: 'Open Sans, sans-serif !important',
      },
    },

    '.textAreaInput': {
      margin: '0 0 6px 0 !important',
      display: 'block!important',
      backgroundColor: '#fff!important',
      fontFamily: 'Open Sans, sans-serif !important',
      '& .MuiOutlinedInput-root': {
        borderRadius: '10px!important',
        height: '40px!important',
        fontFamily: 'Open Sans, sans-serif !important',
      },
      '& .MuiOutlinedInput-input': {
        padding: '20px!important',
        fontFamily: 'Open Sans, sans-serif !important',
      },
      '& .MuiInputLabel-formControl': {
        top: '-8px!important',
        fontSize: '13px!important',
        fontFamily: 'Open Sans, sans-serif !important',
      },
    },

    '.textAreasellInput': {
      margin: '0 0 6px 0 !important',
      display: 'block!important',
      backgroundColor: '#fff!important',
      fontFamily: 'Open Sans, sans-serif !important',
      '& .MuiOutlinedInput-root': {
        borderRadius: '10px!important',
        height: '36px!important',
        fontFamily: 'Open Sans, sans-serif !important',
      },
      '& .MuiOutlinedInput-input': {
        padding: '20px!important',
        fontFamily: 'Open Sans, sans-serif !important',
      },
      '& .MuiInputLabel-formControl': {
        top: '-8px!important',
        fontSize: '13px!important',
        fontFamily: 'Open Sans, sans-serif !important',
      },
    },

    '.textInputSelect': {
      margin: '0 0 6px 0!important',
      display: 'block!important',
      backgroundColor: '#fff!important',
      fontFamily: 'Open Sans, sans-serif !important',
      opacity: '0.8!important',
      fontSize: '13px!important',
      '& .MuiOutlinedInput-root': {
        borderRadius: '10px!important',
        height: '36px!important',
        fontSize: '13px!important',
        fontFamily: 'Open Sans, sans-serif !important',
        opacity: '0.8!important',
      },
      '& .MuiOutlinedInput-input': {
        padding: '20px!important',
        fontFamily: 'Open Sans, sans-serif !important',
      },
      '& .MuiInputLabel-formControl': {
        top: '-8px!important',
        fontSize: '13px!important',
        fontFamily: 'Open Sans, sans-serif !important',
      },
    },

    '.textInput-clogo': {
      padding: '23px!important',
      background: '#F2F5FC!important',
      borderRadius: '10px!important',
      fontFamily: 'Open Sans, sans-serif !important',
    },

    '.txt-margin': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginTop: '5px!important',
      },
    },
    '.formsellSubmit': {
      width: '111px!important',
      backgroundColor: '#2E72BB!important',
      color: '#fff!important',
      textTransform: 'capitalize!important',
      // transition: 'all 0.3s ease-in-out!important',
      borderRadius: '8px!important',
      padding: '6px 10px!important',
      fontSize: '14px!important',
      marginRight: '15px!important',
      // // marginBottom: '15px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700',
      '&:hover': {
        boxShadow: 'none!important',
        backgroundColor: '#074587!important',
        color: '#FFFFFF!important',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '100%!important',
        marginTop: '15px!important',
      },
    },

    '.formsellotpSubmit': {
      width: '250px!important',
      backgroundColor: '#0056B2!important',
      color: '#fff!important',
      textTransform: 'capitalize!important',
      // transition: 'all 0.3s ease-in-out!important',
      borderRadius: '12px!important',
      padding: '6px 15px!important',
      fontSize: '16px!important',
      marginRight: '15px!important',
      marginBottom: '15px!important',
      '&:hover': {
        boxShadow: 'none!important',
        backgroundColor: '#074587!important',
        color: '#FFFFFF!important',
      },
      '&:disabled': {
        background: '#808080 !important',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '100%!important',
        marginTop: '15px!important',
      },
    },

    '.listProperty': {
      marginTop: '60px!important',

      [theme.breakpoints.down(breakpoints.sm)]: {
        marginTop: '0px!important',
      },
    },

    '.formsellcancelSubmit': {
      width: '111px!important',
      backgroundColor: '#fff!important',
      color: '#101010!important',
      textTransform: 'capitalize!important',
      // transition: 'all 0.3s ease-in-out!important',
      borderRadius: '8px!important',
      padding: '9px 10px!important',
      fontSize: '14px!important',
      // marginBottom: '15px!important',
      marginRight: '20px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '500',
      lineHeight: '19px',
      letterSpacing: '-0.21px',
      border: '1px solid #E4E4ED',
      // "&:hover": {
      //   boxShadow: "none!important",
      //   backgroundColor: "#074587!important",
      //   color: "#FFFFFF!important",
      // },
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '100%!important',
        marginBottom: '20px',
      },
    },

    //sign up form end -----------------------------------------------

    //sell with homebazaar start--------------------------------------------------

    '.sell-up-form-points': {
      backgroundColor: '#FFE7DB!important',
      borderRadius: '10px!important',
      padding: '15px!important',
      // height:"100%!important",
      width: '28%!important',
      position: 'absolute!important',
      right: '2%!important',
      zIndex: '-1!important',
      textAlign: 'center!important',
      [theme.breakpoints.down(breakpoints.md)]: {
        display: 'none!important',
      },
    },

    '.sell-with-form-info': {
      backgroundColor: '#fff!important',
      borderRadius: '10px!important',
      boxShadow: 'rgb(0 86 178 / 8%) 0px 8px 24px!important',
      border: '1px solid #CBCBCB!important',
      [theme.breakpoints.down(breakpoints.md)]: {
        width: '100%!important',
      },
    },

    '.sbcancelbtns': {
      textAlign: 'right!important',
    },
    '.sbcancelbtnsotp': {
      textAlign: 'center!important',
      marginTop: '20px',
    },
    '.sellwithformimgmain': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      marginLeft: '20px',
      marginTop: '20px',
    },

    '.sellwithformimgtext': {
      width: '70%',
    },

    '.sellwithformimg': {
      width: '30%',
    },

    '.what-make-main': {
      margin: '10px',
      '& img': {
        borderRadius: '10px',
      },
    },
    '.what-make-shape': {
      backgroundColor: '#fff',
      height: '120px',
      clipPath: 'circle(90% at 50% -70%)',
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    //sell with homebazaar end--------------------------------------------------

    //builder services start--------------------------------------------------
    '.builder-services-title': {
      backgroundColor: '#0056B20A!important',
      padding: '8px!important',
      width: '260px!important',
      borderRadius: '3px!important',
      textAlign: 'center!important',
      marginBottom: '15px!important',
      [theme.breakpoints.down(breakpoints.md)]: {
        width: '100%!important',
      },
    },

    '.builder-services-main-heading': {
      fontSize: '24px!important',
      marginBottom: '25px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '22px!important',
        marginBottom: '25px!important',
      },
    },

    // our culture details page start
    '.beingImgDetails': {
      borderRadius: '18px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center',
      },
      '& img': {
        border: '2px solid #0056b2!important',
        borderRadius: '18px!important',
      },
    },

    '.beingImgDetailsShort': {
      borderRadius: '18px!important',
      textAlign: 'right',

      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center',
        marginBottom: '20px',
      },
      '& img': {
        border: '2px solid #0056b2!important',
        borderRadius: '18px!important',

        [theme.breakpoints.down(breakpoints.lg)]: {
          width: '300px!important',
        },
        [theme.breakpoints.down(breakpoints.mdl)]: {
          width: '210px!important',
        },
        [theme.breakpoints.down(breakpoints.ssm)]: {
          width: '150px!important',
        },

        [theme.breakpoints.down(breakpoints.sm)]: {
          width: '470px!important',
        },
      },
    },

    '.beingImgDetailsShortTwo': {
      borderRadius: '18px!important',
      textAlign: 'left',

      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center',
        marginBottom: '20px',
      },
      '& img': {
        border: '2px solid #0056b2!important',
        borderRadius: '18px!important',

        [theme.breakpoints.down(breakpoints.lg)]: {
          width: '300px!important',
        },
        [theme.breakpoints.down(breakpoints.mdl)]: {
          width: '210px!important',
        },
        [theme.breakpoints.down(breakpoints.ssm)]: {
          width: '150px!important',
        },

        [theme.breakpoints.down(breakpoints.sm)]: {
          width: '470px!important',
        },
      },
    },

    //shoer post start

    '.testimonial-short-second': {
      height: '205px',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 3px 8px',
      position: 'relative',
      padding: '14px',
      background: '#fff',
      borderRadius: '20px',

      [theme.breakpoints.down(breakpoints.ssm)]: {
        height: '100%!important',
        padding: '10px',
      },
    },
    '.testimonial-short': {
      textAlign: 'center',
      padding: '28px',
      position: 'relative',
      background: '#fff',
      borderRadius: '20px',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 3px 8px',
      marginTop: '-26px',
    },

    '.testimonial-pic': {
      width: '75px',
      height: '75px',
      background: '#CFE1F4',
      borderRadius: '50%',
      position: 'absolute',
      top: '0%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },

    '.shortpost-next': {
      position: 'absolute',
      left: '115.5%',
      top: '45%',
      zIndex: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fff',
      // border: "1px solid #ddd",
      boxShadow: 'rgba(0, 0, 0, 0.18) 0px 1px 5px',
      borderRadius: '50%',
      height: '45px',
      width: '45px',
      cursor: 'pointer',
      '& img': {
        marginTop: '3px!important',
      },

      [theme.breakpoints.down(breakpoints.ssmdl)]: {
        height: '35px',
        width: '35px',
        left: '100%',
      },

      [theme.breakpoints.down(breakpoints.ssm)]: {
        height: '35px',
        width: '35px',
        left: '100%',
      },
    },
    '.shortpost-prev': {
      position: 'absolute',
      right: '115.5%',
      top: '45%',
      zIndex: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fff',
      // border: "1px solid #ddd",
      boxShadow: 'rgba(0, 0, 0, 0.18) 0px 1px 5px',
      borderRadius: '50%',
      height: '45px',
      width: '45px',
      cursor: 'pointer',
      '& img': {
        marginTop: '3px!important',
      },

      [theme.breakpoints.down(breakpoints.ssmdl)]: {
        height: '35px',
        width: '35px',
        right: '100%',
      },

      [theme.breakpoints.down(breakpoints.ssm)]: {
        height: '35px',
        width: '35px',
        left: '-15.5%',
      },
    },

    //short post end

    '.short-position': {
      margin: '80px auto',
    },

    '.bg-testimonial': {
      background: 'url("/static/images/ourculture/bg-short1.png")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      height: '330px',
      width: '100%',
      maxWidth: '100%',
      backgroundPosition: '70% 30%',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        background: 'none!important',
        height: '100%',
      },
      [theme.breakpoints.down(breakpoints.ssm)]: {
        background: 'none!important',
        height: '100%',
      },
    },

    '.profile-short': {
      width: '90px',
      height: '90px',
      background: '#CFE1F4',
      borderRadius: '50%',
      position: 'absolute',
      top: '0%',
      left: '50%',
      transform: 'translate(-50%, -50%)',

      // "& img":{
      //   position:"absolute!important",
      //   left:"50%!important",
      //   top:"50%!important",
      //   transform:"translate(-50%, -50%)!important",
      //   height:"35px!important",
      //   width:"35px!important",
      // }
    },

    '.testimonial-short-box': {
      padding: '25px',
      position: 'relative',
      background: '#fff',
      borderRadius: '20px',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 3px 8px',
    },

    '.beingImgDetailsShortTwoNBorder': {
      borderRadius: '18px!important',
      textAlign: 'left',

      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center',
        marginBottom: '20px',
      },
      '& img': {
        // border: "2px solid #0056b2!important",
        borderRadius: '18px!important',

        [theme.breakpoints.down(breakpoints.lg)]: {
          width: '300px!important',
        },
        [theme.breakpoints.down(breakpoints.mdl)]: {
          width: '210px!important',
        },
        [theme.breakpoints.down(breakpoints.ssm)]: {
          width: '150px!important',
        },

        [theme.breakpoints.down(breakpoints.sm)]: {
          width: '470px!important',
        },
      },
    },

    '.mob-short': {
      [theme.breakpoints.up(breakpoints.sm)]: {
        display: 'none',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'block',
      },
    },

    '.desk-short': {
      [theme.breakpoints.up(breakpoints.sm)]: {
        display: 'block',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'none',
      },
    },

    // ".desk-short-res":{
    //   [theme.breakpoints.down(breakpoints.mdl)]: {
    //     paddingRight:"18px!important"
    //   },
    // },

    // ".desk-short-res":{
    //   [theme.breakpoints.down(breakpoints.sm)]: {
    //     paddingRight:"0px!important"
    //   },
    // },

    // our culture details page end

    // our culture page start
    '.mobBanner': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'block!important',
      },
      [theme.breakpoints.up(breakpoints.sm)]: {
        display: 'none!important',
      },
    },
    '.deskBanner': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'none!important',
      },
      [theme.breakpoints.up(breakpoints.sm)]: {
        display: 'block!important',
      },
    },

    '.beimgText': {
      color: '#1E2637!important',
      fontSize: '14px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '500!important',
      marginBottom: '18px!important',
      marginTop: '18px!important',
      lineHeight: '25px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginTop: '8px!important',
      },
    },
    '.cultureBanner': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        margin: '10px 10px 30px 0px!important',
      },
    },
    '.cultureBannerMainTitle': {
      color: Variables.darkColor,
      fontSize: '48px!important',
      fontFamily: 'Open Sans, sans-serif!important',
      fontWeight: '700!important',
      marginBottom: '23px!important',
      [theme.breakpoints.down(breakpoints.ssmdl)]: {
        fontSize: '38px!important',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '22px!important',
        textAlign: 'left!important',
        marginBottom: '10px!important',
      },
    },
    '.cultureBannerMainText': {
      color: Variables.darkColor,
      fontSize: '16px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '400!important',
      marginBottom: '5px!important',
      lineHeight: '27px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '15px!important',
        textAlign: 'justify!important',
      },
    },
    '.beingImg': {
      borderRadius: '18px!important',
      '& img': {
        border: '3px solid #0056B2!important',
        borderRadius: '18px!important',
        height: '208px!important',
        [theme.breakpoints.down(breakpoints.sm)]: {
          // width:"370px!important",
        },
      },
    },
    '.AppreciationImg': {
      borderRadius: '18px!important',
      margin: '10px!important',

      '& img': {
        border: '1px solid #0056b226!important',
        borderRadius: '18px!important',
      },
    },

    '.previco-our-culture': {
      position: 'relative!important',
      right: '0.5%!important',
      bottom: '58%!important',
      zIndex: '1!important',
      display: 'flex!important',
      alignItems: 'center!important',
      justifyContent: 'center!important',
      background: '#fff!important',
      border: '1px solid #ddd!important',
      borderRadius: '50%!important',
      height: '35px!important',
      width: '35px!important',
      cursor: 'pointe!importantr',
      '& img': {
        marginTop: '3px!important',
      },

      [theme.breakpoints.down(breakpoints.sm)]: {
        right: '2.5%!important',
        height: '32px!important',
        width: '32px!important',
        // display: 'none!important',
        // display: 'none!important',
      },
    },
    '.nextico-our-culture': {
      position: 'relative!important',
      left: '97.5%!important',
      bottom: '44%!important',
      zIndex: '1!important',
      display: 'flex!important',
      alignItems: 'center!important',
      justifyContent: 'center!important',
      background: '#fff!important',
      border: '1px solid #ddd!important',
      borderRadius: '50%!important',
      height: '35px!important',
      width: '35px!important',
      cursor: 'pointer!important',
      '& img': {
        marginTop: '3px!important',
      },

      [theme.breakpoints.down(breakpoints.sm)]: {
        left: '90.5%!important',
        height: '32px!important',
        width: '32px!important',
        // display: 'none!important',
        // display: 'none!important',
      },
    },

    '.beingContent': {
      padding: '36px 30px!important',
      background: '#F2F5FC!important',
      borderRadius: '20px!important',
      height: '208px!important',
      alignItems: 'center!important',
      [theme.breakpoints.down(breakpoints.ssmll)]: {
        height: 'auto!important',
        borderRadius: '12px!important',
        padding: '15px!important',
      },
    },

    '.noPadding': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        padding: '0px!important',
        marginBottom: '10px!important',
      },
    },

    '.hrScroll': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        overflow: 'scroll!important',
        whiteSpace: 'nowrap!important',
      },
    },

    '.beingContentTitle': {
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      lineHeight: '1.8!important',
      fontSize: '18px!important',
      color: Variables.darkColor,
      textTransform: 'uppercase!important',
      marginTop: '19px!important',
      marginBottom: '16px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '14px!important',
        marginTop: '4px!important',
        marginBottom: '4px!important',
      },
    },

    '.pgbeinginfo': {
      paddingLeft: '40px!important',
      paddingRight: '40px!important',
      marginBottom: '20px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        paddingLeft: '0px!important',
        paddingRight: '0px!important',
        marginBottom: '10px!important',
      },
    },

    '.our-culture-main-heading': {
      fontSize: '24px!important',
      marginBottom: '10px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '22px!important',
        marginBottom: '10px!important',
      },
    },
    '.mainBenefits': {
      display: 'flex!important',
      width: '100%!important',
      alignItems: 'center!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'block!important',
        textAlign: 'left!important',
      },
    },
    '.mainBenefitsCul': {
      display: 'flex!important',
      width: '100%!important',
      alignItems: 'center!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginBottom: '20px!important',
      },
    },
    '.blogMainTitle': {
      color: '#1D6CB5!important',
      fontSize: '15px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      marginBottom: '10px!important',
      lineHeight: '25px!important',
      textAlign: 'left!important',
      marginTop: '10px!important',
      whiteSpace: 'break-spaces!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '12px!important',
        lineHeight: '20px!important',
      },
    },
    '.blogMainText': {
      color: '#171717!important',
      fontSize: '13px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '500!important',
      marginBottom: '30px!important',
      lineHeight: '25px!important',
      textAlign: 'left!important',
      whiteSpace: 'break-spaces!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '11px!important',
        lineHeight: '18px!important',
        marginBottom: '2px!important',
        textAlign: 'justify!important',
      },
    },
    '.blog-main-box': {
      textAlign: 'center!important',
      border: '1px solid #1D6CB51A!important',
      padding: '12px!important',
      borderRadius: '20px!important',
      cursor: 'pointer!important',
      marginRight: '15px!important',
      marginBottom: '20px!important',
      //  height: "350px!important",

      [theme.breakpoints.down(breakpoints.sm)]: {
        height: 'auto!important',
        whiteSpace: 'break-spaces!important',
        marginRight: '15px!important',
        padding: '9px!important',
        width: '240px!important',
      },
      '& img': {
        borderRadius: '10px!important',
      },
      '&:hover': {
        boxShadow: '0 8px 22px #1d6cb510!important',
      },
    },

    '.tag': {
      margin: '10px!important',
      padding: '7px 40px!important',
      border: '1px solid #1D6CB50F',
      borderRadius: '6px!important',
      background: '#fff!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      fontSize: '14px!important',
      color: Variables.primaryColor,
      cursor: 'pointer!important',
      transition: 'all 1.5s ease-out!important',
    },
    '.awardtag': {
      margin: '10px!important',
      padding: '1px 20px!important',
      border: 'none!important',
      borderRadius: '20px!important',
      background: '#f5f5ff',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      fontSize: '14px!important',
      color: '#EE6A2A!important',
      cursor: 'pointer!important',
      transition: 'all 1.5s ease-out!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        padding: '6px 7px!important',
        margin: '5px!important',
      },
    },
    '.activeTagCulture': {
      margin: '10px!important',
      padding: '7px 40px!important',
      border: '1px solid #1D6CB50F!important',
      borderRadius: '6px!important',
      background: 'rgb(0, 86, 178)!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      fontSize: '14px!important',
      color: '#fff!important',
      cursor: 'pointer!important',
      transition: 'all 1.5s ease-out!important',
      // [theme.breakpoints.down(breakpoints.sm)]: {
      //   padding: "2px 25px!important",
      // },
    },

    '.mainImgBox': {
      display: 'flex!important',
      alignItems: 'center!important',
      width: '100%!important',
    },
    '.mainImgBoxTwo': {
      width: '40%!important',
    },
    '.imgBRadius': {
      '& img': {
        borderRadius: '15px!important',
        [theme.breakpoints.down(breakpoints.sm)]: {
          width: '230px!important',
        },
      },
    },
    '.marginbx': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        margin: '0px 10px',
        display: 'inline-block',
      },
    },
    '.DeskViewWorkspace': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'none!important',
      },
    },
    '.MobViewWorkspace': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        overflow: 'scroll',
        whiteSpace: 'nowrap',
        display: 'block',
      },
      [theme.breakpoints.up(breakpoints.sm)]: {
        display: 'none!important',
      },
    },

    '.mainOrgHeading': {
      display: 'flex!important',
      alignItems: 'center!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'block!important',
      },
    },
    '.mainOrgHeadingText': {
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      lineHeight: '1.8!important',
      fontSize: '20px!important',
      color: Variables.primaryColor,
      paddingLeft: '18px!important',
      marginTop: '-2px!important',
      textTransform: 'uppercase!important',
      [theme.breakpoints.down(breakpoints.ssmdl)]: {
        fontSize: '15px!important',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        paddingLeft: '0px!important',
        marginTop: '-2px!important',
        textAlign: 'left!important',
        fontSize: '16px!important',
      },
    },

    '.mainOrgHeadingTitle': {
      color: '#171717!important',
      fontFamily: 'Open Sans, sans-serif!important',
      fontWeight: '700!important',
      opacity: '0.9!important',
      fontSize: '30px!important',
      paddingRight: '18px!important',
      borderRight: '3px solid rgba(23, 23, 23, 0.13)!important',
      [theme.breakpoints.down(breakpoints.ssmdl)]: {
        fontSize: '22px!important',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        borderRight: 'none!important',
        paddingRight: '0px!important',
        fontSize: '22px!important',
        textAlign: 'left!important',
        marginBottom: '10px!important',
        marginTop: '20px',
      },
    },
    // our culture page end

    //mob-footer start
    '.mob-footer-left': {
      height: '70px',
      background: '#fff',
      flex: '2',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      position: 'relative',
      borderTopRightRadius: '24px',
      bottom: '-9px',
      marginRight: '-2px',
      zIndex: '0',
      textTransform: 'capitalize',
    },
    '.mob-footer-right': {
      height: '70px',
      background: '#fff',
      flex: '2',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      borderTopRightRadius: '0px',
      position: 'relative',
      borderTopLeftRadius: '24px',
      bottom: '-9px',
      marginLeft: '-2px',
      zIndex: '0',
      textTransform: 'capitalize',
    },
    '.activeTag': {
      margin: '10px!important',
      padding: '15px 40px!important',
      border: '1px solid #1D6CB50F!important',
      borderRadius: '40px!important',
      background: 'rgb(0, 86, 178)!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      fontSize: '14px!important',
      color: '#fff!important',
      cursor: 'pointer!important',
      transition: 'all 1.5s ease-out!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        padding: '11px 25px!important',
      },
    },
    '.bg-awards': {
      backgroundImage: 'url(/static/images/awards/awards-bg.png)',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      [theme.breakpoints.down(breakpoints.md)]: {
        padding: '15px',
      },
    },

    '.awardbannerText': {
      color: '#171717!important',
      fontSize: '26px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      marginBottom: '5px!important',
      lineHeight: '40px!important',
      [theme.breakpoints.down(breakpoints.md)]: {
        fontSize: '16px!important',
        textAlign: 'center!important',
        lineHeight: '26px!important',
      },
    },

    '.bgleftimgposition': {
      position: 'relative',
      top: '8%',
      [theme.breakpoints.down(breakpoints.md)]: {
        top: '14%',
        textAlign: 'center',
      },
    },

    '.awards-text-bg': {
      position: 'relative',
      top: '25%',
    },

    '.tabBg': {
      background: '#f5f5ff',
      textAlign: 'center',
      borderRadius: '40px',
      marginBottom: '20px',
      '& button:hover': {
        backgroundColor: 'transparent',
      },
      '& button:focus': {
        backgroundColor: 'transparent',
      },
      '& button:active': {
        backgroundColor: 'transparent',
      },

      '& .MuiTabs-flexContainer': {
        marginLeft: '-25px !important',
      },

      '& .MuiTab-root': {
        padding: '0px',
        opacity: '100% !important',
        minWidth: '153px',
      },
      '@media (max-width:  420px)': {
        '& .MuiTabs-scrollButtonsDesktop': {
          display: 'block',
          paddingTop: '23px',
        },
      },
    },

    '.imgBAwards': {
      boxShadow: 'rgb(38 57 77) 0px 22px 20px -10px',
      border: '12px solid #333333!important',
      borderTop: '9px solid #333333!important',
      borderBottom: '9px solid #333333!important',
      // height: "190px",
      // marginBottom: "25px",
      margin: '25px 40px',
    },

    //awards page end

    // short post details page start

    '.shortPostBanner': {
      position: 'relative!important',
      marginBottom: '30px!important',
      '& img': {
        borderRadius: '20px!important',
        // height: "216px!important",
        // objectFit: "cover!important",
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        '& img': {
          height: '120px!important',
          objectFit: 'cover!important',
        },
      },
      '&::after': {
        content: '""',
        position: 'absolute!important',
        top: 0,
        bottom: '5px!important',
        right: 0,
        left: 0,
        background: '#000000',
        opacity: '0.4!important',
        display: 'block!important',
        zIndex: 1,
        borderRadius: '20px!important',
      },
    },
    '.shortPostBannerText': {
      top: '50%!important',
      left: '50%!important',
      transform: 'translate(-50%, -50%)!important',
      position: 'absolute!important',
      zIndex: '11!important',
      width: '100%',
      [theme.breakpoints.down(breakpoints.sm)]: {
        bottom: '0%!important',
        width: '100%!important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    '.shortPostBannerTextSize': {
      color: '#fff!important',
      fontSize: '30px!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      marginBottom: '15px!important',
      textAlign: 'center!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '16px!important',
        lineHeight: '24px!important',
      },
    },

    '.pdMaintitle': {
      padding: '0px 20px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        padding: '0px 2px!important',
      },
    },

    '.mrtopmob': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginTop: '20px',
      },
    },

    '.pdMaintitleText': {
      padding: '0px 30px!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        padding: '0px 2px!important',
      },
    },

    // short post details page end

    '.project-marketing': {
      padding: '20px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        padding: '0px 20px',
      },
    },

    '.project-marketing-opp': {
      padding: '60px 0px',
      '& h4': {
        color: '#fff!important',
        fontSize: '40px!important',
        fontFamily: 'Open Sans, sans-serif!important',
        fontWeight: '700!important',
        marginBottom: '15px!important',
        textAlign: 'center!important',
        [theme.breakpoints.down(breakpoints.sm)]: {
          fontSize: '34px!important',
        },
      },
    },

    '.project-mark': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: '10px',
      backgroundColor: '#fff',
      border: '1px solid #0056B21A',
      borderRadius: '10px',
    },
    '.project-mark-number': {
      backgroundColor: '#EE6A2A',
      textAlign: 'center',
      color: '#fff!important',
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      marginRight: '15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '.benifitsmain': {
      height: '60px',
      width: '65px',
      background: '#0056B233',
      marginRight: '15px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px',
      borderRadius: '4px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginBottom: '15px',
      },
    },
    '.benifitsmaincul': {
      height: '60px',
      width: '65px',
      background: '#0056B233',
      marginRight: '15px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px',
      borderRadius: '4px',
    },
    '.benifitsmainimg': {
      height: '52px',
      width: '60px',
      backgroundColor: '#0056B2',
      borderRadius: '4px',
      padding: '5px',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
    },
    '.audiencebox': {
      backgroundColor: '#fff',
      padding: '15px',
      textAlign: 'center',
      borderRadius: '10px',
      boxShadow: 'rgb(149 157 165 / 20%) 0px 8px 24px;)',
      position: 'relative',
      marginTop: '45px',
    },

    '.builderservicesImgMain': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#0056B20F',
      width: '100px',
      height: '100px',
      borderRadius: '50%',
      margin: '0px auto',
      marginBottom: '20px',
      marginTop: '-60px',
    },

    '.builderservicesCircleImg': {
      background: '#0056B2',
      height: '80px',
      width: '80px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    // ".gallery":{
    //   position:"relative",
    //   height:"100%",
    //   width:"90%",
    //   margin:"auto",
    //   padding:"45px 0",
    //   display:"grid",
    //   gridTemplateColumns:"auto auto auto auto",
    //   gridGap:"1vmin",
    //   gridAutoFlow:"dence"
    // },

    // ".galleryimg":{
    //   position:"relative",
    //   height:"100%",
    //   width:"100%",
    //   overflow:"hidden",
    //   "& img":{
    //     gridColumnStart:"span 2",
    //     gridRowStart:"span 2"
    //   },
    // },

    //builder services end--------------------------------------------------

    //notfound start
    '.notfound': {
      textAlign: 'center',

      [theme.breakpoints.down(breakpoints.sm)]: {
        marginBottom: '50px',
        marginTop: '50px',
      },
    },

    '.notfound-hr': {
      height: '2px',
      width: '480px',
      borderTop: '2px dashed #868686',
      margin: '0px auto',
      marginBottom: '20px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '320px',
      },
    },
    '.notfound-text': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '90%px',
        margin: '0px auto',
      },
    },
    '.notfound-back': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
    },
    '.back-arrow': {
      marginRight: '10px',
      color: '#818181',
    },
    '.not-section': {
      marginBottom: '30px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        paddingLeft: '10px',
        paddingRight: '10px',
      },
    },

    //not found end

    '.mobileBannerRight': {
      position: 'absolute',
      right: '0',
      zIndex: 1,
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'none',
      },
    },
    '.bredcrumb-text-size': {
      fontSize: '12px!important',
      color: '#171717!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '400!important',
      float: 'right',
      // '& .MuiBreadcrumbs-li': {
      //   marginLeft: '5px',
      //   marginRight: '5px',
      // },
      marginBottom: '10px!important',
      [theme.breakpoints.down(breakpoints.ssmdl)]: {
        marginBottom: '0px!important',
        float: 'left!important',
        '& .MuiBreadcrumbs-li:last-child': {
          whiteSpace: 'nowrap',
          width: 'calc(100vw - 210px)',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
    '.MuiBreadcrumbs-separator': {
      display: 'flex!important',
      marginLeft: '8px!important',
      userSelect: 'none!important',
      marginRight: '8px!important',
      [theme.breakpoints.down(breakpoints.ssmdl)]: {
        display: 'flex!important',
        marginLeft: '2px!important',
        userSelect: 'none!important',
        marginRight: '2px!important',
      },
    },
    '.property-project-name': {
      '& h1': {
        fontSize: '24px!important',
        color: Variables.darkColor,
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '700!important',
        marginRight: '15px!important',
        [theme.breakpoints.down(breakpoints.ssmdl)]: {
          fontSize: '16px!important',
        },
      },
      '& h4': {
        fontSize: '19px!important',
        color: Variables.darkColor,
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '700!important',
        marginRight: '0px!important',
        [theme.breakpoints.down(breakpoints.ssmdl)]: {
          fontSize: '13px!important',
        },
      },

      [theme.breakpoints.down(breakpoints.ssm)]: {
        fontSize: '20px!important',
        color: Variables.darkColor,
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '700!important',
        marginRight: '0px!important',
      },
    },
    '.property-project-name-white': {
      '& h1': {
        fontSize: '24px!important',
        color: '#FCFCFC!important',
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '700!important',
        marginRight: '15px!important',
        [theme.breakpoints.down(breakpoints.ssmdl)]: {
          fontSize: '16px!important',
        },
      },
      '& h4': {
        fontSize: '18px!important',
        color: '#FCFCFC!important',
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '700!important',
        marginRight: '0px!important',
        [theme.breakpoints.down(breakpoints.ssmdl)]: {
          fontSize: '13px!important',
        },
      },

      [theme.breakpoints.down(breakpoints.ssm)]: {
        fontSize: '20px!important',
        color: '#fff!important',
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '700!important',
        marginRight: '0px!important',
      },
    },
    //   //// for commercial office

    '.property-project-Commercial-office': {
      '& h1': {
        fontSize: '20px!important',
        color: '#FCFCFC!important',
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '700!important',
        marginRight: '15px!important',
        [theme.breakpoints.down(breakpoints.ssmdl)]: {
          fontSize: '16px!important',
        },
      },
      '& h4': {
        fontSize: '17px!important',
        color: '#FCFCFC!important',
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '700!important',
        marginRight: '0px!important',
        [theme.breakpoints.down(breakpoints.ssmdl)]: {
          fontSize: '13px!important',
        },
      },

      [theme.breakpoints.down(breakpoints.ssm)]: {
        fontSize: '20px!important',
        color: '#fff!important',
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '700!important',
        marginRight: '0px!important',
      },
    },

    //  //// //

    '.mRight': {
      marginRight: '12px!important',
    },
    '.mLeft': {
      marginLeft: '15px!important',
    },
    '.property-dev-name': {
      '& h4': {
        fontSize: '13px!important',
        color: Variables.darkColor,
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '500!important',
        marginTop: '3px!important',
        marginBottom: '3px!important',
        display: 'flex!important',
        alignItems: 'center!important',
        borderRight: '1px solid #D3E0ED!important',
        paddingRight: '10px!important',
        paddingLeft: '10px',
      },
      '& h5': {
        fontSize: '13px!important',
        color: Variables.darkColor,
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '500!important',
        marginTop: '3px!important',
        marginBottom: '3px!important',
        display: 'flex!important',
        alignItems: 'center!important',
        borderRight: '1px solid #D3E0ED!important',
        paddingRight: '10px!important',
      },
      [theme.breakpoints.down(breakpoints.ssm)]: {
        fontSize: '13px!important',
        color: Variables.darkColor,
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '500!important',
        marginTop: '3px!important',
        marginBottom: '3px!important',
        display: 'flex!important',
        alignItems: 'center!important',
        paddingRight: '10px!important',
      },
    },
    '.property-dev-name-white': {
      '& h5': {
        fontSize: '14px!important',
        color: '#FCFCFC!important',
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '700!important',
        // marginTop: '3px!important',
        marginBottom: '3px!important',
        // display: 'flex!important',
        alignItems: 'center!important',
        borderRight: '1px solid #F2A079!important',
        paddingRight: '10px!important',
        display: 'inline-block !important',
        maxWidth: '240px !important',
        whiteSpace: 'nowrap',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis',
      },
      [theme.breakpoints.down(breakpoints.ssm)]: {
        fontSize: '14px!important',
        color: '#FCFCFC!important',
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '500!important',
        marginTop: '3px!important',
        marginBottom: '3px!important',
        display: 'flex!important',
        alignItems: 'center!important',
        paddingRight: '10px!important',
      },
    },

    '.property-dev-location': {
      fontSize: '13px!important',
      color: Variables.darkColor,
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '500!important',
      paddingLeft: '10px!important',
    },
    '.property-dev-location-white': {
      fontSize: '14px!important',
      color: '#FCFCFC!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      paddingLeft: '10px!important',
    },
    '.property-dev-price': {
      fontSize: '13px!important',
      color: Variables.darkColor,
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '500!important',
      paddingLeft: '0px!important',
    },
    '.property-dev-price-white': {
      fontSize: '14px!important',
      color: '#FCFCFC!important',
      fontFamily: 'Open Sans, sans-serif !important',
      fontWeight: '700!important',
      paddingLeft: '0px!important',
      lineHeight: '38px',
    },

    '.brd-right': {
      borderRight: '1px solid #D3E0ED!important',
      paddingRight: '15px',
      paddingLeft: '15px',
    },
    '.brd-right2': {
      borderRight: '1px solid #F2A079!important',
      paddingRight: '15px',
      // paddingLeft: '15px',
      whiteSpace: 'nowrap',
      width: '190px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '.brd-left': {
      borderRight: '1px solid #D3E0ED!important',
      paddingRight: '15px',
      paddingLeft: '15px',
    },
    '.offer-small-text': {
      fontSize: '13px',
      color: Variables.darkColor,
      marginLeft: '10px',
      fontWeight: '700!important',
      fontFamily: 'Open Sans, sans-serif !important',
      marginTop: '0px',
    },
    '.vertical-line': {
      borderLeft: '1px solid #C1C1C1!important',
      height: '7.2%',
      position: 'absolute',
      left: '50%',
      top: '0',
    },
    '.gallery-prev-btn': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '.gallery-next-btn': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '.livinv-rating-bg': {
      color: '#fff',
      marginRight: '10px',
      zIndex: '9',
      transition: '0.3s',
      backgroundColor: 'rgba(0 , 0, 0, 0.5)',
      borderRadius: '8px',
      padding: '7px',
      border: '1px solid #7070708f!important',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      [theme.breakpoints.down(breakpoints.ssmdl)]: {
        marginRight: '3px',
        width: '100%',
        padding: '4px !important',
        justifyContent: 'center',
      },
      [theme.breakpoints.down(breakpoints.ssm)]: {
        marginRight: '0px',
        width: '100%',
        padding: '8px 4px',
        justifyContent: 'center',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginRight: '0px',
        width: '100%',
        height: '36px',
        padding: '4px !important',
        justifyContent: 'center',
        borderRadius: '5px',
      },
    },
    '.css-8y3x9n': {
      width: '150px!important',
      color: '#171717!important',
      background: '#fff!important',
      fontSize: '12px!important',
      padding: '10px',
      fontWeight: '500!important',
      fontFamily: 'Open Sans, sans-serif !important',
    },

    '.align-rating': {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '20px',
      marginBottom: '7px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        marginLeft: '0px',
        marginTop: '10px',
      },
      '& .pac-container': {
        width: '150px!important',
      },
    },
    '.inev-rating': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right',
      marginRight: '10px',
      marginBottom: '7px',
    },
    '.rating-text': {
      fontSize: '13px!important',
      fontWeight: '400!important',
      fontFamily: 'Open Sans, sans-serif !important',
      color: '#ffffffe3',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '12px!important',
      },
      [theme.breakpoints.down(breakpoints.xxxs)]: {
        fontSize: '10px!important',
      },
    },
    '.rating-text-dark': {
      fontSize: '12px!important',
      fontWeight: '700!important',
      fontFamily: 'Open Sans, sans-serif !important',
      color: Variables.darkColor,
      textTransform: 'uppercase',
      marginTop: '2px',
      marginLeft: '2px',
    },
    '.rating-text-dark-opt': {
      fontSize: '13px!important',
      fontWeight: '500!important',
      fontFamily: 'Open Sans, sans-serif !important',
      color: Variables.darkColor,
      marginLeft: '10px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '180px',
      overflow: 'hidden', 
    },
    '.rating-text-dark-tap': {
      fontSize: '10px!important',
      fontWeight: '500!important',
      fontFamily: 'Open Sans, sans-serif !important',
      color: Variables.darkColor,
      textTransform: 'uppercase',
    },
    '.offer-large-text': {
      '& h4': {
        fontSize: '16px!important',
        fontWeight: '700!important',
        fontFamily: 'Open Sans, sans-serif !important',
        color: '#FFF!important',
        marginLeft: '10px',
      },
      [theme.breakpoints.down(breakpoints.xxxs)]: {
        fontSize: '12px!important',
      },
      [theme.breakpoints.down(breakpoints.ssm)]: {
        marginLeft: '5px',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '0.834rem !important',
        fontWeight: '700!important',
      },
    },
    '.rating-text-dark-opt-info': {
      marginLeft: '5px',
      color: Variables.darkColor,
    },
    '.gallery-options': {
      marginRight: '10px',
      marginBottom: '-4px',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        marginBottom: '-5px',
        marginRight: '2px',
        //width: '25px',
      },
    },
    '.galloptwidth': {
      padding: '8px 10px 8px 20px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        padding: '8px 15px 8px 15px',
      },
      [theme.breakpoints.down(breakpoints.xxsm)]: {
        padding: '8px 10px 8px 10px',
      },
    },
    '.icons-align': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '-2px',
    },
    '.icons-align-opt': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
    },

    // Mobile View start-----------
    '.mob-gallery-images': {
      margin: '0px auto',
      position: 'relative',
      background: '#FFFFFF83',
      width: '102px',
      //height:'41px',
      marginTop: '8%',
      padding: '2px',
      borderRadius: '5px',
    },
    '.config-details': {
      fontWeight: '700',
      fontFamily: 'Open Sans, sans-serif !important',
      fontSize: '14px',
    },
    '.offer-MainBox': {
      marginTop: '22px',
      marginBottom: '20px',
      border: '1px solid #ccc0b0',
      borderRadius: '0px',
      textAlign: 'left',
      background: '#FFE7DB',
      [theme.breakpoints.down(breakpoints.ssm)]: {
        marginBottom: '0!important',
        marginTop: '15px!important',
        borderBottom: '0',
      },
    },
    '.offer-SplBox': {
      padding: '10px',
      borderBottom: '1px solid #ccc0b0',
    },
    '.offer-dealBox': {
      padding: '7px',
      borderBottom: '1px solid #ccc0b0',
      width: '100%',
    },
    '.offer-SplBox-text': {
      fontWeight: '700',
      fontFamily: 'Open Sans, sans-serif !important',
      fontSize: '14px',
      color: '#9E0000',
      fontDisplay: 'swap',
    },
    //Mobile View end-----------
    //property details banner section end

    //builder services end--------------------------------------------------

    '.readmore-faq': {
      marginTop: '-15px',
      textAlign: 'right',
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginRight: '10px',
      },
      [theme.breakpoints.down(breakpoints.xss)]: {
        marginRight: '22px',
      },
      '& .view-all-link': {
        fontSize: '12px !important',
        fontWeight: '700 !Important',
        fontFamily: 'Open Sans, sans-serif!important',
        color: '#0056B2',
        marginTop: '-15px',
        marginRight: '5px',
        cursor: 'pointer',
        [theme.breakpoints.down(breakpoints.sm)]: {
          marginRight: '20px',
        },
      },
    },
    '.commfaq': {
      '& .MuiAccordionSummary-root.Mui-expanded': {
        minHeight: '40px',
      },
      '& .MuiAccordionDetails-root': {
        padding: '8px 16px 2px',
      },
    },
    '.rentingwhychoose': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    '.rentingwhychoosecircle': {
      width: '20%',
    },

    '.howit-works-text-height': {
      height: '80px',
      marginBottom: '5px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        height: 'auto!important',
      },
    },

    '.margn-top': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginTop: '-20px!important',
      },
    },

    '.mainPolicy': {
      display: 'flex',
      width: '100%',
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'block',
        textAlign: 'left',
      },
    },
    '.mainPolicyText': {
      width: '80%',
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '100%',
      },
    },
    '.mainPolicyImgText': {
      width: '80%',
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '100%',
      },
    },

    '.mainPolicyIcon1': {
      width: '7%',
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '100%',
      },
    },
    '.mainPolicyText1': {
      width: '80%',
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '100%',
      },
    },

    '.noSecform': {
      padding: '20px',
      marginBottom: '40px',
    },

    '.noSecformSell': {
      padding: '10px',
      position: 'relative',
    },

    '.noSecformSellbgblue': {
      padding: '20px',
      background: '#0056B2',
    },

    '.noSec2': {
      backgroundColor: '#0056B20F',
      borderTop: '4px solid #0056B2',
      height: '250px',
      marginTop: '60px',
      padding: '20px',
      position: 'relative',
      [theme.breakpoints.down(breakpoints.md)]: {
        display: 'none',
      },
    },

    '.noSec2mob': {
      backgroundColor: '#0056B20F',
      borderTop: '4px solid #0056B2',
      height: '370px',
      marginTop: '60px',
      padding: '20px',
      position: 'relative',
      [theme.breakpoints.up(breakpoints.md)]: {
        display: 'none',
      },
      [theme.breakpoints.down(breakpoints.md)]: {
        display: 'block',
        height: '400px',
      },
    },

    '.noImpactmob': {
      [theme.breakpoints.down(breakpoints.md)]: {
        background: '#0056b2',
        padding: '16px',
        color: '#fff',
        width: '90px',
        height: '90px',
        borderRadius: '50%',
        position: 'absolute',
        top: '-2%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    },

    '.noImpactCirclemob': {
      [theme.breakpoints.down(breakpoints.md)]: {
        textAlign: 'center',
        top: '-2%',
        position: 'relative',
      },
    },

    '.noImpactTextMainmob': {
      [theme.breakpoints.down(breakpoints.md)]: {
        textAlign: 'center',
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        top: '30%',
        height: '120px',
      },
    },

    '.noImpactTextMainmob1': {
      position: 'absolute',
      top: '56%',
      textAlign: 'center',
      transform: 'translate(-50%, -50%)',
      left: '50%',
      height: '95px',
    },

    '.noImpactTextMainmob2': {
      position: 'absolute',
      bottom: '-16%',
      textAlign: 'center',
      transform: 'translate(-50%, -50%)',
      left: '50%',
      height: '120px',
    },

    '.boxHeight': {
      [theme.breakpoints.down(breakpoints.ssm)]: {
        padding: '2px',
        height: '415px',
      },
    },

    '.bldboxes': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'none',
      },
    },

    '.supportFormBg': {
      background: '#fff',
      padding: '20px',
      borderRadius: '15px',
      boxShadow: '1px 3px 12px #00000029',
      position: 'relative',
      bottom: '18%',
      borderBottom: '5px solid #ee6a29',

      [theme.breakpoints.down(breakpoints.ssm)]: {
        bottom: '50%',
      },
    },

    '.rating-feature-section': {
      display: 'flex',
      marginTop: '15px',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',

      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'block',
      },
    },

    '.beforeContainer': {
      display: 'flex',
      [theme.breakpoints.down(breakpoints.sm)]: {
        // display: 'block',
      },
    },

    '.covidDetailsBox': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        paddingLeft: '20px',
        paddingRight: '20px',
        textAlign: 'center',
        marginTop: '-58px',
      },
    },

    '.homesellLeftContainer': {
      textAlign: 'Left',
      display: 'block',
      backgroundColor: '#0056B20A',
      borderRadius: '8px',
      padding: '30px 20px',
      marginLeft: '10px',
      marginRight: '10px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center',
        marginBottom: '30px',
      },
    },

    '.privacypolicyContainer': {
      textAlign: 'Left',
      display: 'block',
      borderRadius: '8px',
      padding: '0px 0px 0px',
      marginLeft: '10px',
      marginRight: '10px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'Left',
        marginBottom: '0px',
        padding: '0px 15px 0px',
        marginTop: '-10px',
      },
    },
    '.privacypolicyContainer1': {
      textAlign: 'Left',
      display: 'block',
      borderRadius: '8px',
      padding: '0px 0px 40px',
      marginLeft: '10px',
      marginRight: '10px',
      marginBottom: '30px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'Left',
        marginBottom: '20px',
        padding: '0px 15px 30px',
        marginTop: '-10px',
      },
    },

    '.homesellRightContainer': {
      padding: '3px 20px',
      marginLeft: '10px',
      marginRight: '10px',
      textAlign: 'Left',
      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center',
      },
    },

    '.cardContainerBox': {
      background: 'transparent !important',
      boxShadow: 'none !important',
      textAlign: 'left',
      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center',
      },
    },

    '.whyHomeBazaarContainer': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        width: '100%',
      },
    },
    /*search-page-fliter*/

    '.rightArrow': {
      float: 'right',
    },

    '.HowitworksText': {
      textAlign: 'left',
      display: 'block',
      [theme.breakpoints.down(breakpoints.sm)]: {
        textAlign: 'center !important',
        display: 'block !important',
      },
    },

    '.property-footer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '7px',
      // "& img":{
      //   marginTop:"5px!important"
      // },
    },

    '.boxContainer': {
      background: '#DEDEFF',
      padding: '10px',
      marginTop: '10px',
      // marginLeft: "-23px",
      paddingLeft: '21px',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
      },
    },
    // ".linechartDetails":{
    //   [theme.breakpoints.down(breakpoints.sm)]: {
    //   width:"300px",
    //   },
    // }
    '.highlight': {
      color: '#0056B2',
    },
    '.error-text': {
      textAlign: 'left',
      color: '#ED494C',
      fontWeight: '500',
      fontSize: '13px',
      marginTop: '10px',
      fontFamily: 'Open Sans, sans-serif ',
    },
    '.error-text-upd': {
      textAlign: 'left',
      color: '#ED494C',
      display: 'flex',
      alighItems: 'center',
      fontWeight: '500',
      fontSize: '13px',
      marginTop: '10px',
      fontFamily: 'Open Sans, sans-serif ',
      '& img': {
        marginRight: '7px',
      },
    },

    '.error-text-upd1': {
      textAlign: 'left',
      color: '#f44336',
      display: 'flex',
      alighItems: 'center',
      fontWeight: '500',
      fontSize: '13px',
      marginTop: '10px',
      fontFamily: 'Open Sans, sans-serif ',
      '& img': {
        marginRight: '4px',
      },
    },
    '.success-text': {
      textAlign: 'center',
      color: '#5CC531',
    },
    '.otp-text': {
      textAlign: 'center',
      color: '#5CC531',
    },
    '.thankyou-text': {
      textAlign: 'center',
      color: '#5CC531',
    },
    '.popup-form': {
      textAlign: 'center',
      color: '#5CC531',
    },
    '.success-signup': {
      textAlign: 'center',
      // color: '#5CC531',
    },

    // ".mobheader":{
    //   paddingTop:"2px"
    // }
    '.custom_table': {
      width: '100%',
    },
    '.tablevalues': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        display: 'flex',
        alignItems: 'center',
      },
      [theme.breakpoints.down(breakpoints.xxsm)]: {
        display: 'block',
        alignItems: 'center',
      },
    },
    '.table_heading': {
      textAlign: 'left',
      width: '210px',
      padding: '4px 0',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down(breakpoints.sm)]: {
        padding: '8px 0',
        width: '70% !important',
      },
      [theme.breakpoints.down(breakpoints.xxsm)]: {
        padding: '0px 0',
      },
      '& h6': {
        color: Variables.darkColor,
        fontSize: '14px!important',
        fontWeight: '500!important',
        opacity: '0.9!important',
        fontFamily: 'Open Sans, sans-serif!important',
        marginLeft: '11px!important',
        [theme.breakpoints.down(breakpoints.sm)]: {
          fontWeight: '400!important',
          opacity: '1.0!important',
        },
      },
      '& img': {
        // height: 'auto!important',
        // width: 'auto!important',
        // maxWidth: '28px!important',
        // maxHeight: '100%!important',
        // padding: '2px',
        objectFit: 'cover',
        width: '23px',
        height: '23px',
      },
      [theme.breakpoints.down(breakpoints.ssm)]: {
        width: '100%',
      },
    },
    '.table_data': {
      width: '50%',
      padding: '7px 0',
      verticalAlign: 'top',
      [theme.breakpoints.down(breakpoints.sm)]: {
        paddingLeft: '0px!important',
        paddingTop: '13px !important',
        width: '47%',
      },
    },

    '.react-modal-sheet-backdrop': {
      border: 'none!important',
      height: '690px !important',
    },
    '.react-modal-sheet-container': {
      height: '100%!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        height: '100%!important',
        maxHeight: 'calc(100vh - 85px)',
        // paddingTop: '17px',
        // maxHeight: '570px',
        // background: 'transparent !important',
      },
      // [theme.breakpoints.down(breakpoints.md)]: {
      //   height: '100%!important',
      //   maxHeight: 'calc(100vh - 85px)',
      //   paddingTop: '17px',
      //   // maxHeight: '570px',
      //   // background: 'transparent !important',
      // },
    },

    '.react-modal-sheet-content': {
      [theme.breakpoints.down(breakpoints.sm)]: {
        overflow: 'hidden',
      },
    },
    //project progress start
    '.highlight-pd': {
      color: Variables.darkColor,
      fontSize: '14px!important',
      fontWeight: '500!important',
      fontFamily: 'Open Sans, sans-serif!important',
      [theme.breakpoints.down(breakpoints.sm)]: {
        fontSize: '12px!important',
      },
      [theme.breakpoints.down(breakpoints.xxsm)]: {
        fontSize: '9px!important',
      },
    },
    //search mobile start
    '.close-mob-btn': {
      position: 'absolute',
      right: '16px',
      top: '8px',
    },
    //search mob end
    //wishlist start
    '.tabDetails': {
      padding: '10px',

      '& .MuiTab-root': {
        minHeight: '35px',
      },

      '& .MuiTab-textColorInherit': {
        opacity: 'none !Important',
      },

      '& .MuiTabs-scroller': {
        flex: 'none !important',
        display: 'block !important',
      },

      '& .MuiTabs-flexContainer': {
        display: 'inline-flex',
      },

      '& .MuiTabs-fixed': {
        overflowX: 'initial !important',
        overflow: 'initial !important',
      },

      '& .MuiTabs-indicator': {
        display: 'none',
      },
    },

    //shortilisted start
    '.ShortTabDetails': {
      padding: '10px',

      '& dev': {
        width: '100% !Important',
      },

      '& .MuiTabs-scroller': {
        flex: 'none !important',
        display: 'block !important',
      },

      '& .MuiTabs-flexContainer': {
        display: 'table-caption',

        '& .MuiTab-textColorInherit': {
          opacity: '9 !Important',
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#464646',
        },
      },

      '& .MuiTabs-fixed': {
        overflowX: 'initial !important',
        overflow: 'initial !important',

        '&:first-child': {
          borderBottom: '1px solid #cccccc',
        },
        '&:nth-child(2)': {
          borderBottom: 'none',
        },
      },
    },

    '.gtm_success_signup': {
      fontSize: '24px',
      fontFamily: 'Open Sans, sans-serif',
      fontWeight: 'bold',
      color: '#FFF!important',
      lineHeight: '33px',
      marginBottom: '35px',
      [theme.breakpoints.down(breakpoints.sm)]: {
        marginBottom: '25px',
      },
    },

    //Mobshortilisted start
    '.MobShortTabDetails': {
      padding: '10px 12px',

      '& button': {
        width: '100%',
        border: '1px solid #ccc',
        marginLeft: '4%',
        marginRight: '4%',
        borderRadius: '5px',
        fontSize: '13px',
        lineHeight: '17px',
        backgroundColor: '#F3F3FF',
        color: '#464646',
        fontWeight: '700',
      },

      '&.proBtnContainer': {
        '&.MuiGrid-grid-xs-6::first-child button': {
          color: '#FFFFFF',
        },

        '&.MuiGrid-grid-xs-6::last-child button': {
          color: '#FFFFFF !Important',
        },
      },

      '& .MuiButton-contained': {
        border: 'none !Important',
        color: '#171717 !Important',
        '& ::first-child': {
          color: '#FFFFFF',
        },

        '& :last-child': {
          color: '#FFFFFF !Important',
        },

        // "& :nth-child(1)":{
        //   color:'#FFFFFF',
        // },
      },

      '& .shortlistContent :first-child button': {
        width: '100% !Important',
      },

      '& .shortlistContent :second-child button': {
        width: '100% !Important',
      },

      '& dev': {
        width: '100% !Important',
      },

      '& .MuiTabs-scroller': {
        flex: 'none !important',
        display: 'block !important',
      },

      '& .MuiTabs-flexContainer': {
        display: 'table-caption',
      },

      '& .MuiTabs-fixed': {
        overflowX: 'initial !important',
        overflow: 'initial !important',

        '&:first-child': {
          // borderBottom: "1px solid #cccccc",
        },
        '&:nth-child(2)': {
          borderBottom: 'none',
        },
      },
    },

    '.mainTab': {
      paddingTop: '11px',
      paddingBottom: '11px',
    },

    '.shortlistData': {
      position: 'absolute',
      zIndex: '1',
      display: 'flex',
      justifyContent: 'flex-end',
      width: '97.3%',
    },

    '.shortlistDataMob': {
      //   position: "absolute",
      //   zIndex: "1",
      display: 'flex',
      //  justifyContent: "flex-end",
      width: '100%',
      borderTop: '1px solid #D8D8E6',
      paddingTop: '14px',
      paddingBottom: '10px',
      borderBottom: '1px solid #D8D8E6',
    },

    '.shortlistContent': {
      '& .MuiTab-root': {
        height: '38px !Important',
      },
    },

    '.shortCallButton': {
      background: Variables.primaryColor,
      color: '#FFFFFF',
      borderRadius: '5px',
      // display: "flex",
      justifyContent: 'center',
      alignItems: 'center',
      height: '33px',
      cursor: 'pointer',
      display: 'flex',
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '32%',
        fontWeight: 'bold',
      },
    },
    '.colorWhite': {
      color: '#FFF !important',
    },
  },

  // buyWHomeContainer:{
  //   [theme.breakpoints.down(breakpoints.sm)]: {
  //     textAlign:"center",
  //    },
  // },
});

function globalStyles() {
  return null;
}

export default withStyles(styles, { withTheme: true })(globalStyles);
